import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import UserRoute from '../../Routes/Auth';
import UserCard from '../../Components/UserCard/UserCard';
import Navigation from '../../Components/Navigation/Navigation';
// import SearchFilterTabDropDown from '../../Components/SearchFilterTabDropDown/SearchFilterTabDropDown';



export default function SearchPage() {

    const { term } = useParams();
    const capitalizedTerm = term.charAt(0).toUpperCase() + term.slice(1);

    const [users, setUsers] = useState([]);



    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API}/search/${term}`);
                // console.log(res.data)
                setUsers(res.data);
            } catch (err) {
                console.error(err);
            }
        };
        fetchUsers();
    }, [term]);


    

    return (
        <UserRoute>
        <div className='category-pg'>
            {/* <h1 className='category-pg__title'>{capitalizedCategory}</h1> */}
            <Navigation heading={capitalizedTerm} back={true} />
            <div className="category-pg__filter-container">
                {/* <SearchFilterTabDropDown title={"Follower Size"} options={['Featured', 'Newest', 'Rating']} />
                <SearchFilterTabDropDown title={"Category"} options={['Featured', 'Newest', 'Rating']} />
                <SearchFilterTabDropDown title={"Location"} options={['Featured', 'Newest', 'Rating']} />
                <SearchFilterTabDropDown title={"Price"} options={['Featured', 'Newest', 'Rating']} />
       <SearchFilterTabDropDown title={"Social Channel"} options={['Featured', 'Newest', 'Rating']} />       */}  
            </div> 
            {users.map(user => (
                <UserCard key={user && user._id} username={user && user.username} location={user && user?.location} category={user && user?.categories[0]} picture={user && user.picture} />
            ))}

        </div>
    </UserRoute>
    )
}
