import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './step.scss'; // Ensure this path is correct based on your project structure
import TextInput from '../../TextInput/TextInput';

function SocialMediaLinksStep({ data, onChange, onNext, onBack, themeMode }) {
  // Initialize social media state with top 5 platforms, including Instagram
  const [socialMedia, setSocialMedia] = useState(
    data.socialMedia || {
      tiktok: '',
      instagram: '', // Added Instagram
      youtube: '',
      spotify: '',
      twitch: '',
      x: '',
    }
  );

  // State for handling validation errors
  const [errors, setErrors] = useState({});

  // Ref for the first input to auto-focus
  const tiktokRef = useRef(null);

  // Auto-focus the TikTok input when component mounts
  useEffect(() => {
    if (tiktokRef.current) {
      tiktokRef.current.focus();
    }
  }, []);

  // Corrected regular expression to validate URLs
  const urlRegex = /^(https?:\/\/)?([\w-]+)\.([a-zA-Z]{2,63})([\w\-@?^=%&:/~+#]*[\w@?^=%&/~+#])?$/;

  // Validation function to check each social media link
  const validate = () => {
    const newErrors = {};

    // Validate TikTok (Required)
    if (!(socialMedia.tiktok || '').trim()) {
      newErrors.tiktok = 'TikTok link is required';
    } else if (!urlRegex.test(socialMedia.tiktok)) {
      newErrors.tiktok = 'Please enter a valid TikTok URL';
    }

    // Validate Instagram (Required)
    if (!(socialMedia.instagram || '').trim()) {
      newErrors.instagram = 'Instagram link is required';
    } else if (!urlRegex.test(socialMedia.instagram)) {
      newErrors.instagram = 'Please enter a valid Instagram URL';
    }

    // Validate YouTube (Optional)
    if ((socialMedia.youtube || '').trim() && !urlRegex.test(socialMedia.youtube)) {
      newErrors.youtube = 'Please enter a valid YouTube URL';
    }

    // Validate Spotify (Optional)
    if ((socialMedia.spotify || '').trim() && !urlRegex.test(socialMedia.spotify)) {
      newErrors.spotify = 'Please enter a valid Spotify URL';
    }

    // Validate Twitch (Optional)
    if ((socialMedia.twitch || '').trim() && !urlRegex.test(socialMedia.twitch)) {
      newErrors.twitch = 'Please enter a valid Twitch URL';
    }

    // Validate X (formerly Twitter) (Optional)
    if ((socialMedia.x || '').trim() && !urlRegex.test(socialMedia.x)) {
      newErrors.x = 'Please enter a valid X (Twitter) URL';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle the "Next" button click
  const handleNextClick = (e) => {
    e.preventDefault();
    if (validate()) {
      onChange({ socialMedia });
      onNext();
    }
  };

  // Handle the "Back" button click
  const handleBackClick = (e) => {
    e.preventDefault();
    onChange({ socialMedia });
    onBack();
  };

  // Handle changes in input fields
  const handleChange = (field) => (e) => {
    setSocialMedia({
      ...socialMedia,
      [field]: e.target.value,
    });
  };

  return (
    <div className={`step ${themeMode}`}>
      <h2>Social Platforms</h2>
      <form>
        {/* TikTok Input */}
        <TextInput
          id="tiktok"
          label="TikTok"
          type="text"
          value={socialMedia.tiktok}
          onChange={handleChange('tiktok')}
          placeholder="Enter your TikTok profile URL"
          required
          error={errors.tiktok}
          themeMode={themeMode}
          ref={tiktokRef} // To auto-focus
        />

        {/* Instagram Input */}
        <TextInput
          id="instagram"
          label="Instagram"
          type="text"
          value={socialMedia.instagram}
          onChange={handleChange('instagram')}
          placeholder="Enter your Instagram profile URL"
          required
          error={errors.instagram}
          themeMode={themeMode}
        />

        {/* YouTube Input */}
        <TextInput
          id="youtube"
          label="YouTube"
          type="text"
          value={socialMedia.youtube}
          onChange={handleChange('youtube')}
          placeholder="Enter your YouTube channel URL"
          required={false}
          error={errors.youtube}
          themeMode={themeMode}
        />

        {/* Spotify Input */}
        <TextInput
          id="spotify"
          label="Spotify"
          type="text"
          value={socialMedia.spotify}
          onChange={handleChange('spotify')}
          placeholder="Enter your Spotify profile URL"
          required={false}
          error={errors.spotify}
          themeMode={themeMode}
        />

        {/* Twitch Input */}
        <TextInput
          id="twitch"
          label="Twitch"
          type="text"
          value={socialMedia.twitch}
          onChange={handleChange('twitch')}
          placeholder="Enter your Twitch profile URL"
          required={false}
          error={errors.twitch}
          themeMode={themeMode}
        />

        {/* X (formerly Twitter) Input */}
        <TextInput
          id="x"
          label="X (Twitter)"
          type="text"
          value={socialMedia.x}
          onChange={handleChange('x')}
          placeholder="Enter your X (Twitter) profile URL"
          required={false}
          error={errors.x}
          themeMode={themeMode}
        />

        {/* Navigation Buttons */}
        <div className="navigation-buttons">
          <button type="button" onClick={handleBackClick} className="back-button">
            Back
          </button>
          <button type="button" onClick={handleNextClick} className="next-button">
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

SocialMediaLinksStep.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default SocialMediaLinksStep;
