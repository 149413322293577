import React, { useEffect, useState, useContext } from 'react'

import './EditPromotionPage.scss'
import UserRoute from '../../Routes/Auth'
import Card from '../../Components/Card/Card'
import InputField from '../../Components/InputField/InputField'
import SocialFieldCard from '../../Components/SocialField/SocialField'
import Button from '../../Components/Button/Button'
import { useNavigate, useParams } from "react-router-dom";
import Navigation from '../../Components/Navigation/Navigation'
import axios from 'axios';
import TextArea from '../../Components/TextArea/TextArea'
import UploadImageComponent from '../../Components/UploadImageComponent/UploadImageComponent'
import Resizer from 'react-image-file-resizer';
import ThemeContext from '../../context/ThemeContext';
import CustomSelect from '../../Components/CustomSelect/CustomSelect'



export default function EditPromotions() {
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const { id } = useParams();
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [currency, setCurrency] = useState("USD");
    const [instagramPosts, setInstagramPosts] = useState(0);
    const [tikTokPosts, setTikTokPosts] = useState(0);
    const [youtubePosts, setYoutubePosts] = useState(0);
    const [podcastPosts, setPodcastPosts] = useState(0);
    const [portfolio, setPortfolio] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPromotion = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/user/edit-profile/promotion/${id}`)
                console.log(data.promotion)
                setName(data.promotion.name);
                setDescription(data.promotion.description);
                setPrice(data.promotion.price);
                setInstagramPosts(data.promotion.instagram.quantity);
                setTikTokPosts(data.promotion.tiktok.quantity);
                setYoutubePosts(data.promotion.youtube.quantity);
                setPodcastPosts(data.promotion.podcast.quantity);
                setPortfolio(data.promotion.portfolio);
                setCurrency(data.promotion.currency);
            } catch (error) {
                console.log(error)
            }
        }
        fetchPromotion();
    }, [id])

    const handleAdd = async () => {
        try {
          Resizer.imageFileResizer(image, 750, 750, 'PNG', 100, 0, async (uri) => {
            try {
              const { data } = await axios.put(
                `${process.env.REACT_APP_API}/user/edit-profile/promotion/${id}/upload-image`,
                {
                  image: uri,
                }
              );
              console.log('IMAGE UPLOADED', data);
              // set image in the state
              console.log('Updated');
            } catch (err) {
              console.log('Failed to upload. Try later.', err);
            }
          });
        } catch (err) {
          console.error(err);
        }
      };
    

    const editPromotion = async (event) => {
        event.preventDefault();
        try {
            const instagram = {
                quantity: instagramPosts
            }
            const tiktok = {
                quantity: tikTokPosts
            }
            const youtube = {
                quantity: youtubePosts
            }
            const podcast = {
                quantity: podcastPosts
            }

            const { data } = await axios.put(`${process.env.REACT_APP_API}/user/edit-profile/promotion/${id}`, {
                name,
                description,
                price,
                instagram,
                tiktok,
                youtube,
                podcast, 
                currency,
            })
            console.log(data)
            navigate(`/settings/promotions/`);
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <UserRoute>
            <div className='edit-promotion-pg'>
                <Navigation heading={"Edit Promotion"} back={true} />
                <Card className="card--input" >
         
                    <InputField label={"Title"} name={"name"} type={"text"} value={name} onChange={e => setName(e.target.value)} />
                    <TextArea label={"Description"} name={"description"} type={"text"} value={description} onChange={e => setDescription(e.target.value)} />
                    <div className='edit-promotion-pg__row edit-promotion-pg__row--modifier'>
                        <label className="edit-promotion-pg__label">Currency</label>
                        <CustomSelect value={currency} options={["USD", "CAD", "EUR", "GBP", ]} disabledOptions={["JPY", "AUD", "NZD", "INR", "SGD", "AED", "CHF", "ZAR"]} onOptionSelect={e => setCurrency(e)} />

                    </div>
                    <InputField label={"Price"} name={"price"} type={"number"} value={price} onChange={e => setPrice(e.target.value)} className="add-promo-pg__price add-promo-pg__price--modifier" />
                    <div className='edit-promotion-pg__row'>
                        <div className='edit-promotion-pg__row-left'>
                            <div className={`edit-promotion-pg__portoflio-container edit-promotion-pg__portoflio-container--${themeClass}`}>
                                {portfolio.map((item, index) => {
                                return (
                                    <div key={index} className="edit-promotion-pg__portfolio edit-promotion-pg__portfolio--modifier">
                                        <img className="edit-promotion-pg__portfolio-image" src={item} alt={`Portfolio  ${index}`} />
                                    </div>
                                );
                                })}
                                <UploadImageComponent tag={"edit-promotion-pg__upload"} handleAdd={handleAdd} image={image} setImage={setImage}  imagePreview={imagePreview} setImagePreview={setImagePreview} />
                            </div>
                        </div>
                        <div className='edit-promotion-pg__row-right'>
                            <SocialFieldCard name={"Instagram"} setPosts={setInstagramPosts} posts={instagramPosts} />
                            <SocialFieldCard name={"TiktTok"} setPosts={setTikTokPosts} posts={tikTokPosts} />
                            <SocialFieldCard name={"Youtube"} setPosts={setYoutubePosts} posts={youtubePosts} />
                            <SocialFieldCard name={"Podcasts"} setPosts={setPodcastPosts} posts={podcastPosts} />
                        </div>
                    </div>
                    <Button modifier={"button"} onClick={editPromotion}>Update</Button>

                </Card>
            </div>
        </UserRoute>
    )
}
