import React, { useState, useContext, useEffect } from 'react'
import './App.scss'
import { Routes, Route } from 'react-router-dom'
import HomePage from './Pages/HomePage/HomePage'
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage'
import UserPage from './Pages/UserPage/UserPage'
import LoginPage from "./Pages/LoginPage/LoginPage"
import SignUpPage from "./Pages/SignUpPage/SignUpPage"
import SettingsPage from "./Pages/SettingsPage/SettingsPage"
import OrdersPage from "./Pages/OrdersPage/OrdersPage"
import NotificationsPage from './Pages/NotificationsPage/NotificationsPage'
import FeaturePage from './Pages/FeaturePage/FeaturePage'
import PromotePage from './Pages/PromotePage/PromotePage'
import SettingsGeneralPage from "./Pages/SettingsGeneralPage/SettingsGeneralPage"
import SettingsSocialPage from "./Pages/SettingsSocialPage/SettingsSocialPage"
import ChangePasswordPage from './Pages/ChangePasswordPage/ChangePasswordPage'
import ChangeEmailPage from './Pages/ChangeEmailPage/ChangeEmailPage'
import AccountSettingsPage from './Pages/AccountSettingsPage/AccountSettingsPage'
import CampaignsPage from './Pages/CampaignsPage/CampaignsPage'
import { Provider } from './context/index'
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy'
import ConnectStripePage from './Pages/ConnectStripePage/ConnectStripePage'
import LogoutPage from './Pages/LogoutPage/LogoutPage'
import CategoryPage from './Pages/CategoryPage/CategoryPage'
import Messages from './Pages/Messages/Messages'
import NewMessage from './Pages/NewMessage/NewMessage'
import DirectMessage from './Pages/DirectMessage/DirectMessage'
import Earnings from './Pages/EarningsPage/EarningsPage'
import UpdatePortfolioPage from './Pages/UpdatePortfolioPage/UpdatePortfolioPage'
import StripeSuccessPage from './Pages/StripeSuccessPage/StripeSuccessPage'
import StripeCancelPage from './Pages/StripeCancelPage/StripeCancelPage'
import StripePaymentSuccessPage from './Pages/StripePaymentSuccessPage/StripePaymentSuccessPage'
import ManagePromotionsPage from './Pages/ManagePromotionsPage/ManagePromotionsPage'
import AddPromotionPage from './Pages/AddPromotionPage/AddPromotionPage'
import ConnectionsPage from './Pages/ConnectionsPage/ConnectionsPage'
import SubscriptionsPage from './Pages/SubscriptionsPage/SubscriptionsPage'
import EditPromotionPage from './Pages/EditPromotionPage/EditPromotionPage'
import CampaignPage from './Pages/CampaignPage/CampaignPage'
import PastCampaignsPage from './Pages/PastCampaignsPage/PastCampaignsPage'
import EditCampaignPage from './Pages/EditCampaignPage/EditCampaignPage'
import CampaignRequestsPage from './Pages/CampaignRequestsPage/CampaignRequestsPage'
import ManagePortflioPage from './Pages/ManagePortfolioPage/ManagePortfolioPage'
import CampaignsOutBoxPage from './Pages/CampaignsOutBoxPage/CampaignsOutBoxPage'
import SearchPage from './Pages/SearchPage/SearchPage'
import EditLinksPage from './Pages/EditLinksPage/EditLinksPage'
import AddLinkPage from './Pages/AddLinkPage/AddLinkPage'
import CampaignAnalyticsPage from './Pages/CampaignAnalyticsPage/CampaignAnalyticsPage'
import CampaignDetailsPage from './Pages/CampaignDetailsPage/CampaignDetailsPage'
import CampaignProductsPage from './Pages/CampaignProductsPage/CampaignProductsPage'
import AddProductPage from './Pages/AddProductPage/AddProductPage'
import AddCampaignPost from './Pages/AddCampaignPost/AddCampaignPost'
import './styles/partials/lightTheme.scss';
import './styles/partials/darkTheme.scss';
import ThemeContext from './context/ThemeContext';
import ReactNotification from "./Components/Notifications/ReactNotification";
import TermsOfService from './Pages/TermsOfService/TermsOfServices'
import LandingPage from './Pages/LandingPage/LandingPage'
import SubscriptionSuccessPage from './Pages/SubscriptionSuccessPage/SubscriptionSuccessPage'
import SubscriptionFailurePage from './Pages/SubscriptionFailurePage/SubscriptionFailurePage'
import TeamsPage from './Pages/TeamsPage/TeamsPage'
import CampaignTeamsPage from './Pages/CampaignTeamsPage/CampaignTeamsPage'
import AddCampignTeamMember from './Pages/AddCampaignTeamMember/AddCampaignTeamMember'
import CampaignReviewPage from './Pages/CampaignReviewPage/CampaignReviewPage'
import ForgotPasswordPage from './Pages/ForgotPassword/ForgotPasswordPage'
import ResetPassworPage from './Pages/ResetPasswordPage/ResetPasswordPage'
import VerifyEmailPage from './Pages/VerifyEmailPage/VerifyEmailPage'
import CalendarPage from './Pages/CalendarPage/CalendarPage'
import DonationsSettingsPage from './Pages/DonationsSettingsPage/DonationsSettingsPage'
import SuccessDonatePage from './Pages/SuccessDonatePage/SuccessDonatePage'
import CancelDonatePage from './Pages/CancelDonatePage/CancelDonatePage'
import DonationsPage from './Pages/DonationsPage/DonationsPage'

function App() {

  const [currentRoom, setCurrentRoom] = useState(null);
  const [notifications, setNotifications] = useState(null);

  const serverUrl = process.env.REACT_APP_SOCKET

  // console.log(serverUrl)


  const { themeMode } = useContext(ThemeContext);

  const themeClass = themeMode === 'light' ? 'light-theme' : '';


  useEffect(() => {
    if (themeMode) {
      document.body.classList.add(themeMode);
    }

    return () => {
      if (themeMode) {
        document.body.classList.remove(themeMode);
      }
    };
  }, [themeMode]);

  useEffect(() => {


  }, []);






  return (
    <div className={`App App--${themeClass}`} >

      <Provider className="">

        <Routes className="App__content">

          {/* Home Section */}
          {/* <Route path="/" element={<UserRoute element={<HomePage />}  />}/> */}
          <Route path="/home" element={<HomePage />} />
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/featured" element={<FeaturePage />} />
          <Route path="/featured/:category" element={<CategoryPage />} />
          <Route path="/search/:term" element={<SearchPage />} />


          {/* Campaigns Section */}
          <Route path="/orders" element={<OrdersPage />} />
          <Route path="/campaigns" element={<CampaignsPage />} />
          <Route path="/campaigns/outgoing" element={<CampaignsOutBoxPage />} />
          <Route path="/campaigns/outgoing/edit/:id" element={<EditCampaignPage />} />
          <Route path="/campaigns/requests/" element={<CampaignRequestsPage />} />


          <Route path="/campaigns/:id/edit" element={<EditCampaignPage />} />
          <Route path="/campaigns/past" element={<PastCampaignsPage />} />

          <Route path="/campaigns/:id" element={<CampaignPage />} />
          <Route path="/campaigns/:id/products" element={<CampaignProductsPage />} />
          <Route path="/campaigns/:id/products/add" element={<AddProductPage />} />
          <Route path="/campaigns/:id/post/add" element={<AddCampaignPost />} />
          <Route path="/campaigns/:id/teams/add" element={<AddCampignTeamMember />} />

          <Route path="/campaigns/:id/post/update" element={<EditCampaignPage />} />
          <Route path="/campaigns/:id/campaign" element={<CampaignDetailsPage />} />
          <Route path="/campaigns/:id/teams" element={<CampaignTeamsPage />} />
          <Route path="/campaigns/:id/analytics" element={<CampaignAnalyticsPage />} />
          <Route path="/campaigns/:id/review" element={<CampaignReviewPage />} />


          <Route path="/:username/promote" element={<PromotePage />} />
          <Route path="/:username/connections" element={<ConnectionsPage />} />

          <Route path="/earnings" element={<Earnings />} />
          <Route path="/stripe/callback/" element={<StripeSuccessPage />} />
          <Route path="/stripe/cancel/" element={<StripeCancelPage />} />
          <Route path="/stripe/success/payment/:id" element={<SubscriptionSuccessPage />} />
          <Route path="/stripe/cancel/payment/" element={<SubscriptionFailurePage />} />
          <Route path="/stripe/success/:id/:promotionId" element={<StripePaymentSuccessPage />} />
          <Route path="/stripe/success/:sessionId" element={<SuccessDonatePage />} />
          <Route path="/stripe/donation/cancel/:sessionId" element={<CancelDonatePage />} />



          {/* User Section */}
          <Route path="/:username/:subMode?" element={<UserPage />} />
          <Route path="/notifications" element={<NotificationsPage notifications={notifications} setNotifications={setNotifications} />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/settings/general" element={<SettingsGeneralPage />} />
          <Route path="/settings/socials" element={<SettingsSocialPage />} />
          <Route path="/settings/monetization" element={<ConnectStripePage />} />
          <Route path="/settings/update_portfolio" element={<UpdatePortfolioPage />} />
          <Route path="/settings/subscription" element={<SubscriptionsPage />} />
          <Route path="/settings/promotions" element={<ManagePromotionsPage />} />
          <Route path="/settings/donations" element={<DonationsSettingsPage />} />
          <Route path="/settings/promotions/add" element={<AddPromotionPage />} />
          <Route path="/settings/promotions/edit/:id" element={<EditPromotionPage />} />
          <Route path="/settings/links/:id" element={<EditLinksPage />} />
          <Route path="/settings/links/add" element={<AddLinkPage />} />
          <Route path="/settings/account" element={<AccountSettingsPage />} />
          <Route path="/settings/email/" element={<ChangeEmailPage />} />
          <Route path="/settings/password/" element={<ChangePasswordPage />} />
          <Route path="/settings/team/" element={<TeamsPage />} />




          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/messages" element={<Messages serverUrl={serverUrl} currentRoom={currentRoom} setCurrentRoom={setCurrentRoom} />} />
          <Route path="/messages/new-message" element={<NewMessage currentRoom={currentRoom} setCurrentRoom={setCurrentRoom} isNewMessage={true} serverUrl={serverUrl} />} />
          <Route path="/messages/:id" element={<DirectMessage currentRoom={currentRoom} setCurrentRoom={setCurrentRoom} isNewMessage={false} serverUrl={serverUrl} />} />


          {/* Auth Section */}
          <Route path="/post" element={<ManagePortflioPage />} />
          <Route path="/donations" element={<DonationsPage />} />


          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPassworPage/>} />
          <Route path="/verify-email" element={<VerifyEmailPage />} />



          <Route path="/logout" element={<LogoutPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <ReactNotification />

      </Provider>

    </div>
  );
}

export default App;
