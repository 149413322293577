import React, { useEffect, useState, useContext } from "react";
import UserRoute from "../../Routes/Auth";
import Card from "../../Components/Card/Card";
import Navigation from "../../Components/Navigation/Navigation";
import "./CampaignAnalyticsPage.scss";
import { useParams } from "react-router-dom";
import CampaignHeader from "../../Components/CampaignHeader/CampaignHeader";
import Carousel from "../../Components/Carousel/Carousel";
import MenuIcon from "../../Pages/UserPage/icons/MenuIcon";
import ThemeContext from "../../context/ThemeContext";
import LineChartComponent from "../../Components/LineChartComponent/LineChartComponent";
import CampaignAnalyticHeader from "../../Components/CampaignAnalyticHeader/CampaignAnalyticHeader";
import CampaignAnalyticsModal from "../../Components/CampaignAnalyticsModal/CampaignAnalyticsModal";
import axios from "axios";

export default function CampaignAnalyticsPage() {

  const [campaign, setCampaign] = useState(null);
  const { id } = useParams();
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
  console.log(themeClass);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [anlyticsModal, setAnalyticsModal] = useState(false);
  const [instagramId, setInstagramId] = useState("");
  const [tiktokId, setTiktokId] = useState("");
  const [youtubeId, setYoutubeId] = useState("");
  const [spotifyPodcastId, setSpotifyPodcastId] = useState("");
  const [spotifyMusicId, setSpotifyMusicId] = useState("");
  const [activeChart, setActiveChart] = useState("views");

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}`);
        setCampaign(response.data);
        setInstagramId(response.data.postIds.instagram);
        setTiktokId(response.data.postIds.tiktok);
        setYoutubeId(response.data.postIds.youtube);
        setSpotifyPodcastId(response.data.postIds.spotifyPodcast);
        setSpotifyMusicId(response.data.postIds.spotifyMusic);
      } catch (error) {
        console.error(error);
      }
    };
    if (!campaign) {
      fetchCampaign();
    }
  }, [campaign, id]);

  const viewsData = [
    { date: "Jan", value: 1000 },
    { date: "Feb", value: 2000 },
    { date: "Mar", value: 1000 },
    { date: "Apr", value: 2000 },
    { date: "May", value: 3000 },
    { date: "Jun", value: 4500 },
  ];

  const engagementData = [
    { date: "Aug 1", value: 900 },
    { date: "Aug 15", value: 1100 },
    { date: "Sep 1", value: 1300 },
    { date: "Sep 15", value: 1500 },
    { date: "Oct 1", value: 1700 },
    { date: "Oct 15", value: 1900 },
  ];
  

  const urlClicksData = [
    { date: "Aug 1", value: 1200 },
    { date: "Aug 15", value: 1400 },
    { date: "Sep 1", value: 1800 },
    { date: "Sep 15", value: 2000 },
    { date: "Oct 1", value: 2300 },
    { date: "Oct 15", value: 2500 },
  ];
  
  const conversionsData = [
    { date: "Aug 1", value: 150 },
    { date: "Aug 15", value: 180 },
    { date: "Sep 1", value: 250 },
    { date: "Sep 15", value: 280 },
    { date: "Oct 1", value: 350 },
    { date: "Oct 15", value: 380 },
  ];
  

  const handleUpdatePostId = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/campaigns/${id}/update-post-id`,
        {
          instagram: instagramId,
          tiktok: tiktokId,
          youtube: youtubeId,
          spotifyPodcast: spotifyPodcastId,
          spotifyMusic: spotifyMusicId,
        }
      );
      
      console.log(response.data);
      
      // Update state with the new postIds from the response
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        postIds: {
          instagram: response.data.campaign.postIds.instagram,
          tiktok: response.data.campaign.postIds.tiktok,
          youtube: response.data.campaign.postIds.youtube,
          spotifyPodcast: response.data.campaign.postIds.spotifyPodcast,
          spotifyMusic: response.data.campaign.postIds.spotifyMusic,
        },
      }));
  
      // Close the modal
      setAnalyticsModal(false);
  
    } catch (error) {
      console.error(error);
    }
  };
  
  

  return (
    <UserRoute>
      <div className="campaign-analytics-pg">
        <Navigation
          heading={campaign?.campaignName}
          back={true}
          edit={`/campaigns/edit/${id}`}
        />
        <Card className="card--margin">
          <div className="campaign-analytics-pg__header">
            <div className="campaign-analytics-pg__header__left">
              <CampaignHeader campaign={campaign} />
            </div>
            <div
              className="campaign-analytics-pg__menu-icon-container"
              onClick={() => setAnalyticsModal(true)}
            >
              <MenuIcon themeMode={themeMode} />
            </div>
          </div>
          <div className="campaign-analytics-pg__header-container">
            <div className="campaign-analytics-pg__header-right">
              <CampaignAnalyticHeader
                campaign={campaign}
                setData={setCurrentIndex}
              />
            </div>
          </div>
          <div className="campaign-analytics-pg__container">
            <Carousel
              themeClass={themeClass}
              campaign={campaign}
              currentIndex={currentIndex}
              setAnalyticsModal={setAnalyticsModal}
            />
            <div className="campaign-analytics-pg__charts">
              <div className="campaign-analytics-pg__chart-selection">
                <button
                  className={`campaign-analytics-pg__chart-btn ${
                    activeChart === "views"
                      ? "campaign-analytics-pg__chart-btn--active"
                      : ""
                  }`}
                  onClick={() => setActiveChart("views")}
                >
                  Views
                </button>
                <button
                  className={`campaign-analytics-pg__chart-btn ${
                    activeChart === "engagement"
                      ? "campaign-analytics-pg__chart-btn--active"
                      : ""
                  }`}
                  onClick={() => setActiveChart("engagement")}
                >
                  Engagement
                </button>
                <button
                  className={`campaign-analytics-pg__chart-btn ${
                    activeChart === "clicks"
                      ? "campaign-analytics-pg__chart-btn--active"
                      : ""
                  }`}
                  onClick={() => setActiveChart("clicks")}
                >
                  Clicks
                </button>
                <button
                  className={`campaign-analytics-pg__chart-btn ${
                    activeChart === "conversions"
                      ? "campaign-analytics-pg__chart-btn--active"
                      : ""
                  }`}
                  onClick={() => setActiveChart("conversions")}
                >
                  Conversions
                </button>
              </div>
              <div className="campaign-analytics-pg__chart-div">
                {activeChart === "views" && (
                  <LineChartComponent
                    data={viewsData}
                    dataKey="value"
                    title="Views Over Time"
                  />
                )}
                {activeChart === "clicks" && (
                  <LineChartComponent
                    data={urlClicksData}
                    dataKey="value"
                    title="Clicks Over Time"
                  />
                )}
                {activeChart === "engagement" && (
                  <LineChartComponent
                    data={engagementData}
                    dataKey="value"
                    title="Engagement Over Time"
                  />
                )}
                {activeChart === "conversions" && (
                  <LineChartComponent
                    data={conversionsData}
                    dataKey="value"
                    title="Conversions Over Time"
                  />
                )}
              </div>
            </div>
          </div>
        </Card>
        {anlyticsModal && (
          <CampaignAnalyticsModal
            isOpen={""}
            onClose={() => setAnalyticsModal(false)}
            setIsOpen={""}
            message={"Add Platfrom Post ID"}
            onConfirm={handleUpdatePostId}
            label={"Update your Post ID"}
            instagram={instagramId}
            tiktok={tiktokId}
            youtube={youtubeId}
            spotifyPodcast={spotifyPodcastId}
            spotifyMusic={spotifyMusicId}
            setInstagramId={setInstagramId}
            setTiktokId={setTiktokId}
            setYoutubeId={setYoutubeId}
            setSpotifyPodcastId={setSpotifyPodcastId}
            setSpotifyMusicId={setSpotifyMusicId}
          />
        )}
      </div>
    </UserRoute>
  );
}
