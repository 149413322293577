import React, { useContext } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import ThemeContext from '../../context/ThemeContext';
import './CampaignHeader.scss';
import { EditIcon } from '../CampaignCard/EditIcon';



export default function CampaignHeader({ status }) {

    const { id } = useParams();
    const url = useLocation();
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const isActive = (pathname) => {
        return url.pathname === pathname ? `campaign-card__link--active campaign-card__link--${themeClass}--active` : '';
    };



    return (
        <>
            <Link to={`/campaigns/${id}/`} className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(`/campaigns/${id}/`)}`}>
                Home
            </Link>
            <Link to={`/campaigns/${id}/campaign`} className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(`/campaigns/${id}/campaign`)}`}>
                Posts
            </Link>
            <Link to={`/campaigns/${id}/products`} className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(`/campaigns/${id}/products`)}`}>
                Products
            </Link>
            <Link to={`/campaigns/${id}/teams`} className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(`/campaigns/${id}/teams`)}`}>
                Team
            </Link>
            <Link to={`/campaigns/${id}/analytics`} className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(`/campaigns/${id}/analytics`)}`}>
                Analytics
            </Link>
            <Link to={`/campaigns/${id}/review`} className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(`/campaigns/${id}/review`)}`}>
                Review
            </Link>
            {(status !== 'Completed' && status !== 'Post Campaign') && (
                <Link to={`/campaigns/${id}/edit`} className={`campaign-header__link campaign-header__link--${themeClass}`}>
                    <EditIcon themeMode={themeMode} />
                </Link>)
            }
        </>
    )
}
