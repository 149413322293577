import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './SubscriptionSuccessPage.scss';
import UserRoute from '../../Routes/Auth';
import axios from 'axios';

function SubscriptionSuccessPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const successRequest = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/user/subscriptions/${id}`);
                console.log(data);
                if (data.success) {
                    navigate(`/subscriptions/${data.subscription.uuid}`);
                }
            } catch (err) {
                console.error(err);
            }
        };

        successRequest();
    }, [id, navigate]);

    return (
        <UserRoute>
            <div className='subscription-success-pg'>
            <h1 className='subscription-success-pg__title'>Welcome, Creator! Subscription active 🎉</h1>
            <p className='subscription-success-pg__text'>You are now subscribed to Chequeai.</p>
                <p className='subscription-success-pg__link'>
                    <Link to="/home" className='subscription-success-pg__link-text'>Home</Link>
                </p>
            </div>
        </UserRoute>
    );
}

export default SubscriptionSuccessPage;
