import React, { useState, useContext } from 'react';
import './FeedbackModal.scss';
import StarIcon from '../../Pages/CampaignReviewPage/StarIcon';
import { apiPost } from '../../utils/apiUtils';  
import ThemeContext from '../../context/ThemeContext';  

const FeedbackModal = ({ isOpen, onClose }) => {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { themeMode } = useContext(ThemeContext); 
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';  

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);  // Start submitting

    try {
      // Send feedback and rating to the feedback endpoint
      const response = await apiPost('/feedback', { feedback, rating });

      console.log('Feedback submitted:', response);

      // Reset form and close modal after successful submission
      setFeedback('');
      setRating(0);
      onClose();
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setIsSubmitting(false);  // End submission
    }
  };

  const handleStarClick = (index) => {
    setRating(index + 1); // Set rating based on star clicked (index + 1 to match 1-5 range)
  };

  if (!isOpen) return null;

  return (
    <div className={`modal-overlay modal-overlay--${themeClass}`} onClick={onClose}>
      <div className={`modal-content modal-content--${themeClass}`} onClick={(e) => e.stopPropagation()}>
        <h2 className={`modal-title modal-title--${themeClass}`}>Feedback</h2>
        <form onSubmit={handleSubmit}>
          <div className="feedback-rating">
            {[...Array(5)].map((_, index) => (
              <StarIcon
                key={index}
                filled={index < rating}
                onClick={() => handleStarClick(index)}
              />
            ))}
          </div>
          <textarea
            className={`feedback-input feedback-input--${themeClass}`}
            placeholder="Any feedback or suggestions..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            required
          />
          <div className="modal-actions">
            <button type="button" className={`close-button close-button--${themeClass}`} onClick={onClose}>
              Close
            </button>
            <button type="submit" className={`submit-button submit-button--${themeClass}`} disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeedbackModal;
