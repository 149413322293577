import React, { useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from '../../Components/Navigation/Navigation';
import './CampaignRequestsPage.scss';
import CampaignRequest from '../../Components/CampaignRequest/CampaignRequest';
import axios from 'axios';

export default function CampaignRequestsPage() {
  const [requestCampaigns, setRequestCampaigns] = useState([]);

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/requests`);
      // console.log(response.data);
      const fetchedCampaigns = response.data?.campaigns || [];
      setRequestCampaigns(fetchedCampaigns);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAccept = async (campaignId, brand) => {
    try {
        console.log(campaignId, brand)
        const response = await axios.put(`${process.env.REACT_APP_API}/campaigns/requests/`, {
            campaignId,
            brand
        })
        console.log(response)
        window.location.reload();
        fetchCampaigns();

    } catch (error) {
        console.log(error)
    }
}

const handleDecline = async (campaignId, brand) => {
    try {

        const response = await axios.delete(`${process.env.REACT_APP_API}/campaigns/requests/`, {
            data: { campaignId, brand }
        })
        console.log(response)
        fetchCampaigns();

    } catch (error) {
        console.log(error)
    }
}

  useEffect(() => {
    fetchCampaigns();
  }, []);

  return (
    <UserRoute>
      <div className='campaign-requests-pg'>
        <Navigation heading='Campaign Requests' back={true} />

          {requestCampaigns.map((campaign) => (
                <CampaignRequest
                    key={campaign._id}
                    campaign={campaign}
                    actions={true}
                    handleAccept={() => handleAccept(campaign._id, campaign.brand.username)} 
                    handleDecline={() => handleDecline(campaign._id, campaign.brand.username)}
                    id={campaign._id}
                />
          ))}

      </div>
    </UserRoute>
  );
}
