import React, { useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
// import CampaignCard from '../../Components/CampaignCard/CampaignCard'
import "./CampaignsPage.scss"
import Navigation from "../../Components/Navigation/Navigation";
import axios from 'axios'
import CampaignLinkCard from '../../Components/CampaignLinkCard/CampaignLinkCard';

export default function CampaignsPage() {
    const [campaigns, setCampaigns] = useState(null);



    const fetchCampaigns = async () => {
        try {
            const response = await axios.get(`/campaigns`);
            const fetchedCampaigns = response.data?.campaigns || [];
            setCampaigns(fetchedCampaigns);
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        if (campaigns === null) {
            fetchCampaigns();
        }
    }, [campaigns])

 


    return (
        <UserRoute>
            <div className='campaigns-pg'>
                <Navigation heading={"Active Campaigns"} back={true} edit={"/settings/promotions"} />
                {campaigns && campaigns.map((campaign) => (
                    <CampaignLinkCard
                        key={campaign._id}
                        to={`/campaigns/${campaign._id}/`}
                        title={campaign.campaignName}
                        brand={campaign?.brand}
                        category={campaign?.category}
                        productName={campaign?.productName}
                        tiktok={campaign?.tiktok}
                        instagram={campaign?.instagram}
                        youtube={campaign?.youtube}
                        podcast={campaign?.podcast}
                        image={campaign?.image}
                        id={campaign._id}
                        status={campaign?.status}
                        location={campaign?.location}
                    />
                    ))}
            </div>
         
        </UserRoute>
    );

}
