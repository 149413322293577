// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  color: white;
}

body {
  margin: 0;
}

p {
  margin: 0;
  color: white;
}

a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

h1,
h2,
h3,
h4 {
  color: white;
}

button {
  padding: 0em 0em;
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  box-sizing: border-box;
}
button:hover {
  background-color: #191716;
}

input {
  background-color: #03071e;
  color: white;
  border-radius: 0.75rem;
}
input input:focus,
input input:hover {
  outline: none !important;
  border: none !important;
}

.earnings-card__content {
  padding: 1rem 0rem;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.earnings-card__content__info {
  width: 100%;
}
.earnings-card__content__info-label {
  font-size: 0.73rem;
  opacity: 0.75;
}
.earnings-card__content__info-value {
  margin-top: 0.34rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/partials/_resets.scss","webpack://./src/Components/EarningsCard/EarningsCard.scss"],"names":[],"mappings":"AAIA;;;EAGC,sBAAA;EACA,SAAA;EACA,YAAA;ACHD;;ADMA;EACC,SAAA;ACHD;;ADMA;EACC,SAAA;EACA,YAAA;ACHD;;ADMA;EACC,qBAAA;EACA,aAAA;EACA,mBAAA;ACHD;;ADMA;;;;EAIC,YAAA;ACHD;;ADMA;EACC,gBAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,sBAAA;ACHD;ADKC;EACC,yBAAA;ACHF;;ADOA;EACC,yBAAA;EACA,YAAA;EACA,sBAAA;ACJD;ADMC;;EAEC,wBAAA;EACA,uBAAA;ACJF;;AA5CI;EACI,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,kBAAA;AA+CR;AA5CI;EACI,WAAA;AA8CR;AA1CI;EACI,kBAAA;EACH,aAAA;AA4CL;AAxCI;EACI,mBAAA;AA0CR","sourcesContent":["@use \"./fonts\" as *;\n@use \"./mixins\" as *;\n\n//Resets SASS\n*,\n*::before,\n*::after {\n\tbox-sizing: border-box;\n\tmargin: 0;\n\tcolor: white;\n}\n\nbody {\n\tmargin: 0;\n}\n\np {\n\tmargin: 0;\n\tcolor: white;\n}\n\na {\n\ttext-decoration: none;\n\tdisplay: flex;\n\talign-items: center;\n}\n\nh1,\nh2,\nh3,\nh4 {\n\tcolor: white;\n}\n\nbutton {\n\tpadding: 0em 0em;\n\twidth: 100%;\n\tborder: none;\n\tborder-radius: 0.5rem;\n\tbox-sizing: border-box;\n\n\t&:hover {\n\t\tbackground-color: #191716;\n\t}\n}\n\ninput {\n\tbackground-color: #03071e;\n\tcolor: white;\n\tborder-radius: 0.75rem;\n\n\tinput:focus,\n\tinput:hover {\n\t\toutline: none !important;\n\t\tborder: none !important;\n\t}\n}\n","@import \"../../styles/partials/mixins\";\n@import \"../../styles/partials/variables\";\n@import \"../../styles/partials/resets\";\n\n.earnings-card {\n\n    &__content {\n        padding: 1rem 0rem;\n        display: flex;\n        justify-content: space-between;\n        text-align: center;\n    }\n\n    &__content__info {\n        width: 100%;\n\n    }\n\n    &__content__info-label {\n        font-size: 0.73rem;\n\t    opacity: 0.75;\n\n    }\n\n    &__content__info-value {\n        margin-top: 0.34rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
