import React, { useContext } from 'react';

import './CampaignsOverview.scss'
import Card from '../Card/Card'
import { Link } from 'react-router-dom'
import ThemeContext from '../../context/ThemeContext';
export default function CampaignsOverview({ requests = 0, active = 0, past = 0 }) {


    const { themeMode } = useContext(ThemeContext);

    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    return (
        <div className={`campaigns-overview campaigns-overview--${themeClass}` }>
            <Card>
                <div className='campaigns-overview__header'>
                    <div className='campaigns-overview__filter'>
                    </div>
                </div>
                <div className='campaigns-overview__body'>
                    <Link className={`campaigns-overview__body-item campaigns-overview__body-item--${themeClass}`} to={"/campaigns/requests/"}>
                        <div className='campaigns-overview__body-item-title'>Requests</div>
                        <h3 className='campaigns-overview__body-item-value'>{requests}</h3>
                    </Link>
                    <Link className={`campaigns-overview__body-item campaigns-overview__body-item--${themeClass}`} to={`/campaigns/`}>
                        <div className='campaigns-overview__body-item-title'>Active</div>
                        <div className='campaigns-overview__body-item-value'>{active}</div>
                    </Link>
                    <Link className={`campaigns-overview__body-item campaigns-overview__body-item--${themeClass}`} to={`/campaigns/past/`}>
                        <div className='campaigns-overview__body-item-title'>Past</div>
                        <div className='campaigns-overview__body-item-value'>{past}</div>
                    </Link>
                </div>
            </Card>
        </div>
    )
}
