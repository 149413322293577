// TextInput.jsx

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './TextInput.scss'; // Ensure this path is correct based on your project structure

const TextInput = forwardRef(
  ({ id, label, value, onChange, placeholder, required, error, type = 'text', themeMode }, ref) => {
    return (
      <div className={`form-group ${themeMode}`}>
        <label htmlFor={id}>
          {label} {required && '*'}
        </label>
        <input
          type={type}
          id={id}
          ref={ref} // Forward the ref to the input element
          value={value}
          onChange={onChange}
          required={required}
          placeholder={placeholder}
          aria-describedby={`${id}-error`}
        />
        {error && (
          <span className={`error-message ${themeMode}`} id={`${id}-error`} role="alert">
            {error}
          </span>
        )}
      </div>
    );
  }
);

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  themeMode: PropTypes.string.isRequired,
};

TextInput.defaultProps = {
  placeholder: '',
  required: false,
  error: '',
  type: 'text',
};

export default TextInput;
