import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { Context } from "../context/index";
import ThemeContext from "../context/ThemeContext";
import "./Auth.scss";
import "./Header.scss";
import ChequaiLogo from "../assets/icons/chequai-logo.png";
import { apiGet } from "../utils/apiUtils";
import FooterComponent from "../Components/FooterComponent/FooterComponent";
import GetStartedModal from "../Components/GetStartedModal/GetStartedModal";
import OnboardingModal from "../Components/OnboardingModal/OnboardingModal";
import { OnBoardingIcon } from "./Icons/OnBoardingIcon";
import { LogoutIcon } from "./Icons/LogoutIcon";
import { HomeIcon } from "./Icons/HomeIcon";
import { PersonIcon } from "./Icons/PersonIcon";
import { NotificationIcon } from "./Icons/NotificationIcon";
import { MessageIcon } from "./Icons/MessageIcon";
import { SearchIcon } from "./Icons/SearchIcon";
import { OrdersIcon } from "./Icons/OrdersIcon";
import { PostIcon } from "./Icons/PostIcon";
import { GetStartedIcon } from "./Icons/GetStartedIcon";
import { SettingsIcon } from "./Icons/SettingsIcon";
import TwoFactorAuthLogin from "../Components/TwoFactorAuthLogin/TwoFactorAuthLogin";
// import chequeaiLogo from "../assets/icons/chequai-logo.png";

// Convert the VAPID key to a Uint8Array for use in the subscription
export function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}


export function askForNotificationPermission() {
  if ("Notification" in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      } else {
        console.log('Notification permission denied.');
      }
    });
  } else {
    console.log('This browser does not support notifications.');
  }
};




const UserRoute = ({ children }) => {
  const [ok, setOk] = useState(false);
  const { state } = useContext(Context);
  const { user } = state;
  const { themeMode } = useContext(ThemeContext);
  const [twoFactorVerified, setTwoFactorVerified] = useState(
    localStorage.getItem("twoFactorVerified") === "true"
  );
  const themeClass = themeMode === "light" ? "light-theme" : "";
  const [isGetStartedModalOpen, setGetStartedModalOpen] = useState(false);
  const [isOnboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const navigate = useNavigate();
  // const [isNotificationPageVisible, setIsNotificationPageVisible] =useState(false);

    // Register service worker and handle push subscription

  


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiGet("/current-user");
        if (response) {
          setOk(true);
        }
      } catch (error) {
        console.error("UserRoute error", error);
      }
    };

    if (user) {
      fetchUser();
    }
  }, [user]);

  useEffect(() => {
    if (!ok && !user) {
      navigate("/login");
    }
  }, [ok, user, navigate]);

  const handleLogout = async () => {
    try {
      await apiGet("/logout");
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("twoFactorVerified");
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  // Check if any twoFactorAuth method is enabled and if the user hasn't verified 2FA yet
  if (
    user?.twoFactorAuth?.some((authMethod) => authMethod.enabled) &&
    !twoFactorVerified
  ) {
    return (
      <TwoFactorAuthLogin
        themeMode={themeMode}
        setTwoFactorVerified={() => {
          setTwoFactorVerified(true);
          localStorage.setItem("twoFactorVerified", "true");
        }}
      />
    );
  }

  return (
    <div className={`auth auth--${themeClass}`}>
      <div className={`auth__link auth__link--${themeClass}`}>
        <NavLink
          className={`nav__link nav__link--m nav__link--${themeClass}`}
          to="/notifications"
          // onClick={() => setIsNotificationPageVisible(true)} // Set state to true when notifications page is opened
        >
          <NotificationIcon themeMode={themeMode} />
        </NavLink>
        <Link
          to="/home"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className="nav__icon nav__icon--profile"
            src={ChequaiLogo}
            alt="Chequeai"
          />
          <h1 className={`auth__title auth__title--${themeClass}`}>
            Chequeai
          </h1>
        </Link>
        <Link
          className={`nav__link nav__link--m nav__link--profile nav__link--${themeClass}`}
          to="/messages"
        >
          <MessageIcon themeMode={themeMode} />
        </Link>
      </div>
      <div className={`auth__app auth__app--${themeClass}`}>
        <div className={`auth__app-body auth__app-body--${themeClass}`}>
          {children}
          <FooterComponent country={user?.country} />
        </div>
        <nav className={`nav nav--${themeClass}`}>
          <div className="nav__m">
            <Link to="/home" className={`nav__link-title`}>
              <img
                className="nav__icon nav__icon--profile"
                src={ChequaiLogo}
                alt="Chequeai"
              />
              <h1 className={`auth__title auth__title--${themeClass}`}>
                Chequeai
              </h1>
            </Link>
            <NavLink
              className={`nav__link nav__link--home nav__link--${themeClass}`}
              to="/home"
            >
              <HomeIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Home
              </h3>
            </NavLink>

            <NavLink
              className={`nav__link nav__link--${themeClass}`}
              to="/featured"
            >
              <SearchIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Search
              </h3>
            </NavLink>
            <NavLink
              className={`nav__link nav__link--${themeClass}`}
              to="/post"
            >
              <PostIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Portfolio
              </h3>
            </NavLink>
            <NavLink
              className={`nav__link nav__link--${themeClass}`}
              to="/orders"
            >
              <OrdersIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Orders
              </h3>
            </NavLink>
            <NavLink
              className={`nav__link nav__link--profile nav__link--${themeClass} nav__link--mh`}
              to="/messages"
            >
              <MessageIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Messages
              </h3>
            </NavLink>

            {user?.username && (
              <NavLink
                className={`nav__link nav__link--profile nav__link--${themeClass}`}
                to={`/${user?.username}`}
              >
                <PersonIcon themeMode={themeMode} />
                <h3 className={`nav__link__text nav__text--${themeClass}`}>
                  Profile
                </h3>
              </NavLink>
            )}
            <NavLink
              className={`nav__link nav__link--${themeClass} nav__link--mh`}
              to="/notifications"
            >
              <NotificationIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Notifications
              </h3>
            </NavLink>
          </div>
          <div className="nav__tablet">
            {user &&
              (!user?.stripeAccountId ||
                !user?.promotions ||
                !user?.campaigns) && (
                <div
                  className={`nav__link nav__link--${themeClass} nav__link--desktop`}
                  onClick={() => setGetStartedModalOpen(true)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") setGetStartedModalOpen(true);
                  }}
                  role="button"
                  tabIndex={0}
                  style={{ cursor: "pointer" }}
                >
                  <GetStartedIcon themeMode={themeMode} />
                  <h3 className={`nav__link__text nav__text--${themeClass}`}>
                    Get Started
                  </h3>
                </div>
              )}
            {user && (!user?.firstName || !user?.socialMedia) && (
              <div
                className={`nav__link nav__link--${themeClass} nav__link--desktop`}
                onClick={() => setOnboardingModalOpen(true)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") setOnboardingModalOpen(true);
                }}
                role="button"
                tabIndex={0}
                style={{ cursor: "pointer" }}
              >
                <OnBoardingIcon themeMode={themeMode} />
                <h4 className={`nav__link__text nav__text--${themeClass}`}>
                  Account Setup
                </h4>
              </div>
            )}
            <NavLink
              className={`nav__link nav__link--${themeClass} nav__link--desktop`}
              to="/settings"
            >
              <SettingsIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Settings
              </h3>
            </NavLink>
            <NavLink
              className={`nav__link nav__link--${themeClass} nav__link--desktop`}
              to="/logout"
              onClick={handleLogout}
            >
              <LogoutIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Logout
              </h3>
            </NavLink>
          </div>
        </nav>

        {isGetStartedModalOpen && (
          <GetStartedModal
            isOpen={isGetStartedModalOpen}
            onClose={() => setGetStartedModalOpen(false)}
            themeMode={themeMode}
            profileUrl={user?.username || ""}
          />
        )}

        {isOnboardingModalOpen && (
          <OnboardingModal
            isOpen={isOnboardingModalOpen}
            onClose={() => setOnboardingModalOpen(false)}
            username={user?.username || ""}
            themeMode={themeMode}
          />
        )}
      </div>
    </div>
  );
};

export default UserRoute;
