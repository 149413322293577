import React, { useContext, useEffect } from 'react';
import './CampaignAnalyticsModal.scss';
import InputField from '../InputField/InputField';
import ThemeContext from '../../context/ThemeContext';

const CampaignAnalyticsModal = ({ children, isOpen, setIsOpen, onClose, message, onConfirm, label, instagram, tiktok, youtube, spotifyPodcast, spotifyMusic, setInstagramId, setTiktokId, setYoutubeId, setSpotifyPodcastId, setSpotifyMusicId }) => {

    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    // Close modal on "Escape" key press
    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        // Add event listener when modal is open
        if (isOpen) {
            window.addEventListener('keydown', handleEscKey);
        }

        // Cleanup event listener on component unmount or when modal is closed
        return () => {
            window.removeEventListener('keydown', handleEscKey);
        };
    }, [isOpen, onClose]);

    return (
        <div className={`confirm-modal`}>
            <div className="confirm-modal__overlay" onClick={onClose} />
            <div className={`confirm-modal__content confirm-modal__content--${themeClass}`}>
                <div className='confirm-modal__container'>
                    <div className={`confirm-modal__header confirm-modal__header--${themeClass}`}>
                        <p>{message}</p>
                    </div>
                    <div className={`confirm-modal__body confirm-modal__body--${themeClass}`}>
                        <InputField
                            label={"Instagram Post"}
                            placeholder={"Post ID"}
                            name="isOpen"
                            type="text"
                            value={isOpen === true ? "" : instagram}
                            onChange={(event) => setInstagramId(event.target.value)}
                        />
                    </div>
          
                        <div className={`confirm-modal__body confirm-modal__body--${themeClass}`}>
                            <InputField
                                label={"TikTok Post"}
                                placeholder={"Post ID"}
                                name="isOpen"
                                type="text"
                                value={isOpen === true ? "" : tiktok}
                                onChange={(event) => setTiktokId(event.target.value)}
                            />
                        </div>
                    <div className={`confirm-modal__body confirm-modal__body--${themeClass}`}>
                        <InputField
                            label={"Youtube Video"}
                            placeholder={"Video ID"}
                            name="isOpen"
                            type="text"
                      v      value={isOpen === true ? "" : youtube}
                            onChange={(event) => setYoutubeId(event.target.value)}
                        />
                    </div>
                    <div className={`confirm-modal__body confirm-modal__body--${themeClass}`}>
                        <InputField
                            label={"Spotify Podcast"}
                            placeholder={"Podcast ID"}
                            name="isOpen"
                            type="text"
                            value={isOpen === true ? "" : spotifyPodcast}
                            onChange={(event) => setSpotifyPodcastId(event.target.value)}
                        />
                    </div>
                    { spotifyMusic && (
                    <div className={`confirm-modal__body confirm-modal__body--${themeClass}`}>
                        <InputField
                            label={"Spotify Music"}
                            placeholder={"Music ID"}
                            name="isOpen"
                            type="text"
                            value={isOpen === true ? "" : spotifyMusic}
                            onChange={(event) => setSpotifyMusicId(event.target.value)}
                        />
                    </div>
                    )}
                    {children}
                    <div className={`confirm-modal__footer confirm-modal__footer--${themeClass}`}>
                        <button className={`confirm-modal__button confirm-modal__button--cnl confirm-modal__button--cnl--${themeClass} confirm-modal__button--${themeClass}`} onClick={onClose}>
                            <p className='confirm-modal__cancel'>Cancel</p>
                        </button>
                        <button className={`confirm-modal__button confirm-modal__button--${themeClass}`} onClick={handleConfirm}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampaignAnalyticsModal;
