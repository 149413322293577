import React, { useState, useContext } from 'react';
import axios from 'axios';
import ThemeContext from "../../context/ThemeContext";

import './EmailListComponent.scss';

const EmailListComponent = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // const response = 
            await axios.post(`${process.env.REACT_APP_API}/email-list`, { email });
            setMessage('Stay tuned for our next campaign 😀');
            setEmail('');
        } catch (error) {
            setMessage('There was an error subscribing, please try again.');
        }
    };

    return (
        <div className='email_list'>
            <h4 className='email_list__title'>Join Our Newsletter</h4>
            <form className='email_list__form' onSubmit={handleSubmit}>
                <h3 className='email_list__label'>Email</h3>
                <input
                    type='email'
                    className={`email_list__input email_list__input--${themeClass}`}
                    placeholder='youremail@example.com'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type='submit' className={`email_list__button email_list__button--${themeClass}`}>Subscribe</button>
            </form>
            {message && <p className='email_list__message'>{message}</p>}
        </div>
    );
};

export default EmailListComponent;
