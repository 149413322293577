import React, { useState, useContext } from 'react';
import './SignUpModal.scss';
import logo from '../../assets/icons/chequai-logo.png';
import axios from 'axios';
import { isValidEmail } from '../../utils/authUtils';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/index';
import Cookies from 'js-cookie';
import {Link} from 'react-router-dom';

const SignUpModal = ({ showModal, setShowModal, setShowLoginModal }) => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
  const [isEmailAvailable, setIsEmailAvailable] = useState(null);
  const [passwordMatchError, setPasswordMatchError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  // const [agreedToTerms, setAgreedToTerms] = useState(false); // New state for terms agreement
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);

  // Async function to check if the username is available
  const checkUsernameAvailability = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/onboarding/check-username`, {
        params: { username }
      });
      setIsUsernameAvailable(response.data.available);
    } catch (error) {
      console.error('Error checking username:', error);
    }
  };

  // Async function to check if the email is available
  const checkEmailAvailability = async () => {
    if (!isValidEmail(email)) {
      setEmailError('Invalid email format');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/onboarding/check-email`, {
        params: { email }
      });
      setIsEmailAvailable(response.data.available);
      setEmailError(null);
    } catch (error) {
      console.error('Error checking email:', error);
    }
  };

  // Handle form submission and send data to the backend
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for valid email before submission
    if (!isValidEmail(email)) {
      setEmailError('Invalid email format');
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setPasswordMatchError('Passwords do not match!');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/expressSignup`, {
        email,
        username,
        password
      });

      if (response.data && response.data.token && response.data.user) {
        // Auto-login the user
        // Save the token cookie
        Cookies.set('_token', response.data.token, { expires: 7 });

        // Dispatch login action to update global state
        dispatch({
          type: 'LOGIN',
          payload: response.data.user
        });

        // Save user data in localStorage
        window.localStorage.setItem('user', JSON.stringify(response.data.user));

        // Close the modal
        setShowModal(false);

        // Redirect to user's profile page
        navigate(`/${username}`);
        window.location.reload();
      } else {
        console.error('Signup failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error during signup:', error);
    }
  };

  return (
    <>
      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={logo} alt="ChequeAI Logo" className="logo-image" />
            <h2>Signup to Chequeai</h2>
            <form onSubmit={handleSubmit}>
              {/* Email input */}
              <div className="input-container">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={checkEmailAvailability}
                  required
                />
                {isEmailAvailable && <span className="available-icon">✔️</span>}
                {isEmailAvailable === false && <span className="error-icon">❌</span>}
                {emailError && <p className="error-text">{emailError}</p>}
              </div>

              {/* Username input */}
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onBlur={checkUsernameAvailability}
                  required
                />
                {isUsernameAvailable && <span className="available-icon">✔️</span>}
                {isUsernameAvailable === false && <span className="error-icon">❌</span>}
              </div>

              {/* Password input */}
              <div className="input-container">
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span
                  className="toggle-password"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </span>
              </div>

              {/* Confirm Password input */}
              <div className="input-container">
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <span
                  className="toggle-password"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </span>
              </div>

              {passwordMatchError && <p className="error-text">{passwordMatchError}</p>}

              {/* Terms and Conditions Agreement */}
              <div className="modal__terms-container">
                <label htmlFor="terms" className="terms__label">
                  <Link to="/terms-of-service" className="terms-link">
                    By signing up, you agree to our Terms of Service, Privacy Policy, and Cookies Policy.
                  </Link>
                </label>
              </div>

              {/* Submit button */}
              <button type="submit" className="signup-button">
                Sign Up
              </button>
            </form>
            <p className="login-text">
              Already have an account?{' '}
              <span
                className="login-link"
                onClick={() => {
                  setShowModal(false);
                  setShowLoginModal(true);
                }}
              >
                Log In
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpModal;
