import React, { useContext, useState, useEffect } from 'react';
import './PostModal.scss';
import ThemeContext from '../../context/ThemeContext';
import Button from '../Button/Button';
import axios from "axios"
import Resizer from 'react-image-file-resizer';
import VideoPlayer from '../VideoPlayer/VideoPlayer';


const PostModal = ({ isOpen, setIsOpen, onClose, message, label, user }) => {
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [caption, setCaption] = useState('');
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    let modifierClass = image?.type.includes('video') ? "video" : (image?.type.includes('image') ? "image" : "");



    const handleConfirm = () => {
        setIsOpen(image)
        handleCreatorCampaign()
        onClose();
    };

    const validateImg = (e) => {
        const file = e.target.files[0];
        if (file.size > 2048576) {
            console.error('Max file size 1mb', { position: 'top-center' });
        } else {
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
    };


    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file.size > 5048576) {
            console.error('Max file size 1mb', { position: 'top-center' });
        } else {
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    useEffect(() => {
        const handleWindowDragOver = (e) => {
            e.preventDefault();
        };

        window.addEventListener('dragover', handleWindowDragOver);

        return () => {
            window.removeEventListener('dragover', handleWindowDragOver);
        };
    }, []);

    // const ZoomIcon = () => {
    //     const color = themeMode === 'light' ? '#b3b2b2' : 'white';
    //     return (
    //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={color} class="bi bi-zoom-in" viewBox="0 0 16 16">
    //             <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
    //             <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
    //             <path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z" />
    //         </svg>
    //     )
    // }
    // const CropIcon = () => {
    //     const color = themeMode === 'light' ? '#b3b2b2' : 'white';

    //     return (
    //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={color} class="bi bi-arrows-angle-expand" viewBox="0 0 16 16">
    //             <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z" />
    //         </svg>
    //     )
    // }

    // const ImagesIcon = () => {
    //     const color = themeMode === 'light' ? '#b3b2b2' : 'white';
    //     return (
    //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={color} class="bi bi-images" viewBox="0 0 16 16">
    //             <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
    //             <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
    //         </svg>
    //     )
    // };


    const ImageIcon = () => {
        const color = themeMode === 'light' ? '#b3b2b2' : 'white';

        return (
            <div className='post-modal__icons'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='50'
                    height='50'
                    fill={color}
                    className='post-modal__svg'
                    viewBox='0 0 16 16'
                >
                    <path d='M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z' />
                    <path d='M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12V2z' />
                </svg>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='50'
                    height='50'
                    fill={color}
                    className='post-modal__svg post-modal__svg--pt'
                    viewBox='0 0 16 16'
                >
                    <path d='M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z' />
                    <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                </svg>
            </div>
        );
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const mobileBreakpoint = 768;
    // const tabletBreakpoint = 1024;

    // const VideoIcon = () => {
    //     // const color = themeMode === 'light' ? '#b3b2b2' : 'white';

    //     return <></>;
    // };
    // const [showInfo, setShowInfo] = useState(false);
    // const [isExpanded, setIsExpanded] = useState(false)

    // ...

    // const handleNext = () => {
    //     // setShowInfo(true);
    //     // setIsExpanded(true);
    // };

    let divStyle;
    let divStyle1;

    if (windowWidth < mobileBreakpoint) {
        modifierClass = image?.type.includes('video') ? "video" : (image?.type.includes('image') ? "image" : "");
        divStyle1 = {
            height: !imagePreview ? '0' : '250px',
            width: !imagePreview ? '0' : '100%',
            transition: 'height 0.5s ease-in-out',
        };
    }


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleCreatorCampaign = async () => {
        console.log(image)
        console.log(image.type)
        if (image && ['image/jpeg', 'image/png', 'image/gif'].includes(image.type)) {
            try {
                Resizer.imageFileResizer(image, 750, 750, 'PNG', 100, 0, async (uri) => {
                    try {
                        const { data } = await axios.put(`${process.env.REACT_APP_API}/user/upload-photo`,
                            { image: uri, caption }
                        );
                        console.log('IMAGE UPLOADED', data);
                        // Set image in the state
                        console.log('Updated');
                        window.location.reload();
                    } catch (err) {
                        console.log('Failed to upload. Try again later.', err);
                    }
                });
            } catch (error) {
                console.log('Failed to resize image. Try again later.', error);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append('video', image, caption);
                for (var key of formData.entries()) {
                    console.log(key[0] + ', ' + key[1]);
                }
                console.log(caption)
                const { data } = await axios.put(`${process.env.REACT_APP_API}/user/upload-video`, formData, caption);
                console.log('VIDEO UPLOADED', data);
                window.location.reload();
            } catch (err) {
                console.log('Failed to upload. Try again later.', err);
            }
        }
    };

    return (
        <div className={`post-modal`}>
            <div className='post-modal__overlay' onClick={onClose} />
            <div className={`post-modal__content post-modal__content--${themeClass} ${imagePreview ? 'post-modal__content--animtate' : ''} post-modal__content--${modifierClass} `} style={divStyle}>
                <div
                    className='post-modal__container'
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <div className={`post-modal__header post-modal__header--${themeClass}`}>
                        <div className={`post-modal__header__left post-modal__header__left--${themeClass}`}>
                            <Button labelModifier={'button--text button--text--cl'} onClick={() => { onClose() }}>
                                Cancel
                            </Button>
                        </div>
                        <div className={`post-modal__header__centre post-modal__header__cancel--${themeClass}`}>
                            <p className={`post-modal__text post-modal__text--${themeClass}`}>Post</p>
                        </div>
                        <div className={`post-modal__header__right post-modal__header__right--${themeClass}`}>
                            <Button labelModifier={'button--text'} onClick={() => { handleConfirm() }} disabled={imagePreview === null}>
                                Next
                            </Button>
                        </div>
                    </div>
                    <div className={`post-modal__body post-modal__body--${themeClass} post-modal__body--${modifierClass}`}>
                        <label
                            htmlFor='file'
                            className={`post-modal__text post-modal__text--${modifierClass}`}
                        >
                            {!imagePreview && (
                                <div className='post-modal__instructions'>
                                    {<p className={`post-modal__label post-modal__label--${themeClass}`}>Drag photos and videos here</p>}
                                    {<ImageIcon />}
                                </div>
                            )}
                            {imagePreview && image.type.includes("image") && <img className='post-modal__img' src={imagePreview} alt='profile' />}
                            {image && image.type.includes("video") && <VideoPlayer videoArray={[imagePreview]} modifier={['video-player--height', 'video-player__video-view', 'video-player__video-home', 'video-player--home']} />
                            }


                        </label>
                        {!imagePreview && (
                            <input type='file' id='file' hidden accept='image/png, image/jpeg, image/webp' onChange={validateImg} />
                        )}
                        <div className={`post-modal__info ${imagePreview ? 'post-modal__info--show' : ''}`} style={divStyle1}>
                            <div className={`post-modal__info__header post-modal__info__header--${themeClass}`}>
                                <img className='post-modal__up' src={user?.picture} alt='profile' />
                                <p className={`post-modal__un post-modal__un--${themeClass}`}>{user?.username}</p>
                            </div>
                            <textarea className={`post-modal__caption post-modal__caption--${themeClass}`}
                                placeholder='Write a caption...'
                                value={caption}
                                onChange={(e) => setCaption(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default PostModal;
