import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '../../Pages/UserPage/icons/MenuIcon';
import './ProductCard.scss';


import Card from '../Card/Card';

 const ProductCard = ({ product = {}, index, themeMode, themeClass, setShopModule }) => {


  return (
    <Card key={index}>
    <div className="product-card__container">
      <div className="product-card__image-container">
        <img src={product.images?.[0]?.url || 'default-image-url'} alt="product" className="product-card__image" />
      </div>
      <div className="product-card__details">
        <div className="product-card__details-header">
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <h2 className="product-card__name">{product.name || 'Default Name'}</h2>
            <div onClick={() => setShopModule(product._id)} className="product-card__link--options">
              <MenuIcon themeMode={themeMode} />
            </div>
          </div>
          <RouterLink to={product.url || '#'} className={`product-card__link product-card__link--${themeClass}`}>
            <p className="product-card__price">${product.price || 0}</p>
          </RouterLink>
        </div>
        <div className="product-card__details-body">
          <p className="product-card__description">{product.category || 'Category: ???'}</p>
          <p className="product-card__description">{product.sku || 'SKU: ???'}</p>
          <p className="product-card__description">{product.color || 'Color: ???'}</p>
          <p className="product-card__description">{product.description || 'Description: ???'}</p>
        </div>
      </div>
    </div>
  </Card>
  
  );
};

export default ProductCard;

