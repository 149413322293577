import { Context } from "../../context/index";
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import Card from "../../Components/Card/Card";
import InputField from '../../Components/InputField/InputField';
import Button from '../../Components/Button/Button';
import "./ChangePasswordPage.scss";
import Navigation from "../../Components/Navigation/Navigation";
import WebAuthnRegister from "../../Components/WebAuthnRegister/WebAuthnRegister";
import ThemeContext from "../../context/ThemeContext";
import TwoFactorAuth from "../../Components/TwoFactorAuth/TwoFactorAuth";


export default function ChangePasswordPage() {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [username, setUsername] = useState("");
  const { state: { user }, } = useContext(Context);
  const { themeMode } = useContext(ThemeContext);

  useEffect(() => {
    if (user !== null) {
      setUsername(user.username);
    }
  }, [user]);

  const { currentPassword, newPassword, confirmNewPassword } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert("New passwords do not match.");
      return;
    }
    // Handle form submission here
  };

  return (
    <UserRoute username={username}>
      <div className="ch-pass-pg">
        <Navigation heading={"Change Password"} back={true} />
        <Card className="card--padding card--settings--tp">
          <form onSubmit={handleSubmit}>
            <InputField
              label="Current Password"
              name="currentPassword"
              type="password"
              value={currentPassword}
              onChange={handleInputChange}
            />
            <InputField
              label="New Password"
              name="newPassword"
              type="password"
              value={newPassword}
              onChange={handleInputChange}
            />
            <InputField
              label="Confirm New Password"
              name="confirmNewPassword"
              type="password"
              value={confirmNewPassword}
              onChange={handleInputChange}
            />
            <Button>
              <p>Update</p>
            </Button>
          </form>
        </Card>
        {false && <>
          <h2 className="passkeys-title">Passkeys</h2>
          <Card className="card--padding card--settings--tp">
            <WebAuthnRegister user={user} themeMode={themeMode} />
          </Card>
        </>}

        <h2 className="passkeys-title">2-Factor Auth (2FA)</h2>
        <Card className="card--padding card--settings--tp">
          <TwoFactorAuth user={user} themeMode={themeMode} />
        </Card>
      </div>
    </UserRoute>
  );
}
