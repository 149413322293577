import React from 'react'
import './CheckoutComponent.scss'
import Button from '../Button/Button'
export default function CheckoutComponent({ price, handleCheckout }) {

    return (
        <div className='checkout-cmp'>
            <div className='checkout-cmp__amount'>
                <Button modifier={"button--padding button--money"} onClick={handleCheckout}>${price}.00</Button>
            </div>
        </div>
    )
}
