// EarningsStatement.jsx
import React, { useContext } from 'react';
import './EarningsStatement.scss';
import { Link } from 'react-router-dom';
import ThemeContext from '../../context/ThemeContext';
import { Tooltip } from 'react-tooltip'; // Import Tooltip

export default function EarningsStatement({
  username,
  campaignStatus,
  campaignName = "",
  transactionDate,
  fee,
  sponsorOrDonatorLabel,
  sponsorOrDonatorName,
  creator,
  campaignId,
  handleAccept,
  handleDecline,
  paymentStatus,
  currency,
  totalAfterFees, // Ensure this prop is passed
}) {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  return (
    <div className={`earnings-statement earnings-statement--${themeClass}`}>
      <div className="earnings-statement__item">
        <div className="earnings-statement__item-header">
          <p className="earnings-statement__item-title">{truncateCampaignTitle(campaignName)}</p>
          <p className="earnings-statement__item-date">{transactionDate}</p>
        </div>
        <div className="earnings-statement__item-body">
          <div className="earnings-statement__item__sub-left-body">
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '0.5rem' }}>
              <p className="earnings-statement__item-brand">{sponsorOrDonatorLabel}:</p>
              <span className="earnings-statement__item-brand-name">{sponsorOrDonatorName}</span>
            </div>
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              <p className="earnings-statement__item-creator">(creator):</p>
              <span className='earnings-statement__item-creator-name'>{creator}</span>
            </div>
          </div>
          <div className="earnings-statement__campaign-status">
            {campaignStatus === 'Pending' && (
              <Link
                to={username === creator ? `/campaigns/requests/` : `/campaigns/outgoing/`}
                className="earnings-statement__campaign-link"
              >
                {campaignId}
              </Link>
            )}
            {campaignStatus !== 'Pending' && (
              <Link to={`/campaigns/${campaignId}`} className="earnings-statement__campaign-link">
                {campaignId}
              </Link>
            )}
            {campaignStatus === 'Cancelled' && (
              <Link
                to={username === creator ? `/campaigns/past/` : `/campaigns/outgoing/`}
                className="earnings-statement__campaign-link"
              >
                {campaignId}
              </Link>
            )}
            {campaignStatus === 'Pending' && username === creator ? (
              <div className="earnings-statement__campaign-cta">
                <button
                  className={`earnings-statement__campaign-status__refund-btn earnings-statement__campaign-status__refund-btn--${themeClass}`}
                  alt="Refund"
                  onClick={() => handleDecline(campaignId, sponsorOrDonatorName)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#E01C34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="earnings__icon">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M10 10l4 4m0 -4l-4 4" />
                  </svg>
                </button>
                <button
                  className={`earnings-statement__campaign-status__accept-btn earnings-statement__campaign-status__accept-btn--${themeClass}`}
                  alt="Accept Campaign"
                  onClick={() => handleAccept(campaignId, sponsorOrDonatorName)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="green" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" className="earnings__icon">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx={12} cy={12} r={9} />
                    <path d="M9 12l2 2l4 -4" />
                  </svg>
                </button>
              </div>
            ) : (
              <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '0.9rem', minWidth: '100px' }}>
                {campaignStatus}
              </p>
            )}
          </div>
          <div className="earnings-statement__item__sub-right-body">
            {/* Updated line with Tooltip integration */}
            <p
              className="earnings-statement__item-amount"
              data-tooltip-id={`tooltip-amount-${campaignId}`}
              data-tooltip-content={`Total After Fees: $${(totalAfterFees / 100).toFixed(2)} ${currency}`}
            >
              ${(fee / 100).toFixed(2)} {currency}
            </p>
            <Tooltip id={`tooltip-amount-${campaignId}`} place="top" effect="solid" />
            <p className="earnings-statement__item-status">{paymentStatus}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function truncateCampaignTitle(title) {
  const maxLength = 25; // Adjust as needed
  if (title.length > maxLength) {
    return title.substring(0, maxLength) + '...';
  }
  return title;
}
