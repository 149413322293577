import { Link } from 'react-router-dom';
import './NotFoundPage.scss';
function NotFoundPage() {
  return (
    <div className='error-pg'>
      <h1 className='error-pg__title'>Page not found</h1>
      <p className='error-pg__text'>Sorry, we're unable to find what you're looking for.</p>
      <p className='error-pg__link'>
        <Link to="/" className='error-pg__link-text'>Home</Link>
      </p>
    </div>
  );
}

export default NotFoundPage;
