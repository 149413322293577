import React, { useContext }  from 'react'
import Card from "../Card/Card"
import Description from '../Description/Description'
import CheckoutComponent from '../CheckoutComponent/CheckoutComponent'
import PromoteHighlight from '../PromoteHighlight/PromoteHighlight'
import PromotionPostCard from '../PromotionPostCard/PromotionPostCard'
import './PromoteCard.scss'
// import { Link } from 'react-router-dom'
import ThemeContext from '../../context/ThemeContext';
import MenuIcon from '../../Pages/UserPage/icons/MenuIcon';


export default function PromoteCard({ name, description, images, price, handleCheckout, tikTok, instagram, edit, youtube, podcast, id, setMenu }) {
  const { themeMode } = useContext(ThemeContext);

  // const EditIcon = () => {
  //   const color = themeMode === 'light' ? 'black' : 'white';

  //     return(
  //       <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-text-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke={color} fill={color} strokeLinecap="round" strokeLinejoin="round">
  //         <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
  //         <path d="M19 10h-14"></path>
  //         <path d="M5 6h14"></path>
  //         <path d="M14 14h-9"></path>
  //         <path d="M5 18h6"></path>
  //         <path d="M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z"></path>
  //       </svg>
  //     )
  // }


  return (
    <Card className={"card--promote"}>
      <div className='promote-card__title-container'>
        <h3 className='promote-card__title'>{name}</h3>
        {
          edit &&
          <div to={`/settings/promotions/edit/${id}`} onClick={() => setMenu(id)}>
            <MenuIcon themeMode={themeMode} />
          </div>
        }
      </div>
      <PromoteHighlight images={images} />
      <Description description={description} />
      <div className='promote-card__post-container'>
        <PromotionPostCard name={"Instagram"} enabled={instagram && instagram.enabled} posts={instagram && instagram.quantity} />
        <PromotionPostCard name={"TikTok"} enabled={tikTok && tikTok.enabled} posts={tikTok && tikTok.quantity} />
        <PromotionPostCard name={"Youtube"} enabled={youtube && youtube.enabled} posts={youtube && youtube.quantity} />
        <PromotionPostCard name={"Podcast"} enabled={podcast && podcast.enabled} posts={podcast && podcast.quantity} />
      </div>
      <CheckoutComponent price={price} handleCheckout={handleCheckout} />
    </Card>
  )
}
