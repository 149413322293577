// src/components/StatsWidget/StatsWidget.jsx

import React from 'react';
import './StatsWidget.scss'; // Import the corresponding SCSS

const StatsWidget = ({ title, value, color, themeClass }) => {
    return (
        <div className={`stats-widget ${color ? `stats-widget--${color}` : ''} stats-widget--${themeClass}`}>
            <p className="stats-widget__title">{title}</p>
            <p className="stats-widget__value">{value}</p>
        </div>
    );
};


export default StatsWidget;
