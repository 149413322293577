import { Link } from 'react-router-dom';
import './StripeSuccessPage.scss';
import UserRoute from '../../Routes/Auth';
import React from 'react';

function StripeSuccessPage() {
    return (
        <UserRoute>
            <div className='stripe-success-pg'>
                <h1 className='stripe-success-pg__title'>🎉 Success! Your Stripe Account is Linked!</h1>
                <p className='stripe-success-pg__link'>
                    <Link to="/home" className='stripe-success-pg__link-text'>Home</Link>
                </p>
            </div>
        </UserRoute>
    );
}

export default StripeSuccessPage;
