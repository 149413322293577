import React, { useEffect, useState, useContext } from 'react';
import './CampaignPage.scss';
import Navigation from '../../Components/Navigation/Navigation';
import CampaignCard from '../../Components/CampaignCard/CampaignCard';
import UserRoute from '../../Routes/Auth';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import OptionsModalBox from '../../Components/OptionsModalBox/OptionsModalBox';
import ImageModal from '../../Components/ImageModal/ImageModal';
import { Context } from '../../context';
// import ThemeContext from '../../context/ThemeContext';


export default function CampaignPage() {
  const [campaign, setCampaign] = useState(null);
  const [brandPortfolio, setBrandPortfolio] = useState([]);
  const [uploadBrandImage, setUploadBrandImage] = useState(null);
  const [uploadBrandPreviewImage, setUploadBrandPreviewImage] = useState(null);
  const [uploadCreatorPreviewImage, setUploadCreatorPreviewImage] = useState(null);
  const [uploadCreatorImage, setUploadCreatorImage] = useState(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [campaignImage, setCampaignImage] = useState(null);
  const [campaignImagePreview, setCampaignImagePreview] = useState(null);

  const { state: { user }, } = useContext(Context);
  // const { themeMode } = useContext(ThemeContext);
  // const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';



  const handleItemClick = (item, brand, creator) => {
    setSelectedItem(item);
    console.log(item, brand, creator);
    if (brand) {
      console.log('brand', brand);
      setShowModal(brand);
    } else {
      setShowModal(creator);
    }
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setShowModal(false);
  };




  const [videoData, setVideoData] = useState(new FormData());
  const { id } = useParams();
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState('');

  const handleBrandCampaign = async () => {
    if (uploadBrandImage && ['image/jpeg', 'image/png', 'image/gif'].includes(uploadBrandImage.type)) {
      try {
        Resizer.imageFileResizer(uploadBrandImage, 750, 750, 'PNG', 100, 0, async (uri) => {
          try {
            const { data } = await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}/upload-brand-photo`,
              { image: uri }
            );
            console.log('IMAGE UPLOADED', data);
            // Set image in the state
            console.log('Updated');
            window.location.reload();
          } catch (err) {
            console.log('Failed to upload. Try again later.', err);
          }
        });
      } catch (error) {
        console.log('Failed to resize image. Try again later.', error);
      }
    } else {
      try {
        console.log(uploadBrandImage)
        const formData = new FormData();
        formData.append('video', uploadBrandImage);
        setVideoData(formData);

        for (var key of formData.entries()) {
          console.log(key[0] + ', ' + key[1]);
        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}/upload-brand-video`, formData);
        console.log('VIDEO UPLOADED', data);
        // Handle the response data and state updates
        console.log('Updated');
      } catch (err) {
        console.log('Failed to upload. Try again later.', err);
      }
    }
  };

  const handleCreatorCampaign = async () => {
    console.log(uploadCreatorImage)
    console.log(uploadCreatorImage.type)
    if (uploadCreatorImage && ['image/jpeg', 'image/png', 'image/gif'].includes(uploadCreatorImage.type)) {
      try {
        Resizer.imageFileResizer(uploadCreatorImage, 750, 750, 'PNG', 100, 0, async (uri) => {
          try {
            const { data } = await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}/upload-creator-photo`,
              { image: uri }
            );
            console.log('IMAGE UPLOADED', data);
            window.location.reload();

            // Set image in the state
            console.log('Updated');
          } catch (err) {
            console.log('Failed to upload. Try again later.', err);
          }
        });
      } catch (error) {
        console.log('Failed to resize image. Try again later.', error);
      }
    } else {
      try {
        console.log(uploadCreatorImage)
        const formData = new FormData();
        formData.append('video', uploadCreatorImage);
        setVideoData(formData);

        for (var key of formData.entries()) {
          console.log(key[0] + ', ' + key[1]);
        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}/upload-creator-ad`, formData);
        console.log('VIDEO UPLOADED', data);
        // Handle the response data and state updates
        console.log('Updated');
      } catch (err) {
        console.log('Failed to upload. Try again later.', err);
      }
    }
  };

  const handleUpdateImage = async (file) => {
    try {
      setCampaignImage(file);
      Resizer.imageFileResizer(file, 750, 750, 'PNG', 100, 0, async (uri) => {
        try {
          const { data } = await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}/main-photo`, { image: uri });
          console.log('IMAGE UPLOADED', data);
          // Handle the response data and state updates
          console.log('Updated');
          window.location.reload();

        } catch (err) {
          console.log('Failed to upload. Try again later.', err);
        }
      });
    } catch (error) {
      console.log('Failed to resize image. Try again later.', error);
    }
  };

  const handleRemoveImage = async () => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/campaigns/${id}/main-photom`, { image: selectedItem.url });
      console.log('IMAGE REMOVED', data);
      // Handle the response data and state updates
      console.log('Updated');
      window.location.reload();

    } catch (err) {
      console.log('Failed to remove. Try again later.', err);
    }
  }

  const handleDeleteCampaignImage = async (imageURL) => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/campaigns/${id}/campaign-content/${imageURL}`);
      console.log('IMAGE REMOVED', data);
      // Handle the response data and state updates
      console.log('Updated');
      window.location.reload();

    } catch (err) {
      console.log('Failed to remove. Try again later.', err);
    }
  }
  const fetchComments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}/comments`);
      setComments(response.data.comments);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleAddComment = async (id) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}/comments`, {
        comment,
      });
      console.log(res);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCampaignCompletion = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}/complete`);
      console.log(res);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  }

  const handleRevisionReq = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}/revision`);
      console.log(res);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  }



  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}/`);
        const fetchedCampaign = response.data;
        setCampaign(fetchedCampaign);
        // console.log(response.data);

      } catch (error) {
        console.error(error);
      }
    };
    if (!campaign) {
      fetchCampaign();
    }
  }, [campaign, id,]);

  return (
    <UserRoute UserRoute>
      <div className="campaign-pg">
        <Navigation heading={campaign?.campaignName} back={true} edit={`/campaigns/edit/${id}`} />
        <CampaignCard
          username={user?.username}
          campaign={campaign}
          handleBrandCampaign={handleBrandCampaign}
          handleCreatorCampaign={handleCreatorCampaign}
          brandPortfolio={brandPortfolio}
          uploadBrandImage={uploadBrandImage}
          uploadCreatorImage={uploadCreatorImage}
          setUploadBrandImage={setUploadBrandImage}
          setUploadCreatorImage={setUploadCreatorImage}
          setBrandPortfolio={setBrandPortfolio}
          uploadBrandPreviewImage={uploadBrandPreviewImage}
          setUploadBrandPreviewImage={setUploadBrandPreviewImage}
          uploadCreatorPreviewImage={uploadCreatorPreviewImage}
          setUploadCreatorPreviewImage={setUploadCreatorPreviewImage}
          videoData={videoData}
          setOptionsModal={setIsOptionsOpen}
          handleItemClick={handleItemClick}
          campaignPreview={campaignImagePreview}
          handlePostComment={handleAddComment}
          comments={comments}
          fetchComments={fetchComments}
          comment={comment}
          setComment={setComment}
          handleCampaignCompletion={handleCampaignCompletion}
          handleRevisionReq={handleRevisionReq}


        />
      </div>
      {isOptionsOpen !== false && (
        <OptionsModalBox
          isOpen={isOptionsOpen}
          setIsOpen={setIsOptionsOpen}
          handleUpdateImage={handleUpdateImage}
          image={campaignImage}
          handleRemoveImage={handleRemoveImage}
          setImagePreview={setCampaignImagePreview}
        />
      )}
      {showModal && (<ImageModal user={showModal} imageUrl={selectedItem.url} onClose={() => handleCloseModal()} type={selectedItem?.type} handleDelete={() => handleDeleteCampaignImage(selectedItem.key)} />)}

    </UserRoute>
  );
}
