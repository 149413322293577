import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './step.scss'; // Adjust the path as per your project structure
import TextInput from '../../TextInput/TextInput'; // Reusable component for consistent styling
import Select from "react-select";

function ProfessionalDetailsStep({ data, onChange, onNext, onBack, themeMode }) {
  const [userType, setUserType] = useState(data.userType || '');
  const [bio, setBio] = useState(data.bio || '');
  const [categories, setCategories] = useState(data.categories || []);
  const [errors, setErrors] = useState({});

  const themeClass = themeMode === "light" ? "light-theme" : "";


  const options = [
    { value: "Artist", label: "Artist" },
    { value: "Actor", label: "Actor" },
    { value: "Athlete", label: "Athlete" },
    { value: "Automotive", label: "Automotive" },
    { value: "Beauty", label: "Beauty" },
    { value: "Content Producer", label: "Content Producer" },
    { value: "Content Creator", label: "Content Creator" },
    { value: "Comedian", label: "Comedian" },
    { value: "Content Pages", label: "Content Pages" },
    { value: "Creative", label: "Creative" },
    { value: "Fashion", label: "Fashion" },
    { value: "Fitness", label: "Fitness" },
    { value: "Food", label: "Food" },
    { value: "Gaming", label: "Gaming" },
    { value: "Health", label: "Health" },
    { value: "Lifestyle", label: "Lifestyle" },
    { value: "Live Streaming", label: "Live Streaming" },
    { value: "Music", label: "Music" },
    { value: "Music Production", label: "Music" },
    { value: "Model", label: "Model" },
    { value: "Photographer", label: "Photographer" },
    { value: "Podcasting", label: "Podcasting" },
    { value: "Street Wear", label: "Street Wear" },
    { value: "Tech", label: "Tech" },
    { value: "Travel", label: "Travel" },
    { value: "Writer", label: "Writer" },
    { value: "Copy-Writer", label: "Copy-Writer" },
    { value: "Video Editor", label: "Video Editor" },
  ];

  const darkMode = {
    control: (base) => ({
      ...base,
      backgroundColor: "#141414",
      borderRadius: "4px",
      border: "1px solid #555555",
      boxShadow: "none",
      marginBottom: "1rem",
      "&:hover": {
        border: "none",
        boxShadow: "none",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#141414",
      borderRadius: "4px",
      fontSize: "14px",
    }),
    option: (base, state) => ({
      ...base,
      color: "white",
      backgroundColor: state.isFocused ? "neutral70" : base.backgroundColor,
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "dangerLight",
      borderRadius: "4px",
      display: "inline-flex",
      alignItems: "center",
      padding: "2px 6px",
      marginRight: "4px",
      marginTop: "4px",
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "white",
      fontSize: "14px",
      fontWeight: "600",
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "white",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "none",
        color: "dangerLight",
      },
    }),
  };

  const lightMode = {
    control: (base) => ({
      ...base,
      backgroundColor: "#E1E1E1",
      borderRadius: "4px",
      border: "none",
      boxShadow: "none",
      "&:hover": {
        border: "none",
        boxShadow: "none",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#E1E1E1",
      borderRadius: "4px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
      "&:hover": {
        backgroundColor: "neutral70",
      },
    }),
    option: (base, state) => ({
      ...base,
      color: "black",
      backgroundColor: state.isFocused ? "neutral70" : "#E1E1E1",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "black",
    }),
  };

  // Handle category selection logic
  const handleCategoryChange = (selectedOptions) => {
    setCategories(selectedOptions);
  };

  // Validation function
  const validate = () => {
    const newErrors = {};

    // Validate User Type (required)
    if (!userType.trim()) {
      newErrors.userType = 'User Type is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validate()) {
      onChange({ userType, bio, categories });
      onNext();
    }
  };

  return (
    <div className={`step ${themeMode}`}>
      <h2>Account Setup</h2>
      <form>
        {/* User Type (Select Dropdown) */}
        <div className="form-group">
          <label htmlFor="userType">Account</label>
          <select
            id="userType"
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            required
            aria-describedby="userType-error"
          >
            <option value="">Select</option>
            <option value="creator">Creator</option>
            <option value="brand">Brand</option>
          </select>
          {errors.userType && (
            <span className="error-message" id="userType-error">
              {errors.userType}
            </span>
          )}
        </div>
        <div className="form-group">
        <label >Creator Category</label>
        <Select
            className={`signup-form__categories-select signup-form__categories-select--${themeClass}`}
            id="categories"
            options={options}
            isMulti={true}
            onChange={handleCategoryChange}
            value={categories}
            styles={themeMode === "light" ? lightMode : darkMode}
          />

          </div>

        {/* Bio (TextArea) */}
        <div className="form-group">
          <TextInput
            id="bio"
            label="Bio"
            type="textarea"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            placeholder="Enter a brief bio (max 500 characters)"
            maxLength={500}
            themeMode={themeMode}
          />
        </div>

        {/* Categories (Checkboxes) */}


        {/* Navigation Buttons */}
        <div className="navigation-buttons">
          <button type="button" onClick={onBack} className="back-button">
            Back
          </button>
          <button type="button" onClick={handleNext} className="next-button">
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

ProfessionalDetailsStep.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default ProfessionalDetailsStep;
