import { Context } from "../../context/index"
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import PromoteCard from '../../Components/PromoteCard/PromoteCard'
import "./PromotePage.scss"
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import axios from 'axios';
import Navigation from '../../Components/Navigation/Navigation';

export default function PromotePage() {
    const { username } = useParams()
    const { state: { user } } = useContext(Context);
    const [promotions, setPromotions] = useState([]);

    const handleCheckout = async (promotionId) => {
        const { data } = await axios.post(`${process.env.REACT_APP_API}/creator/checkout/${username}/`, {
            promotionId: promotionId
        });
        const stripe = await loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY);
        stripe.redirectToCheckout({ sessionId: data });
    }



    useEffect(() => {
        const fetchPromotions = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_API}/user/${username}/promotions`);
                setPromotions(data.data.promotions)
            } catch (error) {
                console.log(error);
            }
        }
        fetchPromotions();
    }, [username]);

    if( user.username === username ){
        return (
            <UserRoute>
                <div className='promote-pg'>
                    <Navigation heading={"Promote"} add={false} username={user.username} back={true} />
                    <div className="no-promotions">
                        <p className='no-promotions__caption'>You cannot promote your own account</p>
                    </div>  
                </div>
            </UserRoute>
        )
    }


    return (
        <UserRoute username={user.username}>
            <div className='promote-pg'>
                <Navigation heading={"Promote"} add={false} username={user.username} back={true} />
                {promotions.length > 0 ?
                    promotions.map(promotion => (
                        <PromoteCard
                            name={promotion.name}
                            description={promotion.description}
                            price={promotion.price}
                            images={promotion.portfolio}
                            handleCheckout={() => handleCheckout(promotion._id)}
                            tikTok={promotion && promotion.tiktok}
                            instagram={promotion && promotion.instagram}
                            podcast={promotion && promotion.podcast}
                            youtube={promotion && promotion.youtube}
                            key={promotion._id}
                        />
                    )) :
                    <div className="no-promotions">
                        <h3 className='no-promotions__title'>No Promotions</h3>
                        <p className='no-promotions__caption'>No promotions available at this time</p>
                    </div>
                }
            </div>
        </UserRoute>
    )
}
