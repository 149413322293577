import React, { useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './StripePaymentSuccessPage.scss';
import UserRoute from '../../Routes/Auth';
import axios from 'axios';
import ThemeContext from '../../context/ThemeContext';
import { Context } from '../../context';


function StripePaymentSuccessPage() {
    const { id, promotionId } = useParams();
    const navigate = useNavigate();
  const { themeMode } = useContext(ThemeContext);
  const { state: { user } } = useContext(Context);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';




    useEffect(() => {
        const successRequest = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_API}/creator/campaigns/${id}/${promotionId}/`);
                console.log(data);
                if (data.success) {
                    navigate(`/campaigns/${data.campaign.uuid}`);
                }
            } catch (err) {
                console.error(err);
            }
        };

        successRequest();
    }, [id, navigate, promotionId]);

    if (!user) {
        return (
            <div className='stripe-payment-success-pg'>
                <h1 className='stripe-payment-success-pg__title'>Request Sent</h1>
                <p className='stripe-payment-success-pg__text'>Get started now and edit the details of your campaign!</p>
                <p className='stripe-payment-success-pg__link'>
                    <Link to="/campaigns/outgoing/" className={`stripe-payment-success-pg__link--text stripe-payment-success-pg__link--text--${themeClass}`}>Campaigns</Link>
                </p>
            </div>
        )
    }

    return (
        <UserRoute>
            <div className='stripe-payment-success-pg'>
                <h1 className='stripe-payment-success-pg__title'>Request Sent</h1>
                <p className='stripe-payment-success-pg__text'>Get started now and edit the details of your campaign!</p>
                <p className='stripe-payment-success-pg__link'>
                    <Link to="/campaigns/outgoing/" className={`stripe-payment-success-pg__link--text stripe-payment-success-pg__link--text--${themeClass}`}>Campaigns</Link>
                </p>
            </div>
        </UserRoute>
    );
}

export default StripePaymentSuccessPage;
