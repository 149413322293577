import React, { useState } from 'react'
import UserRoute from '../../Routes/Auth'
import Card from '../../Components/Card/Card'
import "./AddPromotionPage.scss"
import axios from 'axios'
import Button from '../../Components/Button/Button'
import InputField from '../../Components/InputField/InputField'
import SocialFieldCard from '../../Components/SocialField/SocialField'
import { useNavigate } from "react-router-dom";
import Navigation from '../../Components/Navigation/Navigation'
import CustomSelect from '../../Components/CustomSelect/CustomSelect'


export default function AddPromotionPage() {

    const [promotionName, setPromotionName] = useState("");
    const [promotionDescription, setPromotionDescription] = useState("");
    const [price, setPrice] = useState("");
    const [instagramPosts, setInstagramPosts] = useState(0);
    const [tikTokPosts, setTikTokPosts] = useState(0);
    const [youtubePosts, setYoutubePosts] = useState(0);
    const [podcastPosts, setPodcastPosts] = useState(0);
    const [currency, setCurrency] = useState("USD");
    const navigate = useNavigate();


    const addPromotion = async (event) => {
        event.preventDefault();
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_API}/user/edit-profile/promotion`, {
                promotionName,
                promotionDescription,
                price,
                instagramPosts,
                tikTokPosts,
                youtubePosts,
                podcastPosts,
                currency,
            })
            console.log(data)
            navigate(`/settings/promotions/`);
        } catch (error) {
            console.log(error)
        }
    }

    return (    
        <UserRoute>
            <div className='add-promo-pg'>
                <Navigation heading={"Add Promotion"} back={true} />
                <Card className="card--input" >
                    <InputField label={"Title"} name={"promotionName"} type={"text"} value={promotionName} onChange={e => setPromotionName(e.target.value)} />
                    <InputField label={"Description"} name={"promotionDescription"} type={"text"} value={promotionDescription} onChange={e => setPromotionDescription(e.target.value)} />
                    <div className='add-promo-pg__row add-promo-pg__row--modifier'>
                        <label className="add-promo-pg__label">Currency</label>
                        <CustomSelect value={currency} options={["USD", "CAD", "EUR", "GBP", ]} 
                            disabledOptions={["JPY", "AUD", "NZD", "INR", "SGD", "AED", "CHF", "ZAR"]} 
                            onOptionSelect={e => setCurrency(e)} 
                        />
                    </div>
                    <InputField label={"Price"} name={"price"} type={"number"} value={price}
                        onChange={e => setPrice(e.target.value)} className="add-promo-pg__price add-promo-pg__price--modifier" />
                    <SocialFieldCard name={"Instagram"} setPosts={setInstagramPosts} posts={instagramPosts} />
                    <SocialFieldCard name={"TiktTok"} setPosts={setTikTokPosts} posts={tikTokPosts} />
                    <SocialFieldCard name={"Youtube"} setPosts={setYoutubePosts} posts={youtubePosts} />
                    <SocialFieldCard name={"Podcasts"} setPosts={setPodcastPosts} posts={podcastPosts} />
                    <Button modifier={"button"} onClick={addPromotion}>Add Promotion</Button>
                </Card>
            </div>
        </UserRoute>
    )
}
