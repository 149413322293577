import React, { useContext } from 'react';
import './DropdownAttachments.scss';
import ThemeContext from '../../context/ThemeContext';
import PhotoIcon from './Photo';
import Resizer from 'react-image-file-resizer';
// import VideoIcon from './VIdeoIcon';    
// import MapPinIcon from './MapPinIcon';


const DropdownAttachments = ({ label, setImage, setImagePreview, setVideo }) => {
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const validateImg = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.size > 2048576) {
                console.error("Max file size 1mb");
            } else {
                resizeFile(file)
                    .then(resizedImage => {
                        setImage(resizedImage);
                        setImagePreview(URL.createObjectURL(resizedImage));
                    })
                    .catch(err => {
                        console.error("Error resizing image", err);
                    });
            }
        }
    };

    // const validateVideo = (e) => {
    //     if (e.target.files.length > 0) {
    //         const file = e.target.files[0];
    //         if (file.size > 10485760) { // 10MB max size for videos
    //             console.error("Max file size 10mb");
    //         } else {
    //             setVideo(file);
    //         }
    //     }
    // };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500, // max width
                600, // max height
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    return (
        <div className={`attachment-dropdown attachment-dropdown--${themeClass}`}>
            <button className={`attachment-dropbtn attachment-dropbtn--${themeClass}`}>
                <p className={`attachment-dropdown-label attachment-dropdown-label--${themeClass}`}>{label}</p>
            </button>
            <div className={`attachment-dropdown-content attachment-dropdown-content--${themeClass}`}>
                <div className={`attachment-dropdown-item attachment-dropdown-item--${themeClass}`}>
                    <label htmlFor="image-file" style={{ display: 'flex', gap: '0.5rem', flexDirection: 'row', alignItems: 'center' }}>
                        <PhotoIcon themeMode={themeMode} />
                        <p>Upload Image</p>
                    </label>
                    <input type="file" id="image-file" hidden accept='image/png, image/jpeg, image/webp' onChange={validateImg} />
                </div>
                {/* <div className={`attachment-dropdown-item attachment-dropdown-item--${themeClass}`}>
                    <label htmlFor="video-file" style={{ display: 'flex', gap: '0.5rem', flexDirection: 'row', alignItems: 'center' }}>
                        <VideoIcon themeMode={themeMode} />
                        <p>Upload Video</p>
                    </label>
                    <input type="file" id="video-file" hidden accept='video/mp4, video/webm, video/ogg' onChange={validateVideo} />
                </div> */}
                {/* <div className={`attachment-dropdown-item attachment-dropdown-item--${themeClass}`}>
                    <MapPinIcon themeMode={themeMode} />
                    <p>Send Location</p>
                </div> */}
            </div>
        </div>
    );
};

export default DropdownAttachments;
