

export default  function PasskeysIcon(themeMode) {

    const themeClass = themeMode === 'light' ?  'black' : 'white';  
    

      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="svg-icon"
          style={{ width: "37px", height: "37px", verticalAlign: "middle" }}
          fill={themeClass}
          overflow="hidden"
          viewBox="0 0 1024 1024"
        >
          <path
            fill="#6D7793"
            d="M688.873 93.09a23.273 23.273 0 010-46.545h123.81a164.77 164.77 0 01164.772 164.771V317.44a23.273 23.273 0 01-46.546 0V211.316A118.225 118.225 0 00812.684 93.091H688.873zm242.036 595.783a23.273 23.273 0 0146.546 0v123.81a164.77 164.77 0 01-164.771 164.772H688.873a23.273 23.273 0 010-46.546h123.81A118.225 118.225 0 00930.91 812.684V688.873zM317.44 930.909a23.273 23.273 0 010 46.546H211.316a164.77 164.77 0 01-164.77-164.771V688.873a23.273 23.273 0 0146.545 0v123.81A118.225 118.225 0 00211.316 930.91H317.44zM93.09 317.44a23.273 23.273 0 01-46.545 0V211.316a164.77 164.77 0 01164.771-164.77H317.44a23.273 23.273 0 010 46.545H211.316A118.225 118.225 0 0093.091 211.316V317.44zm395.637 31.65a23.273 23.273 0 0146.546 0V512a69.818 69.818 0 01-69.818 69.818h-23.273a23.273 23.273 0 010-46.545h23.273A23.273 23.273 0 00488.727 512V349.09zm-186.182 0a23.273 23.273 0 0146.546 0v93.092a23.273 23.273 0 01-46.546 0V349.09zm372.364 0a23.273 23.273 0 0146.546 0v93.092a23.273 23.273 0 01-46.546 0V349.09zM325.75 699.95a23.273 23.273 0 1131.162-34.583c43.054 38.773 94.37 58.02 155.089 58.02 60.742 0 112.058-19.247 155.09-58.02a23.273 23.273 0 0131.162 34.584c-51.69 46.568-114.153 69.98-186.252 69.98s-134.563-23.412-186.252-69.98z"
          ></path>
        </svg>
      );
    }
    