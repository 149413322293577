// components/NotificationsPage/NotificationsPage.jsx

import "./NotificationsPage.scss";
import { Context } from "../../context/index";
import React, { useContext, useEffect, useState, useCallback } from 'react';
import UserRoute from '../../Routes/Auth';
import axios from 'axios';
import moment from 'moment';
import NotificationCard from "../../Components/NotificationCard/NotificationCard";
import Loading from "../../Components/Loading/Loading";
import Navigation from "../../Components/Navigation/Navigation";


const PUBLIC_VAPID_KEY = process.env.REACT_APP_VAPIID_KEY;

export function urlBase64ToUint8Array(base64String) {
  
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}



export default function NotificationsPage({ notifications, setNotifications }) {
  const [username, setUsername] = useState("");
  const { state: { user } } = useContext(Context);



  useEffect(() => {
    if ('serviceWorker' in navigator && PUBLIC_VAPID_KEY && user !== null) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service Worker registered');

          // Check the current notification permission status
          if (Notification.permission === 'granted') {
            return Promise.resolve('granted');
          } else if (Notification.permission === 'default') {
            // Request permission only if it's not already granted or denied
            return Notification.requestPermission();
          } else {
            // Permission was denied previously
            return Promise.reject(new Error('Notification permission denied'));
          }
        })
        .then(permission => {
          if (permission === 'granted') {
            return navigator.serviceWorker.ready;
          }
        })
        .then(async registration => {
          if (registration) {
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY),
            });
            console.log('Subscription:', subscription);

            // Send the subscription to the backend as JSON
            await axios.post('/webpush/subscribe', subscription, {
              headers: {
                'Content-Type': 'application/json',
              },
            });

            console.log('Push subscription sent to server:', subscription);
          }
        })
        .catch(err => {
          if (err.message !== 'Notification permission denied') {
            console.error('Error registering service worker or push subscription', err);
          } else {
            console.warn('Notifications are blocked by the user.');
          }
        });
    }
  }, [user]);

  // Fetch notifications function
  const fetchNotifications = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/notifications`);
      setNotifications(response.data.notifications);
      readNotifications(response.data.notifications);
    } catch (error) {
      console.log(error);
    }
  }, [setNotifications]);

  // Polling to fetch new notifications every 30 seconds
  useEffect(() => {
    if (user !== null) {
      setUsername(user.username);
      fetchNotifications();

      const intervalId = setInterval(fetchNotifications, 30000); // Poll every 30 seconds
      return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }
  }, [user, fetchNotifications]);

  // Function to mark notifications as read
  const readNotifications = async (notifications) => {
    try {
      const unreadNotifications = notifications.filter(notification => !notification.read);
      if (unreadNotifications.length > 0) {
        await axios.put(`${process.env.REACT_APP_API}/notifications/read`, {
          notifications: unreadNotifications.map(notification => notification.id)
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handling notification actions (Accept, Decline, Delete)
  const handleAccept = async (notificationId, userFrom) => {
    try {
      await axios.put(`${process.env.REACT_APP_API}/connect/accept`, {
        notificationId,
        userFromId: userFrom
      });
      fetchNotifications(); // Fetch notifications again after action
    } catch (error) {
      console.log(error);
    }
  };

  const handleDecline = async (notificationId, userId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API}/connect/decline/`, {
        data: { notificationId, otherUserId: userId }
      });
      fetchNotifications(); // Fetch notifications again after action
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteNotification = async (notificationId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API}/notifications/`, {
        data: { notificationId }
      });
      fetchNotifications(); // Fetch notifications again after action
    } catch (error) {
      console.log(error);
    }
  };

  // Categorize notifications based on the time they were received
  const categorizeNotifications = (notifications) => {
    const today = [];
    const yesterday = [];
    const pastWeek = [];
    const pastMonth = [];

    notifications.forEach((notification) => {
      const notificationDate = moment(notification.createdAt);
      const now = moment();

      if (notificationDate.isSame(now, 'day')) {
        today.push(notification);
      } else if (notificationDate.isSame(now.subtract(1, 'day'), 'day')) {
        yesterday.push(notification);
      } else if (notificationDate.isAfter(now.subtract(1, 'week'))) {
        pastWeek.push(notification);
      } else if (notificationDate.isAfter(now.subtract(1, 'month'))) {
        pastMonth.push(notification);
      }
    });

    return { today, yesterday, pastWeek, pastMonth };
  };

  // Rendering sections of notifications based on the time categorization
  const renderNotificationSection = (title, notifications) => (
    notifications.length > 0 && (
      <div className="notification-section">
        <h3 style={{ opacity: '0.19' }}>{title}</h3>
        {notifications.map((notification, index) => (
          <NotificationCard
            key={index}
            notification={notification}
            handleAccept={() => handleAccept(notification.id, notification.userFrom)}
            handleDecline={() => handleDecline(notification.id, notification.userFrom)}
            handleDeleteNotification={() => handleDeleteNotification(notification.id)}
          />
        ))}
      </div>
    )
  );

  return (
    <UserRoute username={username}>
      <div className="notifications">
        <Navigation title={"Notifications"} pushNotification={true} navRight={true} />
        <div className='notifications__container'>
          {notifications === null ? (
            <Loading />
          ) : (
            <>
              {(() => {
                const { today, yesterday, pastWeek, pastMonth } = categorizeNotifications(notifications);
                return (
                  <>
                    {renderNotificationSection('Today', today)}
                    {renderNotificationSection('Yesterday', yesterday)}
                    {renderNotificationSection('Past Week', pastWeek)}
                    {renderNotificationSection('Past Month', pastMonth)}
                  </>
                );
              })()}
            </>
          )}
        </div>
      </div>
    </UserRoute>
  );
}
