import React, { useContext, useEffect, useState, useCallback } from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';
import UserRoute from '../../Routes/Auth';
import { Context } from "../../context/index";
import UserCard from '../../Components/UserCard/UserCard';
import Navigation from '../../Components/Navigation/Navigation';
import Dropdown from '../../Components/Dropdown/Dropdown';
import './CategoryPage.scss';

export default function CategoryPage() {
    const { category } = useParams();

    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const [selectedFollowing, setSelectedFollowing] = useState(null);
    const [selectedPricing, setSelectedPricing] = useState(null);
    const [selectedRating, setSelectedRating] = useState(null);

    const capitalizedCategory = category.replace(/_/g, ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, function (char) {
            return char.toUpperCase();
        });

    const [users, setUsers] = useState([]);
    const [username, setUsername] = useState("");
    const { state: { user }, } = useContext(Context);

    useEffect(() => {
        if (user !== null) {
            setUsername(user.username);
        }
    }, [user]);

    const fetchUsers = useCallback(async (url) => {
        try {
            // console.log(selectedPlatforms, selectedFollowing, selectedPricing, selectedRating)
            const res = await axios.post(url,
                { selectedPlatforms, selectedFollowing, selectedPricing, selectedRating }
            );
            setUsers(res.data);
        } catch (err) {
            console.error(err);
        }
    }, [selectedPlatforms, selectedFollowing, selectedPricing, selectedRating]);

    useEffect(() => {
        fetchUsers(`${process.env.REACT_APP_API}/user/all/${category}`);
    }, [category, selectedFollowing, selectedPlatforms, selectedRating, selectedPricing, fetchUsers]);

    const platforms = [
        { path: 'instagram', label: 'Instagram' },
        { path: 'tiktok', label: 'TikTok' },
        { path: 'spotifyPodcast', label: 'Podcasts' },
        { path: 'youtube', label: 'Youtube' },
        { path: 'spotifyMusic', label: 'Music' },
    ];

    const followingFilter = [
        { path: '1korless', label: '1,000 or less' },
        { path: '1k-10k', label: '1K-10K' },
        { path: '10k-50k', label: '10K-50K' },
        { path: '50k-100k', label: '50K-100K' },
        { path: '100k-300k', label: '100K-300K' },
    ];

    const pricingFilter = [
        { path: 'under100', label: '$100 or less' },
        { path: 'under1000', label: '$100-$1,000' },
        { path: 'under10k', label: '$1,000-$10K' },
        { path: 'under50k', label: '$10K-$50K' },
    ];

    const ratingFilter = [
        { path: '5stars', label: '⭐️⭐️⭐️⭐️⭐️' },
        { path: '4stars', label: '⭐️⭐️⭐️⭐️' },
        { path: '3stars', label: '⭐️⭐️⭐️' },
    ];

    return (
        <UserRoute username={username}>
            <div className='category-pg'>
                <Navigation heading={capitalizedCategory} back={true} />
                <div className='sub-filters'>
                    <div className="sub-filters__socials">
                        <Dropdown label={"Socials"} links={platforms} selected={selectedPlatforms} setSelected={setSelectedPlatforms} singleSelection />
                    </div>
                    <div className="sub-filters__socials---tablet">
                        <Dropdown label={"Social Platforms"} links={platforms} selected={selectedPlatforms} setSelected={setSelectedPlatforms} singleSelection />
                    </div>
                    <Dropdown label={"Following"} links={followingFilter} selected={selectedFollowing} setSelected={setSelectedFollowing} singleSelection />
               
                    <Dropdown label={"$$$"} links={pricingFilter} selected={selectedPricing} setSelected={setSelectedPricing} singleSelection />
                    <Dropdown label={"Rating"} links={ratingFilter} selected={selectedRating} setSelected={setSelectedRating} singleSelection />
                </div>
                <div className="category-pg__filter-container">
                    {/* Additional filter components can be added here */}
                </div>
                {/* <div>Combined URL: {combinedUrl}</div> */}
                {users.map(user => (
                    <UserCard key={user && user._id} username={user && user.username} location={user && user.location} category={user && user.categories[0]} picture={user && user.picture} />
                ))}
            </div>
        </UserRoute>
    );
}
