import React, { useEffect, useState } from 'react'
import UserRoute from '../../Routes/Auth'
import "./ConnectionsPage.scss"
import { useParams } from 'react-router-dom'
import axios from "axios"
import Card from '../../Components/Card/Card'
import UserLinkCard from '../../Components/UserLinkCard/UserLinkCard'
import Navigation from '../../Components/Navigation/Navigation'



export default function ConnectionsPage() {

    const { username } = useParams();
    const [connections, setConnections] = useState([]);


    useEffect(() => {

        const fetchConnections = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_API}/user/${username}/connections/`)
                console.log(data)
                setConnections(data.data.connections)
            } catch (error) {
                console.log(error)
            }
        }
        fetchConnections()
    }, [username])



    return (
        <UserRoute>
            <div className='connections-pg'>
                <Navigation heading={"Connections"} back={true} />
                <Card>
                    {connections.map(connection => (
                        <UserLinkCard modifier={"user-link--collaborators"} key={connection._id} title={connection.username} src={`/${connection.username}`} icon={connection.picture}
                            category={connection.categories[0]}
                        />
                    ))}
                </Card>
            </div>
        </UserRoute>
    )
}
