import React, { useContext } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./LineChartComponent.scss"; // Create this file for specific styling if needed
import ThemeContext from "../../context/ThemeContext";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const LineChartComponent = ({ data, dataKey, title }) => {
  const { themeMode } = useContext(ThemeContext);

  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  return (
    <div className={`chart-container chart-container--${themeClass}`}>
      {/* <h3 className="chart-title">{title}</h3> */}
      <ResponsiveContainer width={"100%"} height={350}>
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="date" tick={{ fontSize: 12, fontWeight: "bold", fill: "#666" }} />
          <YAxis tick={{ fontSize: 12, fontWeight: "bold", fill: "#666" }} />
          <Tooltip content={<CustomTooltip />} />
          <Line 
            type="monotone" 
            dataKey={dataKey} 
            stroke="#2962FF" 
            strokeWidth={3} 
            dot={false} 
            strokeOpacity={0.6} 
            activeDot={{ 
            }} 
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartComponent;
