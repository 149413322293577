import React from 'react';
import InstagramIcon from "../../assets/icons/instagram.png";
import TikTokIcon from "../../assets/icons/tiktok.png";
import YoutubeIcon from "../../assets/icons/youtube.svg";
import SpotifyIcon from "../../assets/icons/spotify.png";
import "./PlatformPlaceholderCard.scss";

// Placeholder Card Component
const PlatformPlaceholderCard = ({ platform, setAnalyticsModal, post = true, artist = false, title, description, appStatus, themeClass }) => {
  const platformDetails = {
    instagram: { name: 'Instagram', icon: InstagramIcon },
    youtube: { name: 'YouTube', icon: YoutubeIcon },
    spotify: { name: 'Spotify Show', icon: SpotifyIcon },
    spotifyM: { name: 'Spotify Artist', icon: SpotifyIcon },
    tiktok: { name: 'TikTok', icon: TikTokIcon },
  };

  const details = platformDetails[platform] || { name: 'Platform', icon: '' };

  const handleClick = () => {
    if (setAnalyticsModal) {
      setAnalyticsModal(true);
    }
  };

  return (
    <div className={`placeholder-card placeholder-card--${themeClass}`} onClick={handleClick}>
      <div className="placeholder-card__content">
        {details.icon && (
          <img 
            src={details.icon} 
            alt={`${details.name} icon`} 
            className="placeholder-card__icon" 
          />
        )}
        {post && (
          <>
            <h3 className="placeholder-card__title">{title}</h3>
            {description ? <p className="placeholder-card__description">{description}</p> : <p className="placeholder-card__description">
              A {details.name} Post ID is required to continue.
            </p>}
          </>
        )}
        {/* {artist && (
          <>
            <h3 className="placeholder-card__title">Add {details.name} ID</h3>
            <p className="placeholder-card__description">
              A {details.name} ID is required to continue.
            </p>
          </>
        )} */}
      </div>
    </div>
  );
};

export default PlatformPlaceholderCard;
