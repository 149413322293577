import React, { useEffect, useState, useContext, useCallback } from "react";
import UserRoute from "../../Routes/Auth";
import Card from "../../Components/Card/Card";
import Navigation from "../../Components/Navigation/Navigation";
import "./CampaignReviewPage.scss";
import { useParams } from "react-router-dom";
import CampaignHeader from "../../Components/CampaignHeader/CampaignHeader";
import ThemeContext from "../../context/ThemeContext";
import axios from "axios";
import StarIcon from "./StarIcon";
import { Context } from "../../context/index";
import Pill from "../../Components/Pill/Pill";

export default function CampaignReviewPage() {
    const [campaign, setCampaign] = useState(null);
    const [reviews, setReviews] = useState([]);
    const { id } = useParams();
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";
    const [campaignStatus, setCampaignStatus] = useState(null);
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');
    const [selected, setSelected] = useState([]);
    const { state: { user } } = useContext(Context);

    const labelsByRating = {
        1: ['Service not delivered', 'Poor communication', 'Rude', 'Unprofessional', 'Unreliable', 'Other'],
        2: ['Delayed responses', 'Inconsistent quality', 'Needs improvement', 'Unclear instructions', 'Other'],
        3: ['Satisfactory', 'Met expectations', 'Average performance', 'Could be better', 'Other'],
        4: ['Good communication', 'Professional', 'Timely delivery', 'Well executed', 'Other'],
        5: ['Excellent work', 'Highly engaging', 'Exceeded expectations', 'Creative and innovative', 'Other']
    };

    const labelsByRatingForCreator = {
        1: ['Unclear goals', 'Lack of support', 'Unprofessional', 'Unreliable', 'Other'],
        2: ['Delayed responses', 'Inconsistent feedback', 'Needs improvement', 'Unclear instructions', 'Other'],
        3: ['Satisfactory', 'Met expectations', 'Average collaboration', 'Could be better', 'Other'],
        4: ['Good communication', 'Professional', 'Supportive', 'Well organized', 'Other'],
        5: ['Excellent support', 'Highly collaborative', 'Exceeded expectations', 'Creative and innovative', 'Other']
    };

    const fetchCampaignData = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}`);
            setCampaign(response.data);
            setCampaignStatus(response.data.status);
        } catch (error) {
            console.error("Error fetching campaign data:", error);
        }
    }, [id]);

    const fetchReviews = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}/reviews`);
            setReviews(response.data.reviews);
        } catch (error) {
            console.error("Error fetching reviews:", error);
        }
    }, [id]);

    useEffect(() => {
        fetchCampaignData();
        fetchReviews();
    }, [fetchCampaignData, fetchReviews]);

    const handleStarClick = (index) => {
        setRating(index + 1);
        setSelected([]); // Reset selected labels when rating changes
    };

    const handleSubmitReview = async () => {
        try {
            // Submit the review and rating to the server
            await axios.post(`${process.env.REACT_APP_API}/campaigns/${id}/reviews`, { rating, review, selected }, {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            });
            alert("Review submitted successfully!");
            // Optionally reset the form or update the state
            setRating(0);
            setReview('');
            setSelected([]);
            // Fetch the reviews again to include the new review
            fetchReviews();
        } catch (error) {
            console.error("Error submitting review:", error);
        }
    };

    const handlePillClick = (label) => {
        setSelected((prevSelected) =>
            prevSelected.includes(label)
                ? prevSelected.filter((item) => item !== label)
                : [...prevSelected, label]
        );
    };

    // Determine which labels to use based on the user's role in the campaign
    const labelsToUse = user._id === campaign?.creator?._id ? labelsByRatingForCreator : labelsByRating;

    // Check if the current user has already submitted a review
    const userHasReviewed = reviews.some((review) => review.reviewer._id === user._id);

    return (
        <UserRoute>
            <div className={`campaign-review-pg ${themeClass}`}>
                <Navigation
                    heading={campaign?.campaignName}
                    back={true}
                    edit={`/campaigns/edit/${id}`}
                />
                <Card className="card--margin">
                    <div className="campaign-review-pg__header">
                        <div className="campaign-review-pg__header__left">
                            <CampaignHeader status={campaignStatus} />
                        </div>
                    </div>
                    <div className="campaign-review-pg__container">
                    <div className="reviews-section">
                            <h3>Reviews</h3>
                            {reviews.length > 0 ? (
                                reviews.map((review) => (
                                    <div key={review._id} className="review">
                                        <div className="review-header">
                                            <div className="review-header__profile">
                                                <img src={review.reviewer.picture} alt={`${review.reviewer.username}'s profile`} className="reviewer-profile-picture" />
                                                <h4>{review.reviewer.username}</h4>
                                            </div>
                                            <div className="review-rating">
                                                {[...Array(5)].map((_, index) => (
                                                    <StarIcon
                                                        key={index}
                                                        filled={index < review.rating}
                                                        onClick={() => {}}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        {review.selected.map((label, index) => (
                                            <Pill key={index} label={label} isSelected={true} />
                                        ))}
                                        <p>{review.review}</p>

                                    </div>
                                ))
                            ) : (
                                <p style={{ textAlign: 'center', fontSize: '0.85rem', opacity: 0.5 }}>No reviews yet.</p>
                            )}
                        </div>
                        {!userHasReviewed && (
                            <div>
                                <h2 className="campaign-review-pg__title">How was your campaign with {campaign?.brand?.username || campaign?.creator?.username}?</h2>
                                <div className="rating">
                                    {[...Array(5)].map((_, index) => (
                                        <StarIcon
                                            key={index}
                                            filled={index < rating}
                                            onClick={() => handleStarClick(index)}
                                        />
                                    ))}
                                </div>
                                {rating > 0 && labelsToUse[rating].map((label) => (
                                    <Pill
                                        key={label}
                                        label={label}
                                        onClick={() => handlePillClick(label)}
                                        isSelected={selected.includes(label)}
                                    />
                                ))}
                                <textarea
                                    className={`campaign-review-pg__textarea campaign-review-pg__textarea--${themeClass}`}
                                    value={review}
                                    onChange={(e) => setReview(e.target.value)}
                                    placeholder="Additional feedback"
                                    rows={4}
                                />
                                <button className={`campaign-review-pg__button campaign-review-pg__button--${themeClass}`} onClick={handleSubmitReview}>Submit</button>
                            </div>
                        )}
                        
                    </div>
                </Card>
            </div>
        </UserRoute>
    );
}
