// WebAuthnRegister.jsx

import React from 'react';
import axios from 'axios';
import { startRegistration } from '@simplewebauthn/browser';
import './WebAuthnRegister.scss';
import PasskeysIcon from '../../assets/svg/passkeys';

const WebAuthnRegister = ({ user, themeMode }) => {
  const themeClass = themeMode === 'light' ? 'light-theme' : '';

  const handlePasskeyRegister = async () => {
    try {
      console.log('Starting passkey registration', user);

      // Step 1: Request registration options from the server
      const optionsResponse = await axios.post(
        `${process.env.REACT_APP_API}/webauthn/generate-registration-options`,
        { email: user.email },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const options = optionsResponse.data;
      console.log('Received registration options:', options);

      // Step 2: Start the registration process with the options
      const attestation = await startRegistration(options);
      console.log('Received attestation:', attestation);

      // Step 3: Send the attestation response to the server for verification
      const verificationResponse = await axios.post(
        `${process.env.REACT_APP_API}/webauthn/verify-registration`,
        { email: user.email, attestation },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log('Verification response:', verificationResponse.data);

      if (verificationResponse.data.verified) {
        alert('Passkey registration successful!');
      } else {
        alert('Passkey registration failed!');
      }
    } catch (error) {
      console.error('Error during passkey registration:', error);
      const errorMessage = error.response?.data?.message || error.message;
      alert(`Error during passkey registration: ${errorMessage}`);
    }
  };

  return (
    <div>
      <button
        className={`passkey-register passkey-register--${themeClass}`}
        onClick={handlePasskeyRegister}
      >
        <div className="passkey-icon">
          <PasskeysIcon themeMode={themeMode} />
        </div>
        <p>Set up Passkeys</p>
      </button>
    </div>
  );
};

export default WebAuthnRegister;
