import React, { useState } from "react";
import PropTypes from "prop-types";
import "./step.scss"; // Ensure this path is correct based on your project structure
import ClipBoardIcon from "../../../Icons/ClipBoardIcon";
import Toggle from "../../Toggle/Toggle";

function PreferencesStep({
  data,
  onChange,
  onSubmit,
  onBack,
  themeMode,
  username,
}) {
  const [isPrivate, setIsPrivate] = useState(data.isPrivate || false);
  const profileLink = `https://chequeai.com/${username}`; // Assuming this is the format for user profiles

  // Handle form submission
  const handleSubmit = () => {
    onChange({ isPrivate });
    onSubmit();
  };

  // Function to copy the profile link to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(profileLink).then(() => {});
  };

  return (
    <div className={`step ${themeMode}`}>
      <h2>Nearly Done!</h2>
      <form>
        {/* Display Username with Profile Link */}
        <div className="form-group profile-link">
          <label>Profile Link</label>
          <p>
            <a href={profileLink} target="_blank" rel="noopener noreferrer">
              {profileLink}
            </a>
          </p>
        </div>

        {/* Prompt to Add to Instagram or TikTok Bio */}
        <div className="form-group copy-prompt">
          <p className="copy-text">
            Add this link to your Instagram & TikTok bio, so{" "}
            <strong>brands</strong> and <strong>others</strong> can{" "}
            <span className="highlight">
              connect with you for business inquieries and deals
            </span>
            .
          </p>

          <button
            type="button"
            className="copy-button"
            onClick={copyToClipboard}
          >
            <ClipBoardIcon themeMode={themeMode} /> Copy Profile Link
          </button>
        </div>

        {/* Private Account (Checkbox) */}
        <div className="form-group checkbox-group">
          <label className="checkbox-label">
            <span>Private Account</span> <Toggle setIsPrivate={setIsPrivate} modifier={"mb"} />
          </label>
        </div>

        {/* Navigation Buttons */}
        <div className="navigation-buttons">
          <button type="button" onClick={onBack} className="back-button">
            Back
          </button>
          <button type="button" onClick={handleSubmit} className="next-button">
            Finish
          </button>
        </div>
      </form>
    </div>
  );
}

PreferencesStep.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default PreferencesStep;
