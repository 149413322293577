import { Context } from "../../context/index";
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import Card from '../../Components/Card/Card';
import './PrivacyPolicy.scss';
import Navigation from "../../Components/Navigation/Navigation";

const PrivacyPolicy = () => {
  const [username, setUsername] = useState("");
  const { state: { user } } = useContext(Context);

  useEffect(() => {
    if (user !== null) {
      setUsername(user.username);
    }
  }, [user]);

  const privacyPolicyContent = (
    <div className='privacy-pg'>
      <Navigation heading={"Privacy Policy"} back={true} />
      <Card className="card--padding">

        <h1>Privacy Policy</h1>
        <p>Last updated: June 18, 2024</p>

        <h2>1. Introduction</h2>
        <p>Welcome to Chequeai Marketplace for Creators and Brands. This Privacy Policy ("Policy") describes how we collect, use, and share information about you when you use our Marketplace. By using our Marketplace, you agree to this Policy, so please read it carefully.</p>

        <h2>2. Information We Collect</h2>
        <p>We collect information about you when you use our Marketplace, including:</p>
        <ul>
          <li><strong>Information you provide:</strong> When you create an account, such as your name, email address, and payment information.</li>
          <li><strong>Information from your use of the Marketplace:</strong> Such as your location, interests, and preferences.</li>
          <li><strong>Automatically collected information:</strong> Including your IP address, device type, and browser type.</li>
          <li><strong>Information from third-party sources:</strong> Such as social media platforms and payment processors.</li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>We use your information to:</p>
        <ul>
          <li>Provide and improve our Marketplace</li>
          <li>Process transactions and payments</li>
          <li>Communicate with you about our Marketplace and related products and services</li>
          <li>Respond to your inquiries and requests</li>
          <li>Detect and prevent fraud and other illegal activities</li>
          <li>Comply with legal obligations</li>
        </ul>

        <h2>4. How We Share Your Information</h2>
        <p>We may share your information with third parties, including:</p>
        <ul>
          <li>Service providers who help us operate our Marketplace and provide related services, such as payment processing and customer support</li>
          <li>Other users of our Marketplace, such as brands who are looking to collaborate with creators</li>
          <li>Law enforcement agencies and other government bodies, as required by law or to protect our legal rights</li>
        </ul>

        <h2>5. Cookies and Tracking Technologies</h2>
        <p>We use cookies and other tracking technologies to collect information about your use of our Marketplace and to improve our services. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of our Marketplace may become inaccessible or not function properly.</p>

        <h2>6. Your Choices</h2>
        <p>You can control the information we collect about you and how it is used by:</p>
        <ul>
          <li>Updating your account information</li>
          <li>Adjusting your privacy settings</li>
          <li>Contacting us to request access, correction, or deletion of your personal information</li>
        </ul>

        <h2>7. Security</h2>
        <p>We take reasonable measures to protect your information from unauthorized access, use, disclosure, or modification. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.</p>

        <h2>8. Children's Privacy</h2>
        <p>Our Marketplace is not intended for children under 13 years of age, and we do not knowingly collect personal information from children under 13. If you believe we have collected personal information from a child under 13, please contact us immediately.</p>

        <h2>9. Changes to this Policy</h2>
        <p>We may modify this Policy at any time, so please review it periodically. If we make material changes to this Policy, we will notify you by email or by posting a notice on our Marketplace.</p>

        <h2>10. Contact Us</h2>
        <p>If you have any questions or concerns about this Policy, please contact us at <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>
      </Card>
    </div>
  );

  if (!user) {
    return (
      <div>
        {privacyPolicyContent}
      </div>
    );
  }
  return (
    <UserRoute username={username}>
      {privacyPolicyContent}
    </UserRoute>
  );
};

export default PrivacyPolicy;
