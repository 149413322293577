import React, { useState, useContext, useEffect } from 'react';
import './UpdatePortfolioPage.scss';
import { Context } from '../../context/index';
import UserRoute from '../../Routes/Auth';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import Navigation from '../../Components/Navigation/Navigation';
import OptionsModalBox from '../../Components/OptionsModalBox/OptionsModalBox';
import addPortfolio from '../../assets/defaults/post.png';
import addPortfolioLightmode from '../../assets/defaults/postLight.png';
import Card from '../../Components/Card/Card';
import ThemeContext from '../../context/ThemeContext';


const UpdatePortfolioPage = () => {
    const [userLogged, setUserLogged] = useState('');
    const { state: { user } } = useContext(Context);
    const [portfolioImages, setPortfolioImages] = useState([]);
    const [image, setImage] = useState('');
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    const portfolioPicture = themeMode === 'light' ? addPortfolioLightmode: addPortfolio;


  



    useEffect(() => {
        if (user !== null) {
            setUserLogged(user.username);
        }
    }, [user]);

    const fetchPortfolio = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API}/portfolio/`);
            // console.log(res);
            setPortfolioImages(res.data.portfolioImages);
        } catch (err) {
            console.error(err);
        }
    };

    const handleDelete = async (index) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_API}/portfolio/${index}`
            );
            console.log(res);
        } catch (err) {
            console.error(err);
        }
    };

    const handleAdd = async () => {
        try {
            Resizer.imageFileResizer(image, 750, 750, 'PNG', 300, 0, async (uri) => {
                try {
                    let { data } = await axios.put(
                        `${process.env.REACT_APP_API}/portfolio/${portfolioImages.length}`,
                        {
                            image: uri,
                        }
                    );
                    console.log('IMAGE UPLOADED', data);
                    // set image in the state
                    setImage(data);
                    window.location.reload();
                    console.log('Updated');
                } catch (err) {
                    console.log('Failed to upload. Try later.', err);
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const validateImg = (e) => {
        const file = e.target.files[0];
        if (file.size > 10048576) {
            console.error('Max file size 1mb', { position: 'top-center' });
        } else {
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    useEffect(() => {
        fetchPortfolio();
    }, []);

    const portfolioOptions = (index) => {
        setIsOptionsOpen(index);
    };

    return (
        <UserRoute username={userLogged}>
            <div className="settings_general-pg">
                <Navigation heading="Update Portfolio" back={true} />
                <Card className="card--padding">
                    <div className="settings_general-pg__container">
                        {portfolioImages.map((image, index) => (
                            <button
                                key={index}
                                className="portfolio-image-container"
                                onClick={() => portfolioOptions(index)}
                            >
                                <img
                                    className="portfolio-image"
                                    src={image.url}
                                    alt={`Portfolio ${index}`}
                                />
                            </button>
                        ))}
                        <div className="portfolio-image-container">
                            <label htmlFor="image-upload">
                                <img
                                    className="portfolio-image"
                                    src={imagePreview ? imagePreview : portfolioPicture}
                                    alt="Add Portfolio"
                                />
                            </label>
                            <input
                                type="file"
                                id="image-upload"
                                hidden
                                accept="image/png, image/jpeg"
                                onChange={validateImg}
                            />
                   
                        </div>
                        {imagePreview && (
                                <button
                                    className={`settings_general-pg__add settings_general-pg__add--${themeClass}`}
                                    onClick={() => {
                                        handleAdd();
                                    }}
                                >
                                    +
                                </button>
                            )}
                    </div>
                </Card>
                {isOptionsOpen !== false && (
                    <OptionsModalBox
                        isOpen={isOptionsOpen}
                        setIsOpen={setIsOptionsOpen}
                        handleRemoveImage={handleDelete}
                    />
                )}
            </div>
        </UserRoute>
    );
};

export default UpdatePortfolioPage;
