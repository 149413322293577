import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPasswordPage.scss';
import { Link } from 'react-router-dom';
import FooterComponent from '../../Components/FooterComponent/FooterComponent';

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/request-password-reset`, { email });
      setMessage(response.data.message);
      setError('');
    } catch (error) {
      setError(error.response.data.message || 'An error occurred. Please try again.');
      setMessage('');
    }
  };

  return (
    <div className='forgot-password-form__container'>
      <Link to={"/"}>
        <h1 className='forgot-password-form__header'>Chequeai</h1>
      </Link>
      <form className='forgot-password-form' onSubmit={handleSubmit}>
        <div className='forgot-password-form__email-container'>
          <input
            className='forgot-password-form__email-input'
            name='email'
            placeholder='Email'
            type='email'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          />
        </div>
        <div className='forgot-password-form__btn-container'>
          <button className='forgot-password-form__btn-submit' type='submit'>Reset Password</button>
        </div>
        {message && <p className='forgot-password-form__message'>{message}</p>}
        {error && <p className='forgot-password-form__error'>{error}</p>}
      </form>
      <FooterComponent />
    </div>
  );
}
