import React, { useEffect, useState, useCallback } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from '../../Components/Navigation/Navigation';
import axios from 'axios';
import CampaignRequest from '../../Components/CampaignRequest/CampaignRequest';
import './CampaignsOutBoxPage.scss';

export default function CampaignsOutBoxPage() {
    const [outboxCampaigns, setOutboxCampaigns] = useState([]);

    const fetchCampaigns = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/outgoing`);
            // console.log(response.data);
            const fetchedCampaigns = response.data?.campaigns || [];
            setOutboxCampaigns(fetchedCampaigns);
        } catch (error) {
            console.error('Error fetching campaigns:', error);
        }
    }, []);

    useEffect(() => {
        fetchCampaigns();
    }, [fetchCampaigns]);

    return (
        <UserRoute>
            <div className='cp-outbox-pg'>
                <Navigation heading='Outgoing Campaign - Requests' back={true} />
                <div className='campaigns-outbox-pg__campaigns'>
                    {outboxCampaigns.map((campaign) => (
                        <CampaignRequest
                            key={campaign._id}
                            campaign={campaign}
                            actions={false}
                            edit={true}
                        />
                    ))}
                </div>
            </div>
        </UserRoute>
    );
}
