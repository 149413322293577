import React from 'react';
import Card from '../Card/Card';
import './UserPortfolio.scss';
import ChequaiLogo from "../../assets/icons/chequai-logo.png";

export default function UserPortfolio({ imageUrls, handleImageClick }) {




  const gridImages = imageUrls.map((imageUrl, index) => {
    return (
      <div className="user-portfolio__portfolio-item" key={index}>
        <img
          src={imageUrl.url}
          alt={` ${index + 1}`}
          onClick={() => handleImageClick(imageUrl)}
        />
      </div>
    );
  });

  return (
    <Card>
      <div className="user-portfolio">
        <div className="user-portfolio__header">
          <img className="user-portfolio__icon" src={ChequaiLogo} alt="Chequeai " />
          <h4 className="user-portfolio__title">Chequeai</h4>
        </div>
        <div className="user-portfolio__content">
          <div className="user-portfolio__portfolio">{gridImages}</div>
        </div>
      </div>
    </Card>
  );
}
