import React, { useState, useContext } from 'react';
import './EarningsActivity.scss';
import EarningsStatement from '../EarningsStatement/EarningsStatement';
import ThemeContext from '../../context/ThemeContext';

export default function EarningsActivity({ transactions, username, handleAccept, handleDecline }) {
  const isMobile = window.innerWidth <= 768; // Detect mobile based on screen width

  const formatDate = (dateString) => {
      if (!dateString) return 'Date not available';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'Invalid Date';
  
      // Options for time and date
      const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
      const dateOptionsMobile = { month: '2-digit', day: '2-digit', year: '2-digit' }; // MM/DD/YY for mobile
      const dateOptionsDesktop = { day: '2-digit', month: 'short', year: 'numeric' };   // DD Mon YYYY for larger screens (short month)
  
      // Format time
      const formattedTime = new Intl.DateTimeFormat('en-GB', timeOptions).format(date);
  
      // Format date
      const formattedDate = isMobile
          ? new Intl.DateTimeFormat('en-GB', dateOptionsMobile).format(date)  // MM/DD/YY for mobile
          : new Intl.DateTimeFormat('en-GB', dateOptionsDesktop).format(date); // DD Mon YYYY for desktop (short month)
  
      // Combine for mobile or larger screens
      return isMobile
          ? `${formattedTime}, ${formattedDate}`  // Mobile: HH:mm:ss, MM/DD/YY
          : `${formattedDate}, ${formattedTime}`; // Desktop: DD Mon YYYY, HH:mm:ss
  };
  
  

  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 4;

  // Calculate indexes for current page
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={`earnings-activity earnings-activity--${themeClass}`}>
      <h3 className="earnings-activity__title">Transactions</h3>
      <div>
        {currentTransactions.map((transaction) => {
          const isCampaignTransaction = transaction.campaignId && transaction.campaignId._id;

          if (!transaction || !transaction._id || (!transaction.campaignId && !transaction.donationId) || !transaction?.sellerId?._id) {
            console.error('Invalid transaction object:', transaction);
            return null;
          }
          
          const campaignOrDonationId = isCampaignTransaction ? transaction.campaignId._id : transaction.donationId._id;
          const campaignOrDonationName = isCampaignTransaction ? transaction.campaignId.campaignName : 'Fan Donation';
          const campaignOrDonationStatus = isCampaignTransaction ? transaction.campaignId.status : transaction.status.slice(0, 1).toUpperCase() + transaction.status.slice(1); // Default for donations
          const sponsorOrDonatorLabel = isCampaignTransaction ? '(brand)' : '(sponsor)'; // Adjust the label based on transaction type

          return (
            <EarningsStatement
              username={username || ""}
              key={transaction._id}
              campaignId={campaignOrDonationId}
              campaignName={campaignOrDonationName}
              campaignStatus={campaignOrDonationStatus}
              fee={transaction.total}
              paymentStatus={transaction.creatorPaymentStatus}
              sponsorOrDonatorLabel={sponsorOrDonatorLabel} // Pass label for display
              sponsorOrDonatorName={transaction.buyerId?.username || 'Anonymous'} // Adjust based on transaction type
              creator={transaction.sellerId.username}
              transactionDate={formatDate(transaction.createdAt)}
              handleAccept={isCampaignTransaction ? handleAccept : undefined} // Buttons only for campaign
              handleDecline={isCampaignTransaction ? handleDecline : undefined} // Buttons only for campaign
              currency={transaction.currency}
              totalAfterFees={transaction.totalAfterFees}
            />
          );
        })}
      </div>
      {/* Pagination controls */}
      <div className="pagination">
        {[...Array(Math.ceil(transactions.length / transactionsPerPage)).keys()].map((pageNumber) => (
          <button
            className={`btn ${currentPage === pageNumber + 1 ? 'selected' : ''} btn--${themeClass}`}
            key={pageNumber}
            onClick={() => paginate(pageNumber + 1)}
          >
            {pageNumber + 1}
          </button>
        ))}
      </div>
    </div>
  );
}
