import React, { useState, useEffect, useContext } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from '../../Components/Navigation/Navigation';
import './AddProductPage.scss';
import Card from '../../Components/Card/Card';
import InputField from '../../Components/InputField/InputField';
import Button from '../../Components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import TextArea from '../../Components/TextArea/TextArea';
import Resizer from "react-image-file-resizer";
import productImg from '../../assets/defaults/product.png';
import ThemeContext from '../../context/ThemeContext';
import productImgLight from '../../assets/defaults/productLight.png';


export default function AddProductPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [campaignStatus, setCampaignStatus] = useState(null);
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    const [product, setProduct] = useState({
        name: '',
        price: '',
        sku: '',
        category: '',
        url: '',
        description: '',
    });
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    let productImage = themeMode === 'light' ? productImgLight : productImg;

    const handlePost = async (e) => {
        e.preventDefault();

        // Validate price to be numeric digits only
        const priceRegex = /^[0-9]+$/;
        if (!priceRegex.test(product.price)) {
            setError('Price must be numeric digits only');
            return;
        }

        if (image) {
            Resizer.imageFileResizer(image, 500, 500, "PNG", 100, 0, async (uri) => {
                try {
                    let { data } = await axios.post(
                        `${process.env.REACT_APP_API}/campaigns/${id}/product`,
                        {
                            image: uri,
                            product,
                        }
                    );
                    setImage(data);
                    // console.log("Product added");
                    // console.log("IMAGE UPLOADED", data);
                    navigate('/orders');
                } catch (err) {
                    console.log("Failed to upload image. Try later.", err);
                }
            });
        } else {
            try {
                //let { data } = 
                await axios.post(
                    `${process.env.REACT_APP_API}/campaigns/${id}/product`,
                    {
                        product,
                    }
                );
                // console.log("Product added", data);
                navigate('/orders');
            } catch (err) {
                console.log("Failed to add product. Try later.", err);
            }
        }
    };

    const validateImg = (e) => {
        const file = e.target.files[0];
        if (file.size > 2048576) {
            console.error("Max file size 1mb");
        } else {
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    useEffect(() => {
        const getCampaignStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}/campaign-status`);
                // console.log(response);
                setCampaignStatus(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        if (id && !campaignStatus) {
            getCampaignStatus();
        }
    }, [id, campaignStatus]);

    return (
        <UserRoute>
            <div className="add-product-page">
                <Navigation title={'Add Product'} back={true} />
                {(campaignStatus !== 'Completed' && campaignStatus !== 'Post Campaign') ? (
                    <Card className="card--input">
                        <div className="add-camp-pg__profile-img">
                            <label htmlFor="file">
                                <img className="add-camp-pg__img" src={imagePreview ? imagePreview : productImage} alt="profile" />
                            </label>
                            <input type="file" id="file" hidden accept='image/png, image/jpeg, image/webp' onChange={validateImg} />
                        </div>
                        <InputField
                            label="Name"
                            name="name"
                            type="text"
                            value={product.name}
                            onChange={handleInputChange}
                        />
                        <InputField
                            label="Price"
                            name="price"
                            type="number"
                            value={product.price}
                            onChange={handleInputChange}
                        />
                        {error && <div className="error-message">{error}</div>}
                        <InputField
                            label="SKU"
                            name="sku"
                            type="text"
                            value={product.sku}
                            onChange={handleInputChange}
                        />
                        <InputField
                            label="Category"
                            name="category"
                            type="text"
                            value={product.category}
                            onChange={handleInputChange}
                        />
                        <InputField
                            label="Link"
                            name="url"
                            type="url"
                            value={product.url}
                            onChange={handleInputChange}
                        />
                        <TextArea
                            label="Description"
                            name="description"
                            type="text"
                            value={product.description}
                            onChange={handleInputChange}
                        />
                        <Button modifier="button" onClick={handlePost}>
                            Add Product
                        </Button>
                    </Card>
                ) : (
                    <Card className={`card--link campaign-complete campaign-complete--${themeClass}`}>
                        <p><span>Campaign</span> has been</p>
                        <p>{campaignStatus}</p>
                    </Card>
                )}
            </div>
        </UserRoute>
    );
}
