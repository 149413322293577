import React, { useEffect, useState, useCallback } from "react";
import Button from "../../Components/Button/Button";
import Card from "../../Components/Card/Card";
import InputField from "../../Components/InputField/InputField";
import "./SettingsGeneralPage.scss";
// import { Context } from "../../context/index";
import UserRoute from "../../Routes/Auth";
import displayPicture from "../../assets/defaults/displayPicture.png";
import Resizer from "react-image-file-resizer";
import Toggle from "../../Components/Toggle/Toggle";
import Navigation from "../../Components/Navigation/Navigation";
import PhoneNumberInput from "../../Components/PhoneNumberInput/PhoneNumberInput";
import axios from "axios";


export default function SettingsGeneralPage() {
  const [userLogged, setUserLogged] = useState("");

  // const {state: { user }, } = useContext(Context);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    location: "",
    bio: "",
    website: "",
    country: "",
  });

  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);


  const fetchProfileData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/user/edit-profile/general-profile`);
      if (response && response.data && response.data.user) {
        console.log(response.data.user);
        const user = response.data.user;
        setFormData({
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          location: user.location,
          bio: user.bio,
          website: user.website,
          country: user.country,
        });
        setIsPrivate(user.isPrivate);
        setPhoneNumber(user.phoneNumber);
        setImagePreview(user.picture === "/avatar.png" ? displayPicture : user.picture);
        setUserLogged(user.username);
      }
    } catch (err) {
      console.error("Failed to fetch profile data", err);
    }
  }, []);

  useEffect(() => {
    fetchProfileData();
  }, [fetchProfileData]);

  const { firstName, lastName, username, location, bio, website, country } =
    formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateImg = (e) => {
    const file = e.target.files[0];
    if (file.size > 2048576) {
      console.error("Max file size 1mb", { position: "top-center" });
    } else {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleImage = async (e) => {
    e.preventDefault();
    console.log("image", image);
    const data = new FormData();

    if (image) {
      data.append("image", image);
    }

    data.append("username", username);
    data.append("location", location);
    data.append("bio", bio);
    data.append("website", website);
    Resizer.imageFileResizer(image, 500, 500, "PNG", 100, 0, async (uri) => {
      try {
        let { data } = await axios.put(
          `${process.env.REACT_APP_API}/user/edit-profile/image`,
          {
            image: uri,
          }
        );
        console.log("IMAGE UPLOADED", data);
        // set image in the state
        setImage(data);
        console.log("Profile Picture Updated");
        window.location.reload();
      } catch (err) {
        console.log("Profile Picture failed to upload. Try later.", err);
      }
    });
  };

  const changePrivate = async (newPrivateValue) => {
    console.log("private", newPrivateValue);
    try {
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/user/edit-profile/private`,
        {
          private: newPrivateValue,
        }
      );
      console.log("Account Private", data);
      setIsPrivate(newPrivateValue); // Update the state with the new value
    } catch (err) {
      console.log("Private failed to change. Try later.", err);
    }
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token'); // Assuming you store your token in localStorage
      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/user/edit-profile/general-profile`,
        {
          username,
          location,
          bio,
          website,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("PROFILE UPDATED", data);
    } catch (err) {
      console.log("Profile failed to update. Try later.", err);
    }
  };

  return (
    <UserRoute username={userLogged}>
      <div className="settings_general-pg">
        <Navigation heading={"Edit Profile"} back={true} />
        <Card className="card--padding card--settings">
          <div className="settings_general-pg__profile">
            <div className="settings_general-pg__profile-img">
              <label htmlFor="file">
                <img
                  className="settings_general-pg__img"
                  src={imagePreview}
                  alt="profile"
                />
              </label>
              <input
                type="file"
                id="file"
                hidden
                accept="image/png, image/jpeg"
                onChange={validateImg}
              />
            </div>
            {imagePreview && image && (
              <div className="settings_general-pg__profile__upload">
                <Button modifier={"button button__update--avatar"} onClick={handleImage}>
                  <span>Change</span>
                </Button>
              </div>
            )}
          </div>
    

          <InputField
            label="First Name"
            name="firstName"
            type="text"
            value={firstName}
            onChange={handleInputChange}
          />
          <InputField
            label="Last Name"
            name="lastName"
            type="text"
            value={lastName}
            onChange={handleInputChange}
          />
          <InputField
            label="Username"
            name="username"
            type="text"
            value={username}
            onChange={handleInputChange}
          />

          <InputField
            label="Location"
            name="location"
            type="text"
            value={location}
            onChange={handleInputChange}
          />
          <InputField
            label="Bio"
            name="bio"
            type="text"
            value={bio}
            onChange={handleInputChange}
          />
          <InputField
            label="Website"
            name="website"
            type="text"
            value={website}
            onChange={handleInputChange}
          />
          <InputField
            label="Country"
            name="country"
            type="text"
            value={country}
            onChange={handleInputChange}
            disabled={true}
          />
          <div className="settings_general-pg__last-row-container">
            <div className="settings_general-pg__phoneNumber-container">
              <label className="settings_general-pg__phoneNumber-label" htmlFor="phoneNumber">
                Phone Number
              </label>
              <PhoneNumberInput 
                label="Phone Number"
                name="phoneNumber"
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                setIsPhoneNumberValid={setIsPhoneNumberValid}
                isValid={isPhoneNumberValid}
                modifer="settings_general-pg__phoneNumber-container"
                themeClass="light-theme"
              />
            </div>
            <Toggle
              label="Private Account"
              onChange={changePrivate}
              value={isPrivate}
            />
          </div>

          <Button modifier="update button" onClick={handleSubmit}>
            <span style={{ fontWeight: "700" }}>Change</span>
          </Button>
        </Card>
      </div>
    </UserRoute>
  );
}
