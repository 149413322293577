import React, { useState } from "react";
import "./GetStartedModal.scss";
import profileImage from "../../assets/GetStarted/ProfilePage.png";
import featuredImage from "../../assets/GetStarted/FeaturedPage.png";
import OrdersPage from "../../assets/GetStarted/OrdersPage.png";
import ClipBoardIcon from "../../Icons/ClipBoardIcon";
import chequeaiLogo from "../../assets/icons/chequai-logo.png";

const GetStartedModal = ({ isOpen, onClose, themeMode, profileUrl }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`https://www.chequai.com/${profileUrl}`);
  };

  const steps = [
    {
      title: "Welcome",
      content: (
        <div className="welcome-section">
          <div className="welcome-section__logo">
            <img src={chequeaiLogo} alt="Chequeai Logo" />
            <h1 className="welcome-section__logo__title">Chequeai</h1>
          </div>
          <p>The Home for Creators and Brands</p>
        </div>
      ),
    },
    {
      title: "Set Up Your Profile",
      content: (

          <p>
            Add your social platforms, such as Instagram, TikTok, YouTube, and more. You can also include your affiliate, website, and shop links. {" "}
            <a href="/settings/socials" className="get-started-link">Get Started</a> 
          </p>

      ),
      image: profileImage,
    },
    {
      title: "Discover Brands",
      content: (

        <p>
         Explore brands that align with your style. {" "}
          <a href="/featured/" className="get-started-link">Discover now...</a>
        </p>

      ),
      image: featuredImage,
    },
    {
      title: "Manage Deals",
      content: (

          <p>
            Keep track of your brand deals (collaborations) and earnings. Set up your account to receive payments directly from your brands. {" "} 
            <a href="/settings/monetization/" className="get-started-link">Connect Stripe with Chequeai</a>
          </p>
  
        ),
      image: OrdersPage,
    },
    {
      title: "Grow Together",
      content: (
        <div className="grow-together-section">
          <p>
            Share your <strong>Chequeai</strong> Portfolio :)
          </p>
          <div className="profile-url-container" onClick={handleCopyLink}>
            https://www.chequai.com/{profileUrl}
            <ClipBoardIcon className="clipboard-icon" />
          </div>
        </div>
      ),
    },
  ];

  if (!isOpen) return null;

  const handleNext = () =>
    currentStep < steps.length - 1
      ? setCurrentStep(currentStep + 1)
      : onClose();
  const handlePrev = () => currentStep > 0 && setCurrentStep(currentStep - 1);

  return (
    <div className="get-started-modal-overlay" onClick={onClose}>
      <div
        className={`get-started-modal-content ${
          themeMode === "light" ? "light-theme" : "dark-theme"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header">
          <h2>{steps[currentStep].title}</h2>
        </div>
        <div className="modal-body">
          {steps[currentStep].image && (
            <img
              src={steps[currentStep].image}
              alt={steps[currentStep].title}
              className="modal-image"
            />
          )}
          {steps[currentStep].content}
        </div>
        <div className="modal-footer">
          <button
            className="prev-button"
            onClick={handlePrev}
            disabled={currentStep === 0}
          >
            Previous
          </button>
          <button className="next-button" onClick={handleNext}>
            {currentStep === steps.length - 1 ? "Finish" : "Next"}
          </button>
        </div>
        <div className="modal-progress">
          {steps.map((_, index) => (
            <div
              key={index}
              className={`progress-dot ${
                index === currentStep ? "active" : ""
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GetStartedModal;
