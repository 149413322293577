import React, { useEffect, useContext } from 'react';
import "./UserProfile.scss";
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import displayPicture from '../../assets/defaults/displayPicture.png'
import Instagram from "../../assets/icons/instagram.png"
import TikTok from "../../assets/icons/tiktok1.svg";
import Youtube from "../../assets/icons/youtube1.svg"
import Xsvg from "../../assets/icons/x.svg"
import Spotify from "../../assets/icons/spotify.png"
import Twitch from "../../assets/icons/twitch1.png"
import Linkify from "react-linkify";

import UserLinkCard from '../UserLinkCard/UserLinkCard';
import Card from '../Card/Card';
import SettingsIcon from './SettingsIcon';
import ThemeContext from '../../context/ThemeContext';
import { getFullUrl } from '../../utils/socialUtils';

export default function UserProfile({ otherUser, loggedInUser, username, category, location, img, bio, dp, handleConnect, handleAccept, handleDecline,
    handleDisconnect, isPrivate, isConnected, roomId, otherRole, incomingRequest, outgoingRequest, setShowLoginModal, setDonateModal,  }) {

    console.log(loggedInUser._id, otherUser._id)

    let message = "Connect";
    let handle;

    if (isConnected) {
        message = "Connected"
        handle = handleDisconnect
    } else if (incomingRequest.includes(loggedInUser._id)) {
        message = "Pending"
        handle = () => { }
    } else if (outgoingRequest.includes(loggedInUser._id)) {
        message = "Accept"
        handle = handleAccept
    } else {
        message = "Connect";
        handle = handleConnect;
    }

    const { themeMode } = useContext(ThemeContext);

    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    useEffect(() => { }, [loggedInUser, username, category, location, img, bio, dp, handleConnect, isPrivate, isConnected, roomId])

    return (
        <div className={`user-p user-p--${themeClass}`} >
            <div className='user-p__details'>
                <div className="user-p__img-container">
                    <img className="user-p__img" src={dp === "/avatar.png" ? displayPicture : dp} alt="user profile" />
                </div>
                <div className="user-p__info-container">
                    <div className='user-p__row'>
                        <p className="user-p__info-username">{username}</p>
                        {
                            loggedInUser.username === username && <Link className="user-p__link" to={"/settings"}>
                                <SettingsIcon />
                            </Link>
                        }

                    </div>
                    <div className='user-p__subscriber-info'>
                        <Link className="user-p_connections" to={loggedInUser !== null ? `/${username}/connections/` : `/login`}>
                            <p className="user-p__info-connections"><span className='user-p__connections__text--b'>{otherUser?.connections?.length || 0}</span>{otherRole === "Brand" ? "Creators" : "Connections"}</p>
                        </Link>
                        <p className="user-p__info-connections"><span className='user-p__connections__text--b'>{otherUser?.averageRating || 0.00}</span>{" Rating"}</p>
                        {/* <Link className="user-p_connections" to={`/${username}/connections/`}>
                            <p className="user-p__info-connections"><span className='user-p__connections__text--b'>{otherUser?.supporters?.length || 0}</span>{" Supporters"}</p>
                        </Link> 
                        <Link className="user-p_connections" to={`/${username}/connections/`}>
                            <p className="user-p__info-connections"><span className='user-p__connections__text--b'>{otherUser?.averageRating || 0}</span>{" Rating"}</p>
                        </Link>  */}
                    </div>
                    <div>
                        <p className="user-p__info-category">{category}   |   {location} </p>
                        <Linkify>
                            <p className={`user-p__info-website user-p__info-website--${themeClass}`}>{otherUser?.website}</p>
                        </Linkify>
                    </div>
                    <div className='user-p__actions-row'>
                        {
                            loggedInUser ?
                                (loggedInUser.username !== username ? 
                                    <div className="user-p__link">
                                        <Button modifier="button--user" onClick={handle}>{message}</Button> 
                                        {message === "Accept" &&
                                            <Button modifier="button--user" onClick={handleDecline}>Decline</Button> 
                                        }
                                    </div>
                                    : <Link className="user-p__link" to={"/settings/promotions"}><Button modifier="button--user" >Edit Promotions</Button></Link> )
                            : <div className="user-p__link" ><Button modifier="button--user" onClick={() => { setShowLoginModal(true) }}>{message}</Button></div>
                        }
                        {
                            isConnected && loggedInUser.username !== username && <Link className="user-p__link user-p__link--left" to={`/messages/${roomId}`}><Button modifier="button--user">Message</Button></Link>
                        }
                        {
                            !isPrivate && !isConnected &&  <Link className="user-p__link user-p__link--left" to={`/messages/${roomId}`}><Button modifier="button--user" >Message</Button></Link>
                        }
                    </div>
                    {console.log(otherUser?.connections)}
                    <div className='user-p__actions-row'>
                    {
                        loggedInUser ? (
                            // Check if the current user is not the same as the profile owner and the profile is not private
                            loggedInUser.username !== username && (
                                otherUser.isPrivate ? (
                                    // If otherUser is private, check if they are connected with the logged-in user
                                    otherUser?.connections?.includes(loggedInUser._id) ? (
                                        <Link className="user-p__link" to={`/${username}/promote`}>
                                            <Button modifier="button--user">Promote</Button>
                                        </Link>
                                    ) : (
                                        // If the user is private but not connected, show login modal
                                        //<div className="user-p__link" onClick={() => setShowLoginModal(true)}>
                                        //    <Button modifier="button--user">Promote</Button>
                                        //</div>
                                        <></>
                                    )
                                ) : (
                                    // If otherUser is not private, allow promotion
                                    <Link className="user-p__link" to={`/${username}/promote`}>
                                        <Button modifier="button--user">Promote</Button>
                                    </Link>
                                )
                            )
                        ) : (
                            // If not logged in, prompt to log in
                            <div className="user-p__link" onClick={() => setShowLoginModal(true)}>
                                <Button modifier="button--user">Promote</Button>
                            </div>
                        )
                    }
                    {
                        (loggedInUser.username !== username && otherUser?.donationSettings?.donationEnabled) && 
                        <div className="user-p__link user-p__link--left">
                            <Button modifier="button--user" onClick={() => setDonateModal(true)}>Donate</Button>
                        </div>
                    }
                    </div>
                </div>
            </div>
            <div className='user-p__actions'>
                <div className='user-p__info'>
                    <p className="user-p__bio">{bio}</p>
                </div>
                <Card className="card--row user--links">
                    {otherUser?.socialMedia?.instagram && (
                        <UserLinkCard title="" src={getFullUrl('https://www.instagram.com/', otherUser.socialMedia.instagram)} icon={Instagram} />
                    )}
                    {otherUser?.socialMedia?.spotifyMusic && (
                        <UserLinkCard title="" src={getFullUrl('https://open.spotify.com/artist/', otherUser.socialMedia.spotifyMusic)} icon={Spotify} />
                    )}

                    {otherUser?.socialMedia?.youtube && (
                        <UserLinkCard title="" src={getFullUrl('', otherUser.socialMedia.youtube)} icon={Youtube} />
                    )}
                    {otherUser?.socialMedia?.liveStreaming && (
                        <UserLinkCard title="" src={getFullUrl('https://twitch.com/', otherUser.socialMedia.liveStreaming)} icon={Twitch} />
                    )}
                    {otherUser?.socialMedia?.twitter && (
                        <UserLinkCard title="" src={getFullUrl('https://x.com/', otherUser.socialMedia.twitter)} icon={Xsvg} />
                    )}
                    {otherUser?.socialMedia?.tiktok && (
                        <UserLinkCard title="" src={getFullUrl('https://www.tiktok.com/@', otherUser.socialMedia.tiktok)} icon={TikTok} />
                    )}
                </Card>
            </div>
        </div>
    )
}
