import React, { useContext, useEffect, useState, useCallback } from "react";
import Card from "../../Components/Card/Card";
import InputField from "../../Components/InputField/InputField";
import "./SettingsSocialPage.scss";
import { Context } from "../../context/index";
import UserRoute from "../../Routes/Auth";
import Button from "../../Components/Button/Button";
import Navigation from "../../Components/Navigation/Navigation";
import CheckIcon from "./CheckIcon";
import instagramIcon from "../../assets/icons/instagram.png";
import tiktokIcon from "../../assets/icons/tiktok2.webp";
import xIcon from "../../assets/icons/x.svg";
import snapchatIcon from "../../assets/icons/snapchat.svg";
import spotifyIcon from "../../assets/icons/spotify.png";
import youtubeIcon from "../../assets/icons/youtube1.svg";
import twitchIcon from "../../assets/icons/twitch1.png";
import pinterestIcon from "../../assets/icons/pinterest.png";
import kickIcon from "../../assets/icons/kickLogo.png";
import FacebookTikTokConnect from "../../Components/FacebookTikTokConnect/FacebookTikTokConnect";
import { apiGet, apiPut } from "../../utils/apiUtils"; // Adjust the import path as necessary

export default function SettingsSocialPage() {
  const [username, setUsername] = useState("");
  const {
    state: { user },
  } = useContext(Context);
  const [formData, setFormData] = useState({
    instagram: "",
    tiktok: "",
    youtube: "",
    spotifyMusic: "",
    pinterest: "",
    podcast: "",
    liveStreaming: "",
    twitter: "",
    snapchat: "",
    spotifyPodcast: "",
    youtubeEmbed: "",
    kick: "",
  });
  const [changedFields, setChangedFields] = useState({});
  const [provider, setProvider] = useState("");

  const fetchSocialMediaData = useCallback(async () => {
    try {
      const response = await apiGet("/settings/socials");
      if (response && response.socialMedia) {
        const socialMedia = response.socialMedia;
        setFormData({
          instagram: socialMedia.instagram || "",
          tiktok: socialMedia.tiktok || "",
          youtube: socialMedia.youtube || "",
          spotifyMusic: socialMedia.spotifyMusic || "",
          podcast: socialMedia.podcast || "",
          liveStreaming: socialMedia.liveStreaming || "",
          pinterest: socialMedia.pinterest || "",
          twitter: socialMedia.twitter || "",
          snapchat: socialMedia.snapchat || "",
          spotifyPodcast: socialMedia.spotifyPodcast || "",
          youtubeEmbed: socialMedia.youtubeEmbed || "",
        });
        setUsername(user.username);
      } else {
        console.error("Social media data is missing");
      }
    } catch (err) {
      console.error("Failed to fetch social media data", err);
    }
  }, [user.username]);

  useEffect(() => {
    fetchSocialMediaData();
    // Check for authentication response from Facebook and TikTok
    const handleAuthResponse = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const error = urlParams.get("error");

      if (code && provider) {
        try {
          if (provider === "facebook") {
            await apiPut(`/auth/facebook/callback`, { code });
            console.log("Facebook authentication successful");
          } else if (provider === "tiktok") {
            await apiPut(`/auth/tiktok/callback`, { code });
            console.log("TikTok authentication successful");
          }
        } catch (err) {
          console.error(`Failed to handle ${provider} authentication`, err);
        }
      } else if (error) {
        console.error("Authentication declined:", error);
      }
    };

    handleAuthResponse();
  }, [provider, fetchSocialMediaData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setChangedFields({ ...changedFields, [name]: true });
  };

  const handleUpdate = async () => {
    try {
      const response = await apiPut(
        "/user/edit-profile/social-media",
        formData
      );
      console.log(response);
    } catch (error) {
      console.error("Error updating social media data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdate();
  };

  const {
    instagram,
    tiktok,
    youtube,
    spotifyMusic,
    spotifyPodcast,
    liveStreaming,
    snapchat,
    twitter,
    youtubeEmbed,
    pinterest,
    kick,
  } = formData;

  return (
    <UserRoute username={username}>
      <div className="settings-social-pg">
        <Navigation heading={"Social Platforms"} back={true} />
        <Card className="card--padding">
          { false && <FacebookTikTokConnect setProvider={setProvider} /> }
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <InputField
                label="Instagram"
                name="instagram"
                type="text"
                value={instagram}
                icon={instagramIcon}
                onChange={handleInputChange}
                placeholder={"https://www.instagram.com/username"}
              />
              {changedFields.instagram && (
                <CheckIcon themeMode={user.themeMode} />
              )}
            </div>
            <div className="input-group">
              <InputField
                label="TikTok"
                name="tiktok"
                type="text"
                value={tiktok}
                icon={tiktokIcon}
                onChange={handleInputChange}
                placeholder={"https://www.tiktok.com/@username"}
              />
              {changedFields.tiktok && <CheckIcon themeMode={user.themeMode} />}
            </div>
            <div className="input-group">
              <InputField
                label="X"
                name="twitter"
                type="text"
                value={twitter}
                icon={xIcon}
                onChange={handleInputChange}
                placeholder={"https://www.x.com/@username"}
              />
              {changedFields.twitter && (
                <CheckIcon themeMode={user.themeMode} />
              )}
            </div>
            <div className="input-group">
              <InputField
                label="Snapchat"
                name="snapchat"
                type="text"
                value={snapchat}
                icon={snapchatIcon}
                onChange={handleInputChange}
              />
              {changedFields.snapchat && (
                <CheckIcon themeMode={user.themeMode} />
              )}
            </div>
            <div className="input-group">
              <InputField
                label="Artist ID"
                name="spotifyMusic"
                type="text"
                value={spotifyMusic}
                icon={spotifyIcon}
                onChange={handleInputChange}
                placeholder={"6DARBhWbfcS9E4yJzcliqQ"}
              />
              {changedFields.spotifyMusic && (
                <CheckIcon themeMode={user.themeMode} />
              )}
            </div>
            <div className="input-group">
              <InputField
                label="Podcast ID"
                name="spotifyPodcast"
                type="text"
                value={spotifyPodcast}
                icon={spotifyIcon}
                onChange={handleInputChange}
                placeholder={"5QaSbbv2eD4SFrlFR6IyY7"}
              />
              {changedFields.spotifyPodcast && (
                <CheckIcon themeMode={user.themeMode} />
              )}
            </div>
            <div className="input-group">
              <InputField
                label="Video ID"
                name="youtubeEmbed"
                type="text"
                value={youtubeEmbed}
                icon={youtubeIcon}
                onChange={handleInputChange}
                placeholder={"D48XxB6yvaE"}
              />
              {changedFields.youtubeEmbed && (
                <CheckIcon themeMode={user.themeMode} />
              )}
            </div>
            <div className="input-group">
              <InputField
                label="Channel"
                name="youtube"
                type="url"
                value={youtube}
                onChange={handleInputChange}
                icon={youtubeIcon}
                placeholder={"https://www.youtube.com/@username"}
              />
              {changedFields.youtube && (
                <CheckIcon themeMode={user.themeMode} />
              )}
            </div>
            <div className="input-group">
              <InputField
                label="Twitch"
                name="liveStreaming"
                type="url"
                value={liveStreaming}
                onChange={handleInputChange}
                icon={twitchIcon}
                placeholder={"https://twitch.com/@username"}
              />
              {changedFields.liveStreaming && (
                <CheckIcon themeMode={user.themeMode} />
              )}
            </div>
            <div className="input-group">
              <InputField
                label="Kick"
                name="kick"
                type="url"
                value={kick}
                onChange={handleInputChange}
                icon={kickIcon}
                placeholder={"https://www.kick.com/@username"}
              />
              {changedFields.kick && (
                <CheckIcon themeMode={user.themeMode} />
              )}
            </div>
            <div className="input-group">
              <InputField
                label="Pinterest"
                name="pinterest"
                type="url"
                value={pinterest}
                onChange={handleInputChange}
                icon={pinterestIcon}
                placeholder={"https://www.pinterest.com/username"}
              />
              {changedFields.pinterest && (
                <CheckIcon themeMode={user.themeMode} />
              )}
            </div>
            

            <Button type="submit">
              <p>Save</p>
            </Button>
          </form>
        </Card>
      </div>
    </UserRoute>
  );
}
