
import React, { useContext } from 'react';
import ThemeContext from '../../context/ThemeContext';
import './InputField.scss';

const InputField = ({ label, name, type, value, onChange, errorMessage, placeholder, disabled=false, icon, modifier }) => {

  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  return (
    <div className={`input-field input-field--${themeClass}` }>
      <label className={`input-field__label ${modifier}`} htmlFor={name}>
        {icon && <img src={icon} alt="icon"  className="input-field__icon" />}
        {label}
      </label>
      <div className="input-field__input-container">
        <input
          className={`input-field__input input-field__input--${themeClass}` }
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default InputField;