import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./TwoFactorAuthLogin.scss";
import LoginHeader from "../LoginHeader/LoginHeader";

const TwoFactorAuthLogin = ({ setTwoFactorVerified, themeMode }) => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberDevice, setRememberDevice] = useState(false);
  const themeModifier = themeMode === "light" ? "light" : "dark";

  const inputRefs = useRef([]);

  // Check if the device is remembered
  useEffect(() => {
    const storedTwoFactorExpiry = localStorage.getItem("twoFactorExpiry");
    if (storedTwoFactorExpiry && new Date(storedTwoFactorExpiry) > new Date()) {
      setTwoFactorVerified(true); // Skip 2FA if valid
    }
  }, [setTwoFactorVerified]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (index < 5 && value) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newCode = [...code];
      if (newCode[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        newCode[index] = "";
        setCode(newCode);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const verificationCode = code.join("");
    if (verificationCode.length !== 6) {
      setError("Please enter all 6 digits.");
    } else {
      setError("");
      setLoading(true);

      try {
        const response = await axios.post("/2fa/verify-2fa", { verificationCode });
        if (response.data.success) {
          setTwoFactorVerified(true);
          localStorage.setItem("twoFactorVerified", "true");

          // If "Remember this device" is checked, store the expiration date
          if (rememberDevice) {
            const expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 14); // Two weeks from now
            localStorage.setItem("twoFactorExpiry", expiryDate.toISOString());
          }
        } else {
          setError("Invalid code. Please try again.");
        }
      } catch (err) {
        setError("There was an error verifying the code.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={`two-factor-login two-factor-login--${themeModifier}`}>
      <LoginHeader enabled={false} logout={true} />

      <div className="two-factor-login__content">
        <div className="two-factor-login__container">
          <h2 className={`two-factor-login__title two-factor-login__title--${themeModifier}`}>
            Two-Factor Authentication
          </h2>
          <p className={`two-factor-login__instruction two-factor-login__instruction--${themeModifier}`}>
            Please enter the Code from your 2FA App
          </p>

          <form className="two-factor-login__form" onSubmit={handleSubmit}>
            <div className="two-factor-login__inputs">
              {code.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  className={`two-factor-login__input two-factor-login__input--${themeModifier}`}
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  maxLength="1"
                  ref={(el) => (inputRefs.current[index] = el)}
                  autoFocus={index === 0}
                />
              ))}
            </div>
            {error && <p className="two-factor-login__error">{error}</p>}

            <div className="two-factor-login__remember">
              <input
                type="checkbox"
                id="rememberDevice"
                checked={rememberDevice}
                onChange={() => setRememberDevice(!rememberDevice)}
              />
              <label htmlFor="rememberDevice" className={`two-factor-login__remember-label two-factor-login__remember-label--${themeModifier}`}>
                Do not require for 2-FA on this device for 14 days
              </label>
            </div>

            <button type="submit" className="two-factor-login__button" disabled={loading}>
              {loading ? "Verifying..." : "Verify"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuthLogin;
