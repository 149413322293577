import React, { useContext, useEffect, useState, useCallback } from "react";
import axios from "axios";
import Select from "react-select";
import { loadStripe } from "@stripe/stripe-js";
import Button from "../../Components/Button/Button";
import Card from "../../Components/Card/Card";
import InputField from "../../Components/InputField/InputField";
// import { Context } from "../../context/index";
import displayPicture from "../../assets/defaults/displayPicture.png";
import "./TeamsPage.scss";
import UserRoute from "../../Routes/Auth";
import ThemeContext from "../../context/ThemeContext";
import { Link } from "react-router-dom";

const roleOptions = [{ value: "team-member", label: "Team Member" }];

const styles = {
  control: (base) => ({
    ...base,
    backgroundColor: "#0a0a0a",
    borderRadius: "4px",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "black",
    borderRadius: "4px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: "neutral70",
    },
  }),
  option: (base, state) => ({
    ...base,
    color: "darkgray",
    backgroundColor: state.isFocused ? "neutral70" : "black",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "darkgray",
    fontSize: "12px",
  }),
  placeholder: (base) => ({
    ...base,
    color: "darkgray",
    fontSize: "12px",
  }),
  input: (base) => ({
    ...base,
    color: "darkgray",
    fontSize: "12px",
  }),
};

const lightMode = {
  control: (base) => ({
    ...base,
    backgroundColor: "#E1E1E1",
    borderRadius: "4px",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#E1E1E1",
    borderRadius: "4px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: "neutral70",
    },
  }),
  option: (base, state) => ({
    ...base,
    color: "black",
    backgroundColor: state.isFocused ? "rgba(150, 150, 150, 1)" : "#E1E1E1",
    "&:hover": {
      backgroundColor: "rgba(200, 200, 200, 1)",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "black",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "black",
  }),
};

const TeamManagement = ({ brandId }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [newMember, setNewMember] = useState({
    name: "",
    email: "",
    role: roleOptions[0],
  });

  // const {state: { user }, } = useContext(Context);

  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "";

  const fetchTeamMembers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/settings/teams`
      );
      setTeamMembers(response.data);
    } catch (error) {
      console.error("Error fetching team members:", error);
    }
  }, []);

  useEffect(() => {
    fetchTeamMembers();
  }, [fetchTeamMembers]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMember({ ...newMember, [name]: value });
  };

  const handleRoleChange = (selectedOption) => {
    setNewMember({ ...newMember, role: selectedOption });
  };

  const handleAddMember = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}/settings/teams`,
        {
          ...newMember,
          role: newMember.role.value,
        }
      );

      if (response.data.id) {
        const stripe = await loadStripe(
          process.env.REACT_APP_PUBLIC_STRIPE_KEY
        );
        stripe.redirectToCheckout({ sessionId: response.data.id });
      } else {
        setTeamMembers([...teamMembers, response.data]);
        setNewMember({ name: "", email: "", role: roleOptions[0] });
      }
    } catch (error) {
      console.error("Error adding team member:", error);
    }
  };

  // const handleRemoveMember = async (memberId) => {
  //   try {

  //   } catch (error) {
  //     console.error("Error removing team member:", error);
  //   }
  // };

  return (
    <UserRoute>
      <div className="team-management">
        <Card className="team-management__card">
          <div className="team-management__header">
            <h2>Teams</h2>
            <span className="team-management__add-member-label" htmlFor="name">
              Add a team member
          </span>
          </div>
          <div className="team-management__members">
            {/* <h4>Team Members</h4> */}
            {teamMembers.length === 0 ? (
              <div className="team-management__no-members">
                <p className="team-management__no-members-text">
                  No members yet..
                </p>
              </div>
            ) : (
              <ul>
                {teamMembers.map((member) => (
                  <div key={member._id}>
                    <div className={`team-management__profile team-management__profile--${themeClass}`}>
                      <img
                        src={displayPicture}
                        alt="profile"
                        className="team-management__profile-img"
                      />
                      <div className="team-management__profile-info">
                        <div className="team-management__profile--div">
                          <p className="team-management__profile-name">
                            {member.name}
                          </p>
                          <p
                            className="team-management__profile-email"
                            style={{
                              color: "darkgray",
                              fontSize: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          >
                            {member.email}
                          </p>
                        </div>
                        <div className="team-management__profile--div">
                          <p
                            className="team-management__profile-role"
                            style={{
                              fontSize: "0.77rem",
                              marginTop: "0.1rem",
                              color: "darkgray",
                            }}
                          >
                            roles
                          </p>
                          <p
                            className="team-management__profile-role"
                            style={{
                              color: "darkgray",
                              fontSize: "0.8rem",
                              marginTop: "0.50rem",
                            }}
                          >
                            {member.userType
                              .replace(/-/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase())}
                          </p>
                        </div>
                      </div>
                      {/* <button className={`team-m__cta`} modifier={``} onClick={() => handleRemoveMember(member._id)}>Remove</button>
                       */}
                      <Link to={process.env.REACT_APP_STRIPE_BILLING} className={`team-m__cta`} modifier={``}>cancel</Link>
                    </div>
                  </div>
                ))}
              </ul>
            )}
          </div>
          <div className="team-management__add-member">
            <Select
              id="role-select"
              name="role"
              value={newMember.role}
              onChange={handleRoleChange}
              options={roleOptions}
              styles={themeMode === "light" ? lightMode : styles}
              className={`team-management__select team-management__select--${themeClass}`}
            />
            <InputField
              label="Name"
              name="name"
              type="text"
              value={newMember.name}
              onChange={handleInputChange}
            />
            <InputField
              label="Email"
              name="email"
              type="email"
              value={newMember.email}
              onChange={handleInputChange}
            />

            <Button
              modifier={`teams`}
              onClick={handleAddMember}
              price={"$6.99/m"}
            >
              <p>Add Member</p>
            </Button>
          </div>
        </Card>
      </div>
    </UserRoute>
  );
};

export default TeamManagement;
