import React, { useEffect, useState, useContext, useMemo, useCallback } from "react";
import UserRoute from "../../Routes/Auth";
import Card from "../../Components/Card/Card";
import Navigation from "../../Components/Navigation/Navigation";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import OptionsIcon from "../../assets/icons/three-dots.svg";
import OptionsModalBox from "../../Components/OptionsModalBox/OptionsModalBox";
import ConfirmModal from "../../Components/ConfirmModal/ConfirmModal";
import teamImage from "../../assets/defaults/team.png";
import teamLightImage from "../../assets/defaults/teamLight.png";
import CampaignHeader from "../../Components/CampaignHeader/CampaignHeader";
import ThemeContext from "../../context/ThemeContext";
import "./CampaignTeamsPage.scss";
import CampaignSubHeader from "../../Components/CampaignSubHeader/CampaignSubHeader";
import PhoneIcon from "./PhoneIcon";
import MailIcon from "./MailIcon";
import InstagramIcon from "../../assets/icons/instagram.png";
import PortfolioIcon from "./PortfolioIcon";
import ViewCampaignTeamModal from "../../Components/ViewCampaignTeamModal/ViewCampaignTeamModal";
import EditCampaignTeamModel from "../../Components/EditCampaignTeamtModal/EditCampaignTeamModel";
import DarkLoadingSpinner from "../../Components/DarkLoadingSpinner/DarkLoadingSpinner";

const CampaignTeamsPage = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [campaignStatus, setCampaignStatus] = useState(null);
  const [campaignTeam, setCampaignTeam] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isViewProductModalOpen, setIsViewProductModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmAddShop, setIsConfirmAddShop] = useState(false);
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";
  const picture = themeClass === "light-theme" ? teamLightImage : teamImage;
  const [mode, setMode] = useState('Pre Production');

  const fetchCampaign = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/campaigns/${id}/teams`
      );

      if (response.data) {
        console.log(response.data);
        setTitle(response.data.campaignName);
        setCampaignTeam(response.data.campaignTeamMembers || []); // Ensure it's an array
        setCampaignStatus(response.data.status);
      }
    } catch (error) {
      console.error("Failed to fetch campaign:", error);
    }
  }, [id]);

  useEffect(() => {
    if (!title) {
      fetchCampaign();
    }
  }, [campaignTeam, fetchCampaign, title]);

  const handleUpdateTeamMember = useCallback(async (teamMember) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API}/campaigns/${id}/teams/`,
        { teamMember }
      );
      fetchCampaign();
    } catch (err) {
      console.log("Failed to update team member.", err);
    }
  }, [id, fetchCampaign]);

  const handleDeleteTeamMember = useCallback(async (teamMember) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/campaigns/${id}/teams/${teamMember._id}`
      );
      fetchCampaign();
    } catch (err) {
      console.log("Failed to delete team member.", err);
    }
  }, [id, fetchCampaign]);

  const copyToClipboard = useCallback(async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  }, []);

  const handleAddLinkToProfile = useCallback(async (productData) => {
    try {
      await axios.post(`${process.env.REACT_APP_API}/user/edit-profile/link`, {
        title: productData.name,
        url: productData.url,
      });
      fetchCampaign();
    } catch (err) {
      console.log("Failed to add link to profile.", err);
    }
  }, [fetchCampaign]);

  const handleAddProductToShop = useCallback(async (productData) => {
    try {
      await axios.post(`${process.env.REACT_APP_API}/user/edit-profile/shop`, {
        productData,
      });
      fetchCampaign();
    } catch (err) {
      console.log("Failed to add product to shop.", err);
    }
  }, [fetchCampaign]);

  const filteredTeamMembers = useMemo(() => 
    campaignTeam.filter((teamMember) => teamMember.category === mode),
    [campaignTeam, mode]
  );

  if (!campaignTeam) {
    return <DarkLoadingSpinner />;
  }

  return (
    <UserRoute>
      <div className="cp-prod-pg">
        <Navigation
          heading={title}
          back={true}
          edit={`/campaigns/edit/${id}`}
        />
        <Card className="card--margin">
          <div className="campaign-card__header cp-ct-pg__header">
            <div className="campaign-card__header__left">
              <CampaignHeader status={campaignStatus} />
            </div>
            <div className="cp-ct-pg__sub-header">
              <CampaignSubHeader status={campaignStatus} mode={mode} setMode={setMode} />
            </div>
          </div>
          {filteredTeamMembers.map((teamMember, index) => (
            <div key={index} className={`cp-prod-pg__product cp-prod-pg__product--${themeClass}`}>
              <div className="cp-prod-pg__product__left">
                <img
                  src={teamMember?.image ? teamMember.image.url : picture}
                  alt={teamMember.name}
                  className="cp-prod-pg__product__img"
                  onClick={() => setIsViewProductModalOpen(teamMember)}
                />
              </div>
              <div className="cp-prod-pg__product__right">
                <div className="cp-prod-pg__title-section">
                  <p className="cp-prod-pg__product__name">
                    {teamMember.name}
                    {teamMember.startDate}
                  </p>
                  <img
                    className="cp-dets-pg__post__actions__icons"
                    src={OptionsIcon}
                    alt="options icon"
                    onClick={() => setIsOptionsOpen(teamMember)}
                  />
                </div>
                <div style={{ display: "flex", width: "100%", gap: "0.33rem" }} className="cp-prod-pg__link-section">
                  <p className="cp-prod-pg__product__job-title">{teamMember.jobTitle} </p>   
                  <div style={{ display: "flex", gap: "0.33rem" }}>
                    <div
                      className="cp-prod-pg__clipboard"
                      onClick={() => copyToClipboard(teamMember.url)}
                      alt="clipboard icon"
                    >
                      <PortfolioIcon themeClass={themeClass} />
                    </div>
                    <div
                      className="cp-prod-pg__clipboard"
                      onClick={() => setIsConfirmModalOpen(teamMember)}
                      alt="clipboard icon"
                    >
                      <MailIcon themeClass={themeClass} />
                    </div>
                    <div
                      className="cp-prod-pg__clipboard"
                      onClick={() => setIsConfirmAddShop(teamMember)}
                      alt="clipboard icon"
                    >
                      <PhoneIcon themeClass={themeClass} />
                    </div>
                  </div>
                </div>
                <div className="cp-ct-pg__insta__div">
                  <img src={InstagramIcon} alt="instagram icon" 
                      className="cp-prod-pg__product__img" 
                      style={{ width: "20px", height: "20px" }}
                  />
                  <p className="cp-prod-pg__insta__text">{teamMember.instagram} | {teamMember.email}</p>
                </div>
                <div className="cp-prod-pg__link-section">
                  <Link className="cp-prod-pg__product__url" to={teamMember.email} />
                </div>
                <p className="cp-prod-pg__product__sku">
                  {teamMember.description}
                </p>
              </div>
            </div>
          ))}
          {campaignStatus !== "Completed" && campaignStatus !== "Post Campaign" && (
            <Link className={`cp-prod-pg__add-product cp-prod-pg__add-product--${themeClass}`} to={`/campaigns/${id}/teams/add`}>
              <p className="cp-prod-pg__add">+</p>
              <p className="cp-prod-pg__text">Add Team Member</p>
            </Link>
          )}
        </Card>
        {isOptionsOpen && (
          <OptionsModalBox
            isOpen={isOptionsOpen}
            setIsOpen={setIsOptionsOpen}
            handleRemoveTeamMember={() => handleDeleteTeamMember(isOptionsOpen)}
            handleViewTeamMember={() => setIsViewProductModalOpen(isOptionsOpen)}
            handleEditTeamMember={() => setIsEditModalOpen(isOptionsOpen)}
          />
        )}
        {isConfirmAddShop && (
          <ConfirmModal
            isOpen={isConfirmAddShop}
            onClose={() => setIsConfirmAddShop(false)}
            setIsOpen={setIsConfirmAddShop}
            message={"Would you like to add this product to the shop section in your profile"}
            onConfirm={handleAddProductToShop}
          />
        )}
        {isConfirmModalOpen && (
          <ConfirmModal
            isOpen={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
            setIsOpen={setIsConfirmModalOpen}
            message={"Would you like to add this link to your profile"}
            onConfirm={handleAddLinkToProfile}
          />
        )}
        {isEditModalOpen && (
          <EditCampaignTeamModel
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            onSave={handleUpdateTeamMember}
            setIsOpen={setIsEditModalOpen}
          />
        )}
        {isViewProductModalOpen && (
          <ViewCampaignTeamModal
            isOpen={isViewProductModalOpen}
            onClose={() => setIsViewProductModalOpen(false)}
          />
        )}
      </div>
    </UserRoute>
  );
};

export default CampaignTeamsPage;
