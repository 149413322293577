import React, { useContext, useEffect, useState } from "react";
import Search from "../../Components/Search/Search";
import "./FeaturePage.scss";
import UserRoute from "../../Routes/Auth";
import { Link } from "react-router-dom";
import { Context } from "../../context/index";

export default function FeaturePage() {
const categories = [
    { name: "Artist", url: "artist", color: "linear-gradient(328deg, #9B30FF, #BA55D3)" }, // Purple shades for creativity
    { name: "Actor", url: "actor", color: "linear-gradient(328deg, #FFD700, #FFA500)" }, // Gold to Orange for the spotlight
    { name: "Athlete", url: "athlete", color: "linear-gradient(328deg, #32CD32, #00FF7F)" }, // Lime Green for energy and health
    { name: "Automotive", url: "automotive", color: "linear-gradient(328deg, #FFA500, #FF4500)" }, // Orange shades for speed and excitement
    { name: "Beauty", url: "beauty", color: "linear-gradient(328deg, #FF69B4, #FF1493)" }, // Pink shades for femininity and style
    { name: "Content Producer", url: "content_producer", color: "linear-gradient(328deg, #9370DB, #8A2BE2)" }, // Purple shades for creativity
    { name: "Content Creator", url: "content_creator", color: "linear-gradient(328deg, #8B008B, #9400D3)" }, // Dark Magenta for creativity
    { name: "Comedian", url: "comedian", color: "linear-gradient(328deg, #FFD700, #FF4500)" }, // Gold to Orange for humor and entertainment
    { name: "Content Pages", url: "content_pages", color: "linear-gradient(328deg, #00CED1, #20B2AA)" }, // Turquoise shades for calm and creativity
    { name: "Fashion", url: "fashion", color: "linear-gradient(321deg, rgba(193,34,34,1) 44%, rgba(214,8,139,1) 84%)" }, // Pink shades for style and elegance
    { name: "Fitness", url: "fitness", color: "linear-gradient(328deg, #32CD32, #3CB371)" }, // Green shades for health and vitality
    { name: "Food", url: "food", color: "linear-gradient(328deg, #FF4500, #FF6347)" }, // Orange Red for appetite and flavor
    { name: "Gaming", url: "gaming", color: "linear-gradient(328deg, #9400D3, #8A2BE2)" }, // Dark Violet for excitement and energy
    { name: "Health", url: "health", color: "linear-gradient(328deg, #1E90FF, #00BFFF)" }, // Blue shades for trust and tranquility
    { name: "Lifestyle", url: "lifestyle", color: "linear-gradient(328deg, #FF1493, #FF69B4)" }, // Pink shades for variety and vibrancy
    { name: "Live Streaming", url: "live_streaming", color: "linear-gradient(328deg, #4B0082, #6A5ACD)" }, // Indigo for live interaction
    { name: "Music", url: "music", color: "linear-gradient(321deg, rgba(193,34,34,1) 44%, rgba(214,8,139,1) 84%)" }, // Peach shades for creativity and emotion
    { name: "Model", url: "model", color: "linear-gradient(328deg, #FFFF00, #FFD700)" }, // Yellow shades for elegance and visibility
    { name: "Photographer", url: "photographer", color: "linear-gradient(328deg, #228B22, #32CD32)" }, // Green shades for nature and creativity
    { name: "Podcasting", url: "podcasting", color: "linear-gradient(328deg, #FF6347, #FF4500)" }, // Tomato shades for energy and engagement
    { name: "Street Wear", url: "street_wear", color: "linear-gradient(328deg, #808080, #A9A9A9)" }, // Gray shades for urban and modern
    { name: "Tech", url: "tech", color: "linear-gradient(328deg, #00BFFF, #1E90FF)" }, // Blue shades for innovation and trust
    { name: "Travel", url: "travel", color: "linear-gradient(328deg, #ADD8E6, #87CEFA)" }, // Light Blue shades for exploration and freedom
    { name: "Video Editor", url: "video_editor", color: "linear-gradient(328deg, #8FBC8F, #3CB371)" }, // Green shades for creativity and innovation
];


	const [username, setUsername] = useState("");
	const {
		state: { user },
	} = useContext(Context);


	useEffect(() => {
		if (user !== null) {
			setUsername(user.username);
		}
	}, [user]);

	return (
		<UserRoute username={username}>
			<div className="feature-pg">
				<Search />
				<div className="feature-pg__grid">
					{categories.map((category, index) => (
						<Link
							to={`/featured/${category.url}`}
							key={index}
							className="feature-pg__card"
							style={{ background: category.color }}
						>
							<div className="feature-pg__card-image">
								<div className="feature-pg__card-title">
									{category.name}
								</div>
							</div>
						</Link>
					))}
				</div>
			</div>
		</UserRoute>
	);
}
