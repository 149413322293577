// DonationsSettingsPage.jsx

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Card from "../../Components/Card/Card";
import Button from "../../Components/Button/Button";
import { Context } from "../../context/index";
import UserRoute from "../../Routes/Auth";
import Toggle from "../../Components/Toggle/Toggle";
import "./DonationsSettingsPage.scss";
import Navigation from "../../Components/Navigation/Navigation";
import ThemeContext from "../../context/ThemeContext";

const DonationsSettingsPage = () => {
  const [donationEnabled, setDonationEnabled] = useState(false);
  const [customAmountEnabled, setCustomAmountEnabled] = useState(false);
  const [suggestedAmounts, setSuggestedAmounts] = useState([5, 10, 20, 50]);
  const [currency, setCurrency] = useState("USD"); // Default currency
  const { user } = useContext(Context).state;
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

  // Currency symbols mapping
  const currencySymbols = {
    USD: "$",
    CAD: "$",
    EUR: "€",
    GBP: "£",
    JPY: "¥",
    AUD: "$",
    NZD: "$",
    AED: "د.إ",
    INR: "₹",
    KRW: "₩",
    // Add other currencies as needed
  };

  useEffect(() => {
    const fetchDonationSettings = async () => {
      try {
        const { data } = await axios.get("/settings/donations");
        console.log("Donation settings:", data);
        setDonationEnabled(data.donationEnabled);
        setCustomAmountEnabled(data.customAmountEnabled || false);
        setSuggestedAmounts(data.suggestedAmounts || [5, 10, 20, 50]);
        setCurrency(data.defaultCurrency || "USD");
      } catch (error) {
        console.error("Error fetching donation settings:", error);
      }
    };
    fetchDonationSettings();
  }, [user]);

  const handleToggleDonations = async () => {
    try {
      await axios.post("/settings/donations/toggle", {
        donationEnabled: !donationEnabled,
      });
      setDonationEnabled((prevState) => !prevState);
    } catch (error) {
      console.error("Error toggling donations:", error);
    }
  };

  const handleToggleCustomAmount = async () => {
    try {
      await axios.post("/settings/donations/toggle-custom-amount", {
        customAmountEnabled: !customAmountEnabled,
      });
      setCustomAmountEnabled((prevState) => !prevState);
    } catch (error) {
      console.error("Error toggling custom donation amount:", error);
    }
  };

  const handleUpdateSettings = async () => {
    try {
      await axios.post("/settings/donations/update-amounts", {
        suggestedAmounts,
        currency,
      });
      // Optionally, you can display a success message here
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const handleAmountChange = (index, value) => {
    const newAmounts = [...suggestedAmounts];
    newAmounts[index] = Number(value);
    setSuggestedAmounts(newAmounts);
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  return (
    <UserRoute>
      <div className={`donations-settings donations-settings--${themeClass}`}>
        <Navigation heading={"Donations"} back={true} />
        <Card className={`card card--donations card--donations--${themeClass}`}>
          <h2 className={`donations-settings__title ${themeClass}`}>
            <span className="donations-settings__branding">Settings</span>
          </h2>
          <div className={`donations-settings__toggle donations-settings__toggle--${themeClass}`}
          >

            <Toggle
              label="Enable Donations"
              onChange={handleToggleDonations}
              value={donationEnabled}
              modifier={`donations ${themeClass}`}

            />
          </div>
          {donationEnabled && (
            <>
              <div className={`donations-settings__currency ${themeClass}`}>
                <h3
                  className={`donations-settings__subtitle donations-settings__subtitle--${themeClass}`}
                >
                  Currency
                </h3>
                <select
                  className={`donations-settings__currency-select donations-settings__currency-select--${themeClass}`}
                  value={currency}
                  onChange={handleCurrencyChange}
                >
                  <option value="USD">USD - US Dollar</option>
                  <option value="CAD">CAD - Canadian Dollar</option>
                  <option value="EUR">EUR - Euro</option>
                  <option value="GBP">GBP - British Pound</option>
                  <option value="AUD">AUD - Australian Dollar</option>
                  <option value="NZD">NZD - New Zealand Dollar</option>
                  <option value="JPY">JPY - Japanese Yen</option>
                  <option value="AED">AED - United Arab Emirates Dirham</option>
                  <option value="INR">INR - Indian Rupee</option>
                  <option value="KRW">KRW - South Korean Won</option>
                </select>

              </div>
              <div
                className={`donations-settings__amounts ${themeClass}`}
              >
                <h3
                  className={`donations-settings__subtitle donations-settings__subtitle--${themeClass}`}
                >
                  Presets ({currencySymbols[currency] || currency})
                </h3>
                <div
                  className={`donations-settings__amount-wrapper ${themeClass}`}
                >
                  {suggestedAmounts.map((amount, index) => (
                    <div
                      key={index}
                      className="donations-settings__amount-input-wrapper"
                    >
                      <span className={`donations-settings__currency-symbol donations-settings__currency-symbol--${themeClass}`}>
                        {currencySymbols[currency] || currency}
                      </span>
                      <input
                        type="number"
                        min="0"
                        className={`donations-settings__amount-input donations-settings__amount-input--${themeClass}`}
                        value={amount}
                        onChange={(e) =>
                          handleAmountChange(index, e.target.value)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={`donations-settings__toggle donations-settings__toggle--${themeClass}`}
              >
                <Toggle
                  label="Custom Donations"
                  onChange={handleToggleCustomAmount}
                  value={customAmountEnabled}
                  modifier={`donations ${themeClass}`}
                />
              </div>
            </>
          )}
          <Button
            onClick={handleUpdateSettings}
            className={`donations-settings__amount-button donations-settings__amount-button--${themeClass}`}
          >
            Update
          </Button>
        </Card>
      </div>
    </UserRoute>
  );
};

export default DonationsSettingsPage;
