import React, { useState, useEffect } from 'react'
import UserRoute from '../../Routes/Auth'
import Navigation from '../../Components/Navigation/Navigation'
// import CampaignCard from '../../Components/CampaignCard/CampaignCard'
import "./PastCampaignsPage.scss"
import CampaignRequest from '../../Components/CampaignRequest/CampaignRequest'
import axios from 'axios'

export default function PastCampaignsPage() {
    
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {  
        const fetchCampaigns = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/completed`);
                const fetchedCampaigns = response.data?.campaigns || [];
                setCampaigns(fetchedCampaigns);
                // console.log(response.data);

            } catch (error) {
                console.error(error);
            }
        };
        fetchCampaigns();
    }, [])


    return (
        <UserRoute>
            <div className='past-campaigns-pg'>
                <Navigation heading={"Past Campaigns"} subh back={true} edit={"/settings/promotions"} />
                    {campaigns.map((campaign) => (
                        <CampaignRequest
                            key={campaign._id}
                            campaign={campaign}
                            
                            
                        />
                    ))}
                    {/* <CampaignCard
                        title="Fashion Nova: Summer Campaign"
                        productName="Leather Cargo Shorts - Black"
                        sku="#UKU12345"
                        productUrl="https://www.fashionnova.com/products/summer-dress"
                        trackingNumber="TN123456789XZ3HYE2"
                        productStatus="Shipped"
                        affiliateLink="https://www.fashionnova.com/affiliate"
                        affiliateUrl="https://www.fashionnova.com/pages/affiliate-program"
                        marketChannels="Instagram, Facebook, TikTok"
                        currentProgress={100}
                        status="in progress"
                    /> */}
            </div>
        </UserRoute>
    )
}
