
import Card from "../../Components/Card/Card"
import UserLinkCard from '../../Components/UserLinkCard/UserLinkCard'
import "./SettingsPage.scss"
import { Context } from "../../context/index"
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from "../../Components/Navigation/Navigation";

export default function SettingsPage() {
  const [username, setUsername] = useState("");
  const { state: { user }, } = useContext(Context);

  useEffect(() => {
    if (user !== null) {
      setUsername(user.username)
    }

  }, [user]);
  return (
    <UserRoute username={username}>

      <div className='settings-pg'>
        <Navigation heading={"Settings"} username={username} back={true} darkMode={true} navRight={true}  />
        <Card className={"card--ptb"}>
          {/* <UserLinkCard title="Account Details" src="/settings/account" />       */}
          <UserLinkCard title="Edit Profile" src="/settings/general" />
          <UserLinkCard title="Update Email" src="/settings/email" />
          <UserLinkCard title="Fan Donations" src="/settings/donations" />
          <UserLinkCard title={user?.stripeAccountId === "" ? "Connect Payments" : "Creator Monetization"} src="/settings/monetization" />
          {/* <UserLinkCard title="Update Portfolio" src="/settings/update_portfolio" /> */}
          {/* <UserLinkCard title="Manage Promotions" src="/settings/promotions" /> */}


          {/* <UserLinkCard title="Manage Socials" src="/settings/socials" /> */}
          {/* <UserLinkCard title="Integrate Third Party Socials (Coming Soon)" src="/settings/account" /> */}
          <UserLinkCard title="Account Security" src="/settings/password" />
          <UserLinkCard title="App Subscription" src="/settings/subscription" />
          <UserLinkCard title="Teams" src="/settings/team" />

          <UserLinkCard title="Billing" src={process.env.REACT_APP_STRIPE_BILLING} />
          {/* <UserLinkCard title="Terms of Service" src="/termsofservice" /> */}
          {/* <UserLinkCard title="Privacy Policy" src="/privacypolicy" /> */}
          {/* <UserLinkCard title="Support" src="/support" /> */}
          <UserLinkCard title="Logout" src="/logout"  modifier="user-link--hide-td" />
        </Card>
      </div>
    </UserRoute>

  )
}
