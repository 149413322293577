import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./SignUpPage.scss";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context/index";
import Select from "react-select";
import ThemeContext from "../../context/ThemeContext";
import FooterComponent from "../../Components/FooterComponent/FooterComponent";
import SubscriptionPlanCard from "../../Components/SubscriptionPlanCard/SubscriptionPlanCard";
import { loadStripe } from "@stripe/stripe-js";
import PhoneNumberInput from "../../Components/PhoneNumberInput/PhoneNumberInput";
import { isValid } from "date-fns";

const options = [
  { value: "Artist", label: "Artist" },
  { value: "Actor", label: "Actor" },
  { value: "Athlete", label: "Athlete" },
  { value: "Automotive", label: "Automotive" },
  { value: "Beauty", label: "Beauty" },
  { value: "Content Producer", label: "Content Producer" },
  { value: "Content Creator", label: "Content Creator" },
  { value: "Comedian", label: "Comedian" },
  { value: "Content Pages", label: "Content Pages" },
  { value: "Creative", label: "Creative" },
  { value: "Fashion", label: "Fashion" },
  { value: "Fitness", label: "Fitness" },
  { value: "Food", label: "Food" },
  { value: "Gaming", label: "Gaming" },
  { value: "Health", label: "Health" },
  { value: "Lifestyle", label: "Lifestyle" },
  { value: "Live Streaming", label: "Live Streaming" },
  { value: "Music", label: "Music" },
  { value: "Music Production", label: "Music" },
  { value: "Model", label: "Model" },
  { value: "Photographer", label: "Photographer" },
  { value: "Podcasting", label: "Podcasting" },
  { value: "Street Wear", label: "Street Wear" },
  { value: "Tech", label: "Tech" },
  { value: "Travel", label: "Travel" },
  { value: "Writer", label: "Writer" },
  { value: "Copy-Writer", label: "Copy-Writer" },
  { value: "Video Editor", label: "Video Editor" },
];

const styles1 = {
  control: (base) => ({
    ...base,
    backgroundColor: "#141414",
    borderRadius: "8px",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#141414",
    borderRadius: "4px",
  }),
  option: (base, state) => ({
    ...base,
    color: "white",
    backgroundColor: state.isFocused ? "neutral70" : base.backgroundColor,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "dangerLight",
    borderRadius: "4px",
    display: "inline-flex",
    alignItems: "center",
    padding: "2px 6px",
    marginRight: "4px",
    marginTop: "4px",
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "white",
    fontSize: "14px",
    fontWeight: "600",
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: "white",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "none",
      color: "dangerLight",
    },
  }),
  // Adjust the dropdown arrow icon color to darkgrey
  dropdownIndicator: (base) => ({
    ...base,
    color: "black", // Set the dropdown arrow color to darkgrey
    svg: {
      fill: "black", // Make sure the SVG icon also changes to darkgrey
    },
    "&:hover": {
      color: "black", // Ensure the hover state is also darkgrey
      svg: {
        fill: "black", // Ensure the SVG remains darkgrey on hover
      },
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none", // Remove the line between the input and the dropdown icon
  }),
};

const styles = {
  control: (base) => ({
    ...base,
    backgroundColor: "#141414",
    borderRadius: "8px",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#141414",
    borderRadius: "4px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: "neutral70",
    },
  }),
  option: (base, state) => ({
    ...base,
    color: "white",
    backgroundColor: state.isFocused ? "neutral70" : base.backgroundColor,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "black", // Set the dropdown arrow color to darkgrey
    svg: {
      fill: "black", // Make sure the SVG icon also changes to darkgrey
    },
    "&:hover": {
      color: "black", // Ensure the hover state is also darkgrey
      svg: {
        fill: "black", // Ensure the SVG remains darkgrey on hover
      },
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none", // Remove the line between the input and the dropdown icon (optional)
  }),
};

const lightMode = {
  control: (base) => ({
    ...base,
    backgroundColor: "#E1E1E1",
    borderRadius: "8px",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#E1E1E1",
    borderRadius: "4px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: "neutral70",
    },
  }),
  option: (base, state) => ({
    ...base,
    color: "black",
    backgroundColor: state.isFocused ? "neutral70" : "#E1E1E1",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "black",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "black", // Set the dropdown arrow color to darkgrey
    svg: {
      fill: "black", // Make sure the SVG icon also changes to darkgrey
    },
    "&:hover": {
      color: "black", // Ensure the hover state is also darkgrey
      svg: {
        fill: "black", // Ensure the SVG remains darkgrey on hover
      },
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none", // Remove the line between the input and the dropdown icon (optional)
  }),
};

const standardPlan = {
  priceId: "price_1PTwmQHJJv2x3AI28W0JCOoP",
  name: "Standard",
  price: "$29.99",
  duration: "Monthly",
  buttonLabel: "Subscribe",
  features: [
    "Manage Brand Campaigns",
    "Built-in Product Listings",
    "Quick Links to All Your Main Socials",
    "Links to Third-Party Sites",
    "8 Campaigns Per Month",
    "3 Promotions",
    "7.0% Campaign Fee",
  ],
};

const premiumPlan = {
  priceId: "price_1PT7eiHJJv2x3AI2Vw6HYmCM",
  name: "Premium",
  price: "$59.99",
  duration: "Monthly",
  buttonLabel: "Subscribe",
  features: [
    "Manage Brand Campaigns",
    "Built-in Product Listings",
    "Quick Links to All Your Main Socials",
    "Links to Third-Party Sites",
    "Unlimited Campaigns Per Month",
    "Unlimited Promotions",
    "6.0% Campaign Fee",
  ],
};

const userTypeOptions = [
  { value: "creator", label: "Creator" },
  { value: "brand", label: "Brand" },
];

const countryOptions = [
  { value: "US", label: "🇺🇸 United States" },
  { value: "CA", label: "🇨🇦 Canada" },
  { value: "UK", label: "🇬🇧 United Kingdom" },
  { value: "AUS", label: "🇦🇺 Australia" },
  { value: "NZ", label: "🇳🇿 New Zealand" },
  { value: "IN", label: "🇮🇳 India" },
  { value: "KR", label: "🇰🇷 South Korea" },
];

const industryOptions = [
  { value: "Technology", label: "Technology" },
  { value: "Fashion", label: "Fashion" },
  { value: "Food & Beverage", label: "Food & Beverage" },
  { value: "Automotive", label: "Automotive" },
  { value: "Health & Wellness", label: "Health & Wellness" },
  { value: "Finance", label: "Finance" },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Education", label: "Education" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Retail", label: "Retail" },
  { value: "Travel & Tourism", label: "Travel & Tourism" },
];
const companySizeOptions = [
  { value: "1-10", label: "1-10 employees" },
  { value: "11-50", label: "11-50 employees" },
  { value: "51-200", label: "51-200 employees" },
  { value: "201-500", label: "201-500 employees" },
  { value: "500+", label: "500+ employees" },
];

export default function SignUpPage() {
  const [page, setPage] = useState(1);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [userType, setUserType] = useState("creator");
  const [location, setLocation] = useState("");
  const [bio, setBio] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [socialMedia, setSocialMedia] = useState({
    tiktok: "",
    instagram: "",
    youtube: "",
    podcast: "",
  });
  const [categories, setCategories] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [emailError, setEmailError] = useState(""); // For email validation error
  // const [generalError, setGeneralError] = useState(""); // For other general errors
  const navigate = useNavigate();
  const { state } = useContext(Context);
  const { user } = state;
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "";
  const [country, setCountry] = useState("United States");
  const [promo, setPromo] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for showing password

  const togglePasswordVisibility = () => setShowPassword(!showPassword); // Toggle password visibility

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      return false; // Return false for invalid email
    }
    setEmailError(""); // Clear error if the email is valid
    return true; // Return true for valid email
  };

  const handleNextPage = () => {
    if (
      page === 1 &&
      username !== "" &&
      email !== "" &&
      password !== "" &&
      validateEmail(email)
    ) {
      setPage(page + 1);
    } else if (
      page === 2 &&
      (fname !== "" || companyName !== "") &&
      (lname !== "" || industry !== "") &&
      dateOfBirth !== "" &&
      userType !== "" &&
      ((userType === "brand" && companySize !== "") || userType === "creator")
    ) {
      setPage(page + 1);
    } else if (
      page === 3 &&
      location !== "" &&
      bio !== "" &&
      phoneNumber !== "" &&
      categories.length !== 0 &&
      isValid
    ) {
      setPage(page + 1);
    } else if (
      page === 4 &&
      socialMedia.tiktok !== "" &&
      socialMedia.instagram !== ""
    ) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    setPage(page - 1);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    // Map categories to their values
    const categoryValues = categories.map((category) => category.value);

    // Create the user data object
    const userData = {
      email,
      username,
      password,
      dateOfBirth,
      fname,
      lname,
      userType,
      categories: categoryValues,
      location,
      bio,
      socialMedia,
      country,
      phoneNumber,
      promo,
      // Include industry and company size fields for 'brand' user type
      ...(userType === "brand" && {
        companyName,
        industry,
        companySize,
      }),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/signup`,
        userData
      );
      console.log(response);
      navigate(`/login`);
    } catch (error) {
      console.error("Signup error:", error.response?.data || error.message);
    }
  };

  const handleCheckout = async ({ id }) => {
    try {
      if (
        id === "price_1PTwmQHJJv2x3AI28W0JCOoP" ||
        id === "price_1PT7eiHJJv2x3AI2Vw6HYmCM"
      ) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API}/user/edit-profile/subscribe/${id}`,
          {}
        );
        console.log(data);
        const stripe = await loadStripe(
          process.env.REACT_APP_PUBLIC_STRIPE_KEY
        );
        stripe.redirectToCheckout({ sessionId: data.id });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user !== null) navigate("/");
  }, [user, navigate]);

  const handleCategoryChange = (selectedOptions) => {
    setCategories(selectedOptions);
  };

  const handleSocialMediaChange = (e) => {
    setSocialMedia({ ...socialMedia, [e.target.name]: e.target.value });
  };

  const BackArrowIcon = () => {
    const color = themeMode === "light" ? "#e1e1e1" : "#222222";

    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
          fill={color}
        />
      </svg>
    );
  };

  useEffect(() => {}, [categories]);

  const renderPage1 = () => {
    return (
      <div className={`sign-up__forms sign-up__forms--${themeClass}`}>
        <div className="signup-form__country-container">
          <label className="signup-form__country-label" htmlFor="country">
            Country
          </label>
          <Select
            className={`signup-form__country-select signup-form__country-select--${themeClass}`}
            options={countryOptions}
            value={{ value: country, label: country }}
            onChange={(selectedOption) => setCountry(selectedOption.value)}
            styles={themeMode === "light" ? lightMode : styles1}
          />
        </div>
        <div className="signup-form__username-container">
          <label className="signup-form__username-label" htmlFor="username">
            Username
          </label>
          <input
            className={`signup-form__username-input signup-form__username-input--${themeClass}`}
            name="username"
            placeholder="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="signup-form__email-container">
          <label className="signup-form__email-label" htmlFor="email">
            Email
          </label>
          <input
            className={`signup-form__email-input signup-form__email-input--${themeClass}`}
            name="email"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && (
            <div className="error-message">{emailError}</div> // Display email error
          )}
        </div>
        <div className="signup-form__password-container">
          <label className="signup-form__password-label" htmlFor="password">
            Password
          </label>
          <div className="password-input-wrapper">
            <input
              className={`signup-form__password-input signup-form__password-input--${themeClass}`}
              name="password"
              placeholder="Password"
              type={showPassword ? "text" : "password"} // Toggle type between "password" and "text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              className="password-toggle"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? "Hide" : "Show"} {/* Text inside the toggle */}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderPage2 = () => {
    return (
      <div className="sign-up__forms">
        <div onClick={handlePrevPage} className="signup-form__backArrow">
          <BackArrowIcon />
        </div>

        <div className="signup-form__type-container">
          <label className="signup-form__type-label" htmlFor="type">
            Account Type
          </label>
          <Select
            className={`signup-form__user-select signup-form__user-select--${themeClass}`}
            options={userTypeOptions}
            value={{
              value: userType,
              label: userType.charAt(0).toUpperCase() + userType.slice(1),
            }}
            onChange={(selectedOption) => setUserType(selectedOption.value)}
            styles={themeMode === "light" ? lightMode : styles}
          />
        </div>

        {userType === "creator" ? (
          <>
            <div className="signup-form__fname-container">
              <label className="signup-form__fname-label" htmlFor="fname">
                First Name
              </label>
              <input
                className={`signup-form__fname-input signup-form__fname-input--${themeClass}`}
                name="fname"
                placeholder="First Name"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
              />
            </div>
            <div className="signup-form__lname-container">
              <label className="signup-form__lname-label" htmlFor="lname">
                Last Name
              </label>
              <input
                className={`signup-form__lname-input signup-form__lname-input--${themeClass}`}
                name="lname"
                placeholder="Last Name"
                value={lname}
                onChange={(e) => setLname(e.target.value)}
              />
            </div>
            <div className="signup-form__dob-container">
              <label className="signup-form__dob-label" htmlFor="dob">
                Date of Birth
              </label>
              <input
                className={`signup-form__dob-input signup-form__dob-input--${themeClass}`}
                name="dob"
                placeholder="Date of Birth"
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>
          </>
        ) : userType === "brand" ? (
          <>
            <div className="signup-form__company-container">
              <label
                className="signup-form__company-label"
                htmlFor="companyName"
              >
                Company Name
              </label>
              <input
                className={`signup-form__company-input signup-form__company-input--${themeClass}`}
                name="companyName"
                placeholder="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="signup-form__dob-container">
              <label className="signup-form__dob-label" htmlFor="dob">
                Date Company Est.
              </label>
              <input
                className={`signup-form__dob-input signup-form__dob-input--${themeClass}`}
                name="dob"
                placeholder="Established Date"
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>
            <div className="signup-form__industry-container">
              <label className="signup-form__industry-label" htmlFor="industry">
                Industry
              </label>
              <Select
                className={`signup-form__industry-select signup-form__industry-select--${themeClass}`}
                options={industryOptions}
                value={{ value: industry, label: industry }}
                onChange={(selectedOption) => setIndustry(selectedOption.value)}
                styles={themeMode === "light" ? lightMode : styles}
              />
            </div>
            <div className="signup-form__company-size-container">
              <label
                className="signup-form__company-size-label"
                htmlFor="companySize"
              >
                Company Size
              </label>
              <Select
                className={`signup-form__company-size-select signup-form__company-size-select--${themeClass}`}
                options={companySizeOptions}
                value={{ value: companySize, label: companySize }}
                onChange={(selectedOption) =>
                  setCompanySize(selectedOption.value)
                }
                styles={themeMode === "light" ? lightMode : styles}
              />
            </div>
          </>
        ) : null}
      </div>
    );
  };

  const renderPage3 = () => {
    return (
      <div className="sign-up__forms">
        <div onClick={handlePrevPage} className="signup-form__backArrow">
          <BackArrowIcon />
        </div>
        <div className="signup-form__phoneNumber-container">
          <label
            className="signup-form__phoneNumber-label"
            htmlFor="phoneNumber"
          >
            Phone Number
          </label>
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setIsPhoneNumberValid={setIsPhoneNumberValid}
            isValid={isPhoneNumberValid}
            modifer={`signup-form__phoneNumber-input signup-form__phoneNumber-input--${themeClass}`}
            themeClass={themeClass}
          />
        </div>
        <div className="signup-form__categories-container">
          <label className="signup-form__categories-label" htmlFor="categories">
            Categories
          </label>
          <Select
            className={`signup-form__categories-select signup-form__categories-select--${themeClass}`}
            id="categories"
            options={options}
            isMulti={true}
            onChange={handleCategoryChange}
            value={categories}
            styles={themeMode === "light" ? lightMode : styles1}
          />
        </div>
        <div className="signup-form__location-container">
          <label className="signup-form__location-label" htmlFor="location">
            Location
          </label>
          <input
            className={`signup-form__location-input signup-form__location-input--${themeClass}`}
            name="location"
            placeholder="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div className="signup-form__bio-container">
          <label className="signup-form__bio-label" htmlFor="bio">
            Bio
          </label>
          <textarea
            className={`signup-form__bio-input signup-form__bio-input--${themeClass}`}
            name="bio"
            placeholder="Bio"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
          />
        </div>
      </div>
    );
  };

  const renderPage4 = () => {
    return (
      <div className="sign-up__forms">
        <div onClick={handlePrevPage} className="signup-form__backArrow">
          <BackArrowIcon />
        </div>
        <div className="signup-form__tiktok-container">
          <label className="signup-form__tiktok-label" htmlFor="tiktok">
            TikTok
          </label>
          <input
            className={`signup-form__tiktok-input signup-form__tiktok-input--${themeClass}`}
            name="tiktok"
            placeholder="www.tiktok.com/@username"
            value={socialMedia.tiktok}
            onChange={handleSocialMediaChange}
          />
        </div>
        <div className="signup-form__instagram-container">
          <label className="signup-form__instagram-label" htmlFor="instagram">
            Instagram
          </label>
          <input
            className={`signup-form__instagram-input signup-form__instagram-input--${themeClass}`}
            name="instagram"
            placeholder="www.instagram.com/username"
            value={socialMedia.instagram}
            onChange={handleSocialMediaChange}
          />
        </div>
        <div className="signup-form__youtube-container">
          <label className="signup-form__youtube-label" htmlFor="youtube">
            YouTube
          </label>
          <input
            className={`signup-form__youtube-input signup-form__youtube-input--${themeClass}`}
            name="youtube"
            placeholder="www.youtube.com/channel/username"
            value={socialMedia.youtube}
            onChange={handleSocialMediaChange}
          />
        </div>
        {categories.some((category) => category.value === "Artist") ? (
          <div className="signup-form__podcast-container">
            <label className="signup-form__podcast-label" htmlFor="podcast">
              Spotify Artist ID
            </label>
            <input
              className={`signup-form__podcast-input signup-form__podcast-input--${themeClass}`}
              name="podcast"
              placeholder="Spotify Artist ID"
              value={socialMedia.podcast}
              onChange={handleSocialMediaChange}
            />
          </div>
        ) : categories.some(
            (category) => category.value === "Live Streaming"
          ) ? (
          <div className="signup-form__livestream-container">
            <label
              className="signup-form__livestream-label"
              htmlFor="livestream"
            >
              Twitch
            </label>
            <input
              className={`signup-form__livestream-input signup-form__livestream-input--${themeClass}`}
              name="livestream"
              placeholder="Twitch URL"
              value={socialMedia.livestream}
              onChange={handleSocialMediaChange}
            />
          </div>
        ) : (
          <div className="signup-form__podcast-container">
            <label className="signup-form__podcast-label" htmlFor="podcast">
              Spotify Podcast ID
            </label>
            <input
              className={`signup-form__podcast-input signup-form__podcast-input--${themeClass}`}
              name="podcast"
              placeholder="Spotify Podcast ID"
              value={socialMedia.podcast}
              onChange={handleSocialMediaChange}
            />
          </div>
        )}
      </div>
    );
  };

  const renderPage5 = () => {
    return (
      <div className="">
        <div onClick={handlePrevPage} className="signup-form__backArrow">
          <BackArrowIcon />
        </div>
        <div className="landing-pg__subscription-div">
          <SubscriptionPlanCard
            priceId={standardPlan.priceId}
            name={standardPlan.name}
            price={standardPlan.price}
            duration={standardPlan.duration}
            buttonLabel={standardPlan.buttonLabel}
            handleCheckout={handleCheckout}
            features={standardPlan.features}
          />
          <SubscriptionPlanCard
            priceId={premiumPlan.priceId}
            name={premiumPlan.name}
            price={premiumPlan.price}
            duration={premiumPlan.duration}
            buttonLabel={premiumPlan.buttonLabel}
            handleCheckout={handleCheckout}
            features={premiumPlan.features}
          />
        </div>
        <div className="signup-form__promo-container">
          <label className="signup-form__promo-label" htmlFor="promo">
            Registration Code
          </label>
          <input
            className={`signup-form__promo-input signup-form__promo-input--${themeClass}`}
            name="promo"
            placeholder="Promo Code"
            value={promo}
            onChange={(e) => setPromo(e.target.value)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`signup-form__container signup-form__container--${themeClass}`}
      >
        <Link to={"/login"}>
          <h1 className="signup-form__header">Chequeai</h1>
        </Link>
        <div className="signup-form">
          {page === 1 && renderPage1()}
          {page === 2 && renderPage2()}
          {page === 3 && renderPage3()}
          {page === 4 && renderPage4()}
          {page === 5 && renderPage5()}

          {page < 4 && (
            <button
              className={`signup-form__btn-next signup-form__btn-next--${themeClass}`}
              onClick={() => handleNextPage()}
            >
              Next
            </button>
          )}
          {page === 4 && (
            <div className="signup-form__btn-container">
              {/* Terms of Service Agreement */}
              <div className="terms-container">
                <label htmlFor="terms" className="terms__label">
                  <Link to="/terms-of-service" className="terms-link">
                    By signing up, you agree to our Terms of Service, Privacy
                    policy, and Cookies Policy.
                  </Link>
                </label>
              </div>
              {/* Sign Up Button */}
              <button
                className={`signup-form__btn-submit signup-form__btn-submit--${themeClass}`}
                onClick={handleSignUp}
                disabled={
                  socialMedia.tiktok === "" || socialMedia.instagram === "" // Include terms agreement in validation
                }
                aria-disabled={
                  socialMedia.tiktok === "" || socialMedia.instagram === ""
                } // Add accessibility
              >
                Sign Up
              </button>
            </div>
          )}
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
