import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './MessageModal.scss';
import ThemeContext from '../../context/ThemeContext';

const MessageModal = ({ isOpen, setIsOpen, message, subMessage, ctaMessage, ctaLink, imgSrc }) => {
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const handleClose = () => {
        setIsOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="message-modal">
            <div className="message-modal__overlay" onClick={handleClose} />
            <div className={`message-modal__content message-modal__content--${themeClass}`}>
                <div className="message-modal__container">
                    <div className={`message-modal__header message-modal__header--${themeClass}`}>
                        <p className={`message-modal__header-text message-modal__header-text--${themeClass}`}>{message}</p>
                    </div>
                    {subMessage && (
                        <div className={`message-modal__subheader message-modal__subheader--${themeClass}`}>
                            <p className={`message-modal__subheader-text message-modal__subheader-text--${themeClass}`}>{subMessage}</p>
                        </div>
                    )}
                    {imgSrc && (
                        <div className="message-modal__image-container">
                            <img className="message-modal__image" src={imgSrc} alt="Modal visual" />
                        </div>
                    )}
                    {ctaMessage && (
                        <div className={`message-modal__cta message-modal__cta--${themeClass}`}>
                            <p className={`message-modal__cta-text message-modal__cta-text--${themeClass}`}>{ctaMessage}</p>
                            {ctaLink && (
                                <Link to={ctaLink} className={`message-modal__cta-button message-modal__cta-button--${themeClass}`}>
                                    Get Started
                                </Link>
                            )}
                        </div>
                    )}
                    <div className={`message-modal__footer message-modal__footer--${themeClass}`}>
                        <button
                            className={`message-modal__button message-modal__button--${themeClass}`}
                            onClick={handleClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageModal;
