import React, { useContext } from 'react';
import axios from 'axios';
import facebookIcon from '../../assets/icons/instagram.png'; // Update with the actual path to your icon
import tiktokIcon from '../../assets/icons/tiktok.png'; // Update with the actual path to your icon
import './FacebookTikTokConnect.scss';
import ThemeContext from '../../context/ThemeContext';


const FacebookTikTokConnect = ({ setProvider }) => {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';


  const handleFacebookConnect = async () => {
    try {
      setProvider('facebook'); // Update provider state before making the request
      const response = await axios.get(`${process.env.REACT_APP_API}/auth/facebook`, { withCredentials: true });
      if (response.data && response.data.url) {
        window.location.href = response.data.url; // Redirect to the Facebook auth URL
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response from server:', error.response);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
    }
  };

  // const handleTiktokConnect = async () => {
  //   try {
  //     setProvider('tiktok'); // Update provider state before making the request
  //     const response = await axios.get(`${process.env.REACT_APP_API}/auth/tiktok`, { withCredentials: true });
  //     if (response.data && response.data.url) {
  //       window.location.href = response.data.url; // Redirect to the TikTok auth URL
  //     } else {
  //       console.error('Unexpected response format:', response);
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       console.error('Error response from server:', error.response);
  //     } else if (error.request) {
  //       console.error('No response received:', error.request);
  //     } else {
  //       console.error('Error setting up request:', error.message);
  //     }
  //   }
  // };

  return (
    <div className="connect-buttons-container">
      <button className={`connect-button connect-button--${themeClass}`} onClick={handleFacebookConnect}>
        <img src={facebookIcon} alt="Facebook" className="connect-icon" />
        Connect Instagram
      </button>
      <button className={`connect-button connect-button--${themeClass}`}  onClick={""}>
        <img src={tiktokIcon} alt="TikTok" className="connect-icon" />
        Connect TikTok
      </button>
    </div>
  );
};

export default FacebookTikTokConnect;
