import axios from 'axios';

// Set the base URL for Axios
axios.defaults.baseURL = process.env.REACT_APP_API || "http://localhost:8080/api";

// Set default headers
axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000; // 30 seconds

// Fetch and cache CSRF Token for reuse
let csrfToken = null;

const setAuthHeader = () => {
  const token = window.localStorage.getItem('token');
  if (token && token !== "null") {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

// Fetch CSRF Token and cache it
const fetchCsrfToken = async () => {
  if (!csrfToken) {
    try {
      const response = await axios.get("/csrf-token");
      if (response?.data?.csrf) {
        csrfToken = response.data.csrf;
        axios.defaults.headers.common["x-csrf-token"] = csrfToken;
      } else {
        console.error("No CSRF token received");
      }
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
    }
  }
};

// Specific API request functions using axios methods
export const apiGet = async (url, params = {}) => {
  setAuthHeader();
  await fetchCsrfToken(); // Fetch CSRF token only once per session
  try {
    const response = await axios.get(url, { params });
    return response.data;
  } catch (error) {
    console.error("API GET Request Error:", error);
    throw error;
  }
};

export const apiPost = async (url, data) => {
  setAuthHeader();
  await fetchCsrfToken();
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error("API POST Request Error:", error);
    throw error;
  }
};

export const apiPut = async (url, data) => {
  setAuthHeader();
  await fetchCsrfToken();
  try {
    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    console.error("API PUT Request Error:", error);
    throw error;
  }
};

export const apiDelete = async (url) => {
  setAuthHeader();
  await fetchCsrfToken();
  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    console.error("API DELETE Request Error:", error);
    throw error;
  }
};
