import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './step.scss';

function PersonalInfoStep({ data, onChange, onNext, themeMode }) {
  const [firstName, setFirstName] = useState(data.firstName || '');
  const [lastName, setLastName] = useState(data.lastName || '');
  const [dateOfBirth, setDateOfBirth] = useState(data.dateOfBirth || '');
  const [country, setCountry] = useState(data.country || ''); // Added country state
  const [errors, setErrors] = useState({});

  const usernameRef = useRef(null);

  useEffect(() => {
    if (usernameRef.current) {
      usernameRef.current.focus();
    }
  }, []);

  const validate = () => {
    const newErrors = {};
    if (!firstName.trim()) newErrors.firstName = 'First name is required';
    if (!lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!country.trim()) newErrors.country = 'Country is required'; // Validate country selection
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (validate()) {
      onChange({ firstName, lastName, dateOfBirth, country });
      onNext();
    }
  };

  return (
    <div className={`step ${themeMode}`}>
      <h2>Account Information</h2>
      <form>
        <div className="form-group">
          <label htmlFor="firstName">First Name *</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            placeholder="Enter your first name"
            aria-describedby="firstName-error"
          />
          {errors.firstName && (
            <span className="error-message" id="firstName-error" role="alert">
              {errors.firstName}
            </span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name *</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            placeholder="Enter your last name"
            aria-describedby="lastName-error"
          />
          {errors.lastName && (
            <span className="error-message" id="lastName-error" role="alert">
              {errors.lastName}
            </span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="dateOfBirth">Date of Birth</label>
          <input
            type="date"
            id="dateOfBirth"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            aria-describedby="dateOfBirth-error"
          />
        </div>

        {/* Country Selection */}
        <div className="form-group">
          <label htmlFor="country">Country *</label>
          <select
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
            aria-describedby="country-error"
          >
            <option value="" disabled>Select your country</option>
            <option value="USA">United States</option>
            <option value="CA">Canada</option>
            <option value="UK">United Kingdom</option>
          </select>
          {errors.country && (
            <span className="error-message" id="country-error" role="alert">
              {errors.country}
            </span>
          )}
        </div>

        <div className="navigation-buttons">
          <button type="button" onClick={handleNextClick} className="next-button">
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

PersonalInfoStep.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default PersonalInfoStep;
