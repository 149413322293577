// src/pages/TermsOfService/TermsOfService.jsx

import { Context } from "../../context/index";
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import Card from '../../Components/Card/Card';
import './TermsOfService.scss';
import Navigation from "../../Components/Navigation/Navigation";
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  const [username, setUsername] = useState("");
  const { state: { user } } = useContext(Context);

  useEffect(() => {
    if (user !== null) {
      setUsername(user.username);
    }
  }, [user]);

  function TermsPage() {
    return (
      <div className='terms-pg'>
        <Navigation heading={"Terms of Service"} back={true} />
        <Card className="card--padding">
          <h1>Terms of Service</h1>
          <p><strong>Last updated:</strong> June 29, 2024</p>

          <h2>1. Introduction</h2>
          <p>Welcome to Chequeai! These Terms of Service (the "Terms") govern your use of our app and services (the "Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Services.</p>

          <h2>2. Definitions</h2>
          <p><strong>"User"</strong> refers to any individual or entity that accesses or uses the Services.</p>
          <p><strong>"Content"</strong> refers to any text, images, videos, or other materials uploaded or shared by Users through the Services.</p>
          <p><strong>"Brand"</strong> refers to any company or individual using Chequeai's Services to manage brand deals and payments.</p>

          <h2>3. Account Registration and Security</h2>
          <p>To access certain features of our Services, you must register for an account. When registering, you agree to provide accurate, current, and complete information and to update this information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>

          <h2>4. Age Requirement</h2>
          <p>You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you are at least 18 years old.</p>

          <h2>5. User Conduct and Obligations</h2>
          <p>By using our Services, you agree to abide by the following conduct standards. You shall not:</p>
          <ul>
            <li>Violate any applicable local, state, national, or international laws or regulations.</li>
            <li>Engage in any fraudulent, deceptive, or misleading practices.</li>
            <li>Post, share, or transmit any content that is unlawful, harmful, threatening, defamatory, obscene, or otherwise objectionable.</li>
            <li>Infringe on the intellectual property rights, privacy rights, or other legal rights of others.</li>
            <li>Use the Services to distribute spam, unsolicited communications, or other forms of mass messaging.</li>
            <li>Interfere with or disrupt the operation of the Services or the servers or networks connected to the Services.</li>
            <li>Use the Services to harvest, collect, or gather user data without their consent.</li>
            <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
          </ul>

          <h2>6. Payments and Financial Transactions</h2>
          <p>All payments and transactions made through our Services are processed using Stripe, a secure payment gateway. You agree to provide accurate and complete payment information. We are not responsible for any loss or damage resulting from inaccurate or incomplete payment information. All fees and charges are non-refundable except as expressly stated in these Terms or as required by applicable law.</p>

          <h2>7. Intellectual Property Rights</h2>
          <p>All content and materials available on our Services, including text, graphics, logos, and software, are the property of Chequeai or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, modify, or distribute any content or materials from our Services without our prior written permission.</p>

          <h2>8. User-Generated Content</h2>
          <p>As a user of our Services, you retain ownership of any content you create and share. By sharing content on our platform, you grant us a non-exclusive, royalty-free, worldwide license to use, display, reproduce, modify, and distribute your content for the purpose of operating and promoting our Services. You represent and warrant that you have the necessary rights to grant this license and that your content does not violate the rights of any third party. Additionally, for the duration of a campaign, you grant the participating Brand the right to use your likeness and content created for the campaign. All materials produced for the campaign will be co-owned by you and the Brand.</p>

          <h2>9. Brand Rights and Obligations</h2>
          <p>Brands using our Services agree to respect the rights of content creators. Brands are granted a limited, non-exclusive license to use the content creator's likeness and materials for the duration of the campaign. Brands must comply with all applicable laws and regulations and must not engage in any fraudulent, deceptive, or misleading practices.</p>

          <h2>10. Privacy and Data Protection</h2>
          <p>We are committed to protecting your privacy and personal information. Our <Link to="/privacy-policy">Privacy Policy</Link> explains how we collect, use, and share your information. By using our Services, you agree to our privacy practices as described in our Privacy Policy.</p>

          <h2>11. Cookies and Tracking Technologies</h2>
          <p>We use cookies and other tracking technologies to enhance your experience on our platform. Cookies help us understand how you interact with our Services, personalize your experience, and improve our offerings. By using our Services, you consent to the use of cookies and tracking technologies as described in our <Link to="/privacy-policy">Privacy Policy</Link>. You can manage your cookie preferences through your browser settings.</p>

          <h2>12. Termination and Suspension of Services</h2>
          <p>We reserve the right to terminate or suspend your account and access to our Services at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties. Upon termination, your right to use the Services will immediately cease. All provisions of these Terms that by their nature should survive termination shall survive, including ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>

          <h2>13. Disclaimers and Limitation of Liability</h2>
          <p>Our Services are provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the availability, reliability, or accuracy of our Services. To the maximum extent permitted by law, we disclaim all liability for any damages or losses arising from your use of our Services, including but not limited to direct, indirect, incidental, punitive, and consequential damages. In no event shall our total liability to you for all damages, losses, and causes of action exceed the amount you have paid to us in the last six (6) months, or one hundred US dollars ($100), whichever is greater.</p>

          <h2>14. Indemnification</h2>
          <p>You agree to indemnify, defend, and hold harmless Chequeai and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of our Services, your violation of these Terms, or your violation of any rights of another. This indemnification obligation will survive the termination of your account and these Terms.</p>

          <h2>15. Governing Law and Jurisdiction</h2>
          <p>These Terms and any disputes arising out of or related to these Terms or our Services will be governed by and construed in accordance with the laws of the jurisdiction in which Chequeai is headquartered, without regard to its conflict of law principles. You agree to submit to the personal jurisdiction of the courts located in that jurisdiction for the purpose of litigating all such claims or disputes.</p>

          <h2>16. Dispute Resolution and Arbitration</h2>
          <p>Any disputes arising out of or related to these Terms or our Services will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. You agree to waive your right to a jury trial and to participate in a class action lawsuit or class-wide arbitration. The arbitration will be conducted in the jurisdiction in which Chequeai is headquartered, and the arbitrator's decision will be binding and may be entered as a judgment in any court of competent jurisdiction.</p>

          <h2>17. Changes to Terms and Services</h2>
          <p>We may update these Terms from time to time. If we make material changes, we will notify you by email or by posting a notice on our Services. Your continued use of our Services after any changes to these Terms constitutes your acceptance of the updated Terms. We reserve the right to modify or discontinue our Services at any time without notice.</p>

          <h2>18. Severability</h2>
          <p>If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable to the fullest extent permitted by law. The invalid or unenforceable provision will be deemed modified to the minimum extent necessary to make it valid and enforceable.</p>

          <h2>19. Entire Agreement</h2>
          <p>These Terms constitute the entire agreement between you and Chequeai regarding your use of our Services and supersede all prior agreements and understandings, whether written or oral, regarding the subject matter. Any waiver of any provision of these Terms will be effective only if in writing and signed by a duly authorized representative of Chequeai.</p>
          
          <h2>20. Language</h2>
          <p>These Terms of Service and all related documents are written in English. Any translations provided are for convenience only. In the event of any conflict between the English version and a translated version, the English version will prevail.</p>

          <h2>21. User Responsibilities</h2>
          <p>Users are responsible for ensuring that any content they post, upload, or otherwise make available through the Services is accurate, complete, and complies with all applicable laws and regulations.</p>

          <h2>22. Content Monitoring</h2>
          <p>We reserve the right to monitor and review user content and activities on our Services to ensure compliance with these Terms. We may remove or disable access to any content that violates these Terms or is otherwise objectionable.</p>

          <h2>23. Third-Party Services</h2>
          <p>Our Services may contain links to third-party websites or services that are not owned or controlled by Chequeai. We are not responsible for the content, privacy policies, or practices of any third-party websites or services.</p>

          <h2>24. Assignment</h2>
          <p>You may not assign or transfer any of your rights or obligations under these Terms without our prior written consent. We may assign or transfer our rights and obligations under these Terms without restriction.</p>

          <h2>25. Force Majeure</h2>
          <p>We will not be liable for any delay or failure to perform any obligation under these Terms if the delay or failure is due to events or circumstances beyond our reasonable control, including but not limited to acts of God, natural disasters, war, terrorism, or civil unrest.</p>

          <h2>26. Notice</h2>
          <p>All notices, requests, or other communications required or permitted to be given under these Terms will be in writing and will be deemed to have been duly given when delivered by hand, email, or mailed by certified or registered mail, return receipt requested, with postage prepaid.</p>

          <h2>27. Compliance with Export Laws</h2>
          <p>You agree to comply with all applicable export and re-export control laws and regulations, including but not limited to the Export Administration Regulations ("EAR") maintained by the U.S. Department of Commerce and trade and economic sanctions maintained by the Treasury Department's Office of Foreign Assets Control ("OFAC").</p>

          <h2>28. Feedback</h2>
          <p>Any feedback, comments, or suggestions you may provide regarding Chequeai, or the Services is entirely voluntary and we will be free to use such feedback, comments, or suggestions as we see fit and without any obligation to you.</p>

          <h2>29. Advertisements</h2>
          <p>Our Services may display advertisements, some of which may be tailored to your interests. By using our Services, you agree that we may display advertisements in connection with your use of the Services.</p>

          <h2>30. Waiver</h2>
          <p>The failure of Chequeai to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Chequeai.</p>

          <h2>31. Data Retention</h2>
          <p>We will retain your information for as long as your account is active or as needed to provide you Services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

          <h2>32. Contact Information</h2>
          <p>If you have any questions or concerns about these Terms, please contact us at <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>

          {/* New Sections Start Here */}

          <h2>33. Brand Responsibilities</h2>
          <p>Brands using Chequeai's Services are responsible for vetting Creators to ensure they do not engage in spam, fraud, or any other deceptive practices. Brands must conduct due diligence to verify the authenticity and reliability of Creators before entering into any agreements or partnerships. Failure to properly vet Creators may result in reputational damage, for which Chequeai will not be held liable.</p>

          <h2>34. Creator Responsibilities</h2>
          <p>Creators using Chequeai's Services are responsible for maintaining their brand reputation and online presence. Creators must ensure that their content is genuine, free from fraudulent activities, and does not infringe upon the rights of others. Additionally, Creators are expected to handle all deal processes professionally, ensuring that all agreed-upon work is completed to the satisfaction of the Brand. Chequeai is not liable for any incomplete or substandard work performed by Creators.</p>

          <h2>35. Limitation of Liability for Incomplete Work</h2>
          <p>Chequeai acts as an intermediary platform facilitating connections between Brands and Creators. While we strive to ensure the integrity and reliability of our Services, Chequeai is not responsible for the performance, conduct, or contractual obligations of Brands or Creators. In the event of incomplete work or any disputes arising from partnerships facilitated through our platform, Chequeai shall not be held liable. Users are encouraged to establish clear terms and agreements with each other to mitigate potential risks.</p>

          {/* Additional New Sections */}

          <h2>36. Right to Remove Users</h2>
          <p>Chequeai reserves the right to remove or suspend any User from the platform at our sole discretion, without prior notice or explanation. This includes, but is not limited to, Users who violate these Terms, engage in prohibited conduct, or pose a risk to the safety and integrity of the platform. Such removal does not constitute a breach of contract or liability on the part of Chequeai.</p>

          <h2>37. Copyright Protection and Intellectual Property</h2>
          <p>All materials, content, and features available on Chequeai's platform, including but not limited to text, graphics, logos, images, audio clips, video clips, data compilations, and software, are the property of Chequeai or its licensors and are protected by United States and international copyright laws. Unauthorized use, reproduction, modification, distribution, transmission, republication, display, or performance of any of the content on the platform is strictly prohibited.</p>
          <p>You may not obtain ownership rights to any content or materials viewed on the platform. Any use of such content or materials without Chequeai's express written permission is strictly prohibited and may violate copyright, trademark, and other laws.</p>
          <p>If you believe that your work has been copied in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information:</p>
          <ul>
            <li>A physical or electronic signature of the person authorized to act on behalf of the owner of the copyright interest;</li>
            <li>A description of the copyrighted work that you claim has been infringed;</li>
            <li>A description of where the material that you claim is infringing is located on the platform;</li>
            <li>Your address, telephone number, and email address;</li>
            <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
            <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
          </ul>
          <p>Our Copyright Agent for notice of claims of copyright infringement can be reached as follows:</p>
          <p><strong>Chequeai Copyright Agent</strong><br/>
          Email: <a href="mailto:support@chequeai.com">support@chequeai.com</a></p>

        </Card>
      </div>
    )
  }

  if (user === null) {
    return (
      TermsPage()
    );
  }

  return (
    <UserRoute username={username}>
      {TermsPage()}
    </UserRoute>
  );
};

export default TermsOfService;
