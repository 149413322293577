import React from 'react';
import './PaymentCardComponent.scss'; // Import SCSS for styling
import { Tooltip } from 'react-tooltip'; // Import react-tooltip

const PaymentCardComponent = ({ payment, themeClass }) => {

    const formattedAmount = `$${(payment.amount / 100).toFixed(2)} ${payment.currency.toUpperCase()}`;

    const isMobile = window.innerWidth <= 768; // Detect mobile based on screen width
    const monthFormat = isMobile ? 'short' : 'long';

    const formatDate = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleDateString('en-US', { 
            month: monthFormat, 
            day: 'numeric', 
            year: 'numeric' 
        });
    };

    const createdDate = formatDate(payment.created);    

    return (
        <div className={`payment-card payment-card--${themeClass}`}>
            <div className="payment-header">
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <span className={`status ${payment.status}`}>{payment.status.toUpperCase()}</span>
                </div>

                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <h2 className="amount">{formattedAmount}</h2>
                </div>
            </div>
            <div className={`details details--${themeClass}`}>
                <div className="left-details">
                    <div className="detail-item">
                        <span className={`label label--${themeClass}`}>Initiated</span>
                        <span className={`value value--${themeClass}`}>{createdDate}</span>
                    </div>
                    <div className="detail-item" data-tooltip-id={`tooltip-${payment.id}`} data-tooltip-content={`Payment ID: ${payment.id}`}>
                        <span className={`label label--${themeClass}`} >
                            ID
                        </span>
                        <span className={`value value--${themeClass}`}>{payment.id || 'N/A'}</span>
                        <Tooltip className='payment-id-tooltip' id={`tooltip-${payment.id}`} place="top" effect="solid" />
                    </div>
                </div>
                <div className="right-details">
                    <div className="detail-item">
                        <span className={`label label--${themeClass}`}>Net Amount</span>
                        <span className={`value value--${themeClass}`}>${(payment.balance_transaction.net / 100).toFixed(2)} {payment.currency.toUpperCase()}</span>
                    </div>
                    <div className="detail-item">
                        <span className={`label label--${themeClass} platform-fee-mobile`}>Platform Fee</span>
                        <span className={`label label--${themeClass} platform-fee-tablet`}>Platform Transaction Fee</span>
                        <span className={`value value--${themeClass}`}>
                            {`$${(payment.balance_transaction.fee / 100).toLocaleString()} ${payment.currency.toUpperCase()}` || 'N/A'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentCardComponent;
