import React, { useState, useContext } from 'react';
import './ImageModal.scss';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { Link } from 'react-router-dom';
import Options from '../../assets/icons/three-dots.svg';
import ThemeContext from '../../context/ThemeContext';

const ImageModal = ({ imageUrl, onClose, type, username = null, displayPicture = null, user, handleDelete = null }) => {
  const [showOptions, setShowOptions] = useState(false);
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme'
  const modifierClass = imageUrl.includes('mp4') ? "video" : (imageUrl?.includes('png') ? "image" : "");

  const closeModal = () => {
    onClose();
  };

  if (user) {
    username = user.username;
    displayPicture = user.picture;
  }

  const handleOptionsClick = () => {
    console.log('options clicked');
    setShowOptions(showOptions => !showOptions);
  };

  return (
    <div className="image-modal">
      <div className="image-modal__overlay" onClick={closeModal} />
      <div className={`image-modal__content image-modal__content--${themeClass} image-modal__content--${modifierClass}`}>
        <div className={`image-modal__image-container image-modal__image-container--${themeClass} image-modal__image-container--${modifierClass}`}>
          <div className={`image-modal__info image-modal__info--${themeClass} image-modal__info--${modifierClass}`} >
            <div className="image-modal__info-left">
              <img src={displayPicture} alt="Portfolio" className="image-modal__info-dp" />
              <Link to={`/${username}`} className="image-modal__info-text">
                {username}
              </Link>
            </div>
            <div className="image-modal__info-right">
              <img
                src={Options}
                alt="options icon"
                className="image-modal__options-icon"
                onClick={handleOptionsClick}
              />
              {showOptions && (
                <div className={`image-modal__options image-modal__options--${themeClass}`}>
                  {/* <div className="image-modal__options-item">Copy Link</div> */}
                  {/* <div className="image-modal__options-item">Share to</div> */}
                  {/* <div className="image-modal__options-item">Embed</div> */}
                  {handleDelete && <div className={`image-modal__options-item image-modal__options-item--${themeClass}`} onClick={() => handleDelete()}><p>Delete</p></div>}
                  {/* <div className="image-modal__options-item">Report</div> */}
                  <div className={`image-modal__options-item image-modal__options-item--cancel image-modal__options-item--${themeClass}`} onClick={() => setShowOptions(false)}>Cancel</div>
                </div>
              )}
            </div>
          </div>
          <div className='image-modal__post'>
            {imageUrl.includes('.mp4') ? (
              <VideoPlayer videoArray={[imageUrl]} modifier={['video-player--height', 'video-player__video-view', 'video-player__video-home', 'video-player--home']} />
            ) : (
              <img src={imageUrl} alt="Portfolio" className="image-modal__image" onClick={() => setShowOptions(false)} />
            )}
          </div>
        </div>
      </div >
    </div >
  );
};

export default ImageModal;
