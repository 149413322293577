import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import "./UserCard.scss";
import displayPicture from '../../assets/defaults/displayPicture.png'
import ThemeContext from '../../context/ThemeContext';


export default function UserCard({ username, category, location, picture }) {

      const { themeMode } = useContext(ThemeContext);

    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  return (
    <Link className={`user user--${themeClass}` } to={`/${username}`}>
      <div className="user__img-container">
        <img className={`user__img user__img--${themeClass}` } src={picture === "/avatar.png" ? displayPicture : picture} alt={`${username}'s profile`} />
      </div>
      <div className="user__info-container">
        <div>
          <p className="user__info-username">{username}</p>
          <p className="user__info-category">{category}</p>
          <p className="user__info-location">{location}</p>
        </div>
        <div>
        </div>
      </div>
    </Link>
  )
}
