import React, { useContext } from "react";
import "./CampaignCard.scss";
import { Link, useParams } from "react-router-dom";
import Card from "../Card/Card";
import PromotionPostCard from "../PromotionPostCard/PromotionPostCard";
import CampaignPortfolioContainer from "../CampaignPortfolioContainer/CampaignPortfolioContainer";
import CampaignPoster from "../../assets/defaults/campaignPoster.png";
import ThemeContext from "../../context/ThemeContext";
import CampaignHeader from "../CampaignHeader/CampaignHeader";
import CommentInputField from "../CommentInputField/CommentInputField";
import Comment from "../Comment/Comment";
import CampaignStatus from "../CampaignStatus/CampaignStatus";
import Linkify from "react-linkify";

export default function CampaignCard({
  campaign,
  campaignPreview,
  setCamapignPreview,
  title,
  to,
  currentProgress,
  image,
  images,
  handleBrandCampaign,
  handleCreatorCampaign,
  brandPortfolio = [],
  setBrandPortfolio,
  uploadCreatorImage,
  setUploadCreatorImage,
  uploadBrandImage,
  setUploadBrandImage,
  videoData,
  uploadBrandPreviewImage,
  setUploadBrandPreviewImage,
  uploadCreatorPreviewImage,
  setUploadCreatorPreviewImage,
  setOptionsModal,
  handleItemClick,
  handlePostComment,
  comment,
  setComment,
  username,
  handleCampaignCompletion,
  handleRevisionReq,
}) {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

  const { id } = useParams();

  const {
    brand,
    creator,
    price,
    category,
    description,
    productName,
    sku,
    productURL,
    trackingURL,
    productType,
    affiliateLink,
    affiliateURL,
    status,
    brandConcept,
    campaignPosts,
    createdAt,
    updatedAt,
    tiktok,
    instagram,
    youtube,
    podcast,
    endDate,
    location,
    promoCode,
  } = campaign || {};

  const formattedCreatedAt = new Date(createdAt).toLocaleString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formattedUpdatedAt = new Date(updatedAt).toLocaleString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const formattedEndDateAt = new Date(endDate).toLocaleString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const ClipBoardIcon = () => {
    const color = themeMode === "light" ? "#000" : "darkgray";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-tabler icons-tabler-outline icon-tabler-clipboard-list"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
        <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
        <path d="M9 12l.01 0" />
        <path d="M13 12l2 0" />
        <path d="M9 16l.01 0" />
        <path d="M13 16l2 0" />
      </svg>
    );
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {}
  };

  const truncateURL = (url, maxLength = 30) => {
    if (url.length <= maxLength) {
      return url;
    }
    return `${url.slice(0, maxLength)}...`;
  };

  return (
    <>
      <Card className="card--margin">
        <div className="campaign-card__header">
          <div className="campaign-card__header__left">
            <CampaignHeader status={status} />
          </div>
          <div className="campaign-card__header__right">
            <p className="campaign-card__date-modified">
              Campaign ID: {id.slice(-10)}
            </p>
            <p className="campaign-card__date-modified">
              Last Update: {formattedUpdatedAt}
            </p>
          </div>
        </div>
        <div className="campaign-card__images">
          {image && (
            <img src={image} alt="Campaign" className="campaign-card__image" />
          )}
          {!!images && images.length > 0 && (
            <div className="campaign-card__carousel">
              {images.map((i, index) => (
                <img
                  src={i}
                  alt={`Campaign ${index + 1}`}
                  key={index}
                  className="campaign-card__carousel-image"
                />
              ))}
            </div>
          )}
        </div>
        <div className="campaign-card__info">
          <div className="campaign-card__details">
            <div className="campaign-card__details-container">
              <div></div>
              <div className="campaign-card__date">
                <div className="campaign-card__title-div">
                  <h2 className="campaign-card__title">
                    {"" + brand?.username + " x " + creator?.username}
                  </h2>
                </div>
                {brand?.username === username && (
                  <div className="campaign-card__cta">
                    {(status === "Check In" || status === "Comments") && (
                      <div className="campaign-status__completed">
                        {status === "Check In" && (
                          <button
                            className={`campaign-card__approve campaign-card__approve--${themeClass}`}
                            onClick={handleRevisionReq}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="red"
                              strokeWidth={1}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="icon icon-tabler icons-tabler-outline icon-tabler-bubble-text"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M7 10h10" />
                              <path d="M9 14h5" />
                              <path d="M12.4 3a5.34 5.34 0 0 1 4.906 3.239a5.333 5.333 0 0 1 -1.195 10.6a4.26 4.26 0 0 1 -5.28 1.863l-3.831 2.298v-3.134a2.668 2.668 0 0 1 -1.795 -3.773a4.8 4.8 0 0 1 2.908 -8.933a5.33 5.33 0 0 1 4.287 -2.16" />
                            </svg>
                            Comments
                          </button>
                        )}
                        <button
                          className={`campaign-card__approve campaign-card__approve--${themeClass}`}
                          onClick={handleCampaignCompletion}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="green"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className=""
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx={12} cy={12} r={9} />
                            <path d="M9 12l2 2l4 -4" />
                          </svg>
                          Complete
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {price && (
                  <div className="campaign-card__campign-fee-div">
                    <h2 className="campaign-card__campaign-fee">${price}.00</h2>
                  </div>
                )}
              </div>
              {brand && (
                <div className="campaign-card__row-100">
                  <div className="campaign-card__info-pair">
                    <div className="campaign-card__info-label">Brand:</div>
                    <div className="campaign-card__info-item">
                      <Link
                        to={`/${brand.username}`}
                        className="campaign-card__info-link"
                      >
                        {brand.username}
                      </Link>
                    </div>
                  </div>
                  <div className="campaign-card__date-container">
                    {formattedCreatedAt && (
                      <div className="campaign-card__info-pair campaign-card__info-pair--shorter">
                        <div className="campaign-card__info-label">
                          Start Date:
                        </div>
                        <div className="campaign-card__info-item">
                          {formattedCreatedAt}
                        </div>
                      </div>
                    )}
                    {formattedEndDateAt && (
                      <div className="campaign-card__info-pair campaign-card__info-pair--shorter">
                        <div className="campaign-card__info-label">
                          End Date:
                        </div>
                        <div className="campaign-card__info-item">
                          {formattedEndDateAt}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {creator && (
                <div className="campaign-card__div">
                  <div className="campaign-card__info-pair">
                    <div className="campaign-card__info-label">Creator:</div>
                    <div className="campaign-card__info-item">
                      <Link
                        to={`/${creator.username}`}
                        className="campaign-card__info-link"
                      >
                        {creator.username}
                      </Link>
                    </div>
                  </div>
                  <div className="campaign-card__date-container">
                    {promoCode && (
                      <div className="campaign-card__info-pair">
                        <div className="campaign-card__info-label">
                          Promo Code:
                        </div>
                        <div className="campaign-card__info-item">
                          {promoCode}
                          <div
                            onClick={() => copyToClipboard(promoCode)}
                            className="campaign-card__copy-icon"
                            style={{
                              cursor: "pointer",
                              marginLeft: "0.33rem",
                              alignSelf: "center",
                            }}
                          >
                            <ClipBoardIcon />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="campaign-card__div">
                {category && (
                  <div className="campaign-card__info-pair">
                    <div className="campaign-card__info-label">Category:</div>
                    <div className="campaign-card__info-item">@{category}</div>
                  </div>
                )}
                {location && (
                  <div className="campaign-card__info-pair">
                    <div className="campaign-card__info-label">Location:</div>
                    <div className="campaign-card__info-item">{location}</div>
                  </div>
                )}
              </div>
              <div className="campaign-card__div">
                {productName && (
                  <div className="campaign-card__info-pair">
                    <div className="campaign-card__info-label">Product:</div>
                    <div className="campaign-card__info-item">
                      {productName}
                    </div>
                  </div>
                )}
                {productType && (
                  <div className="campaign-card__info-pair">
                    <div className="campaign-card__info-label">
                      Product Type:
                    </div>
                    <div className="campaign-card__info-item">
                      {productType}
                    </div>
                  </div>
                )}
                {sku && (
                <div className="campaign-card__info-pair">
                  <div className="campaign-card__info-label">SKU:</div>
                  <div className="campaign-card__info-item">{sku}</div>
                </div>
              )}
              </div>
              {/* {price && (
                                <div className="campaign-card__info-pair">
                                    <div className="campaign-card__info-label">Campaign Fee:</div>
                                    <div className="campaign-card__info-item">${price}.00</div>

                                </div>
                            )} */}
      

         
              <div className="campaign-card__div">
                {affiliateLink && (
                    <div className="campaign-card__info-pair">
                    <div className="campaign-card__info-label">
                        Affiliate Link:
                    </div>
                    <Linkify>
                        <div className="campaign-card__info-item">
                        {truncateURL(affiliateLink)}
                        <div
                            onClick={() => copyToClipboard(affiliateLink)}
                            className="campaign-card__copy-icon"
                            style={{
                            cursor: "pointer",
                            marginLeft: "0.33rem",
                            alignSelf: "center",
                            }}
                        >
                            <ClipBoardIcon />
                        </div>
                        </div>
                    </Linkify>
                    </div>
                )}
                {affiliateURL && (
                    <div className="campaign-card__info-pair">
                    <div className="campaign-card__info-label">
                        Affiliate URL:
                    </div>
                    <Linkify>
                        <div className="campaign-card__info-item">
                        {truncateURL(affiliateURL)}
                        <div
                            onClick={() => copyToClipboard(affiliateURL)}
                            className="campaign-card__copy-icon"
                            style={{
                            cursor: "pointer",
                            marginLeft: "0.33rem",
                            alignSelf: "center",
                            }}
                        >
                            <ClipBoardIcon />
                        </div>
                        </div>
                    </Linkify>
                    </div>
                )}
              </div>
              <div className="campaign-card__div">
                {productURL && (
                    <div className="campaign-card__info-pair">
                    <div className="campaign-card__info-label">Product URL:</div>
                    <Linkify>
                        <div className="campaign-card__info-item">
                        {truncateURL(productURL)}
                        <div
                            onClick={() => copyToClipboard(productURL)}
                            className="campaign-card__copy-icon"
                            style={{
                            cursor: "pointer",
                            marginLeft: "0.33rem",
                            alignSelf: "center",
                            }}
                        >
                            <ClipBoardIcon />
                        </div>
                        </div>
                    </Linkify>
                    </div>
                )}
                {trackingURL && (
                    <div className="campaign-card__info-pair">
                    <div className="campaign-card__info-label">Tracking URL:</div>
                    <Linkify>
                        <div className="campaign-card__info-item">
                        {truncateURL(trackingURL)}
                        <div
                            onClick={() => copyToClipboard(trackingURL)}
                            className="campaign-card__copy-icon"
                            style={{
                            cursor: "pointer",
                            marginLeft: "0.33rem",
                            alignSelf: "center",
                            }}
                        >
                            <ClipBoardIcon />
                        </div>
                        </div>
                    </Linkify>
                    </div>
                )}
              </div>
     

              {description && (
                <div className="campaign-card__info-pair">
                  <div className="campaign-card__info-label">
                    Campaign Details:
                  </div>
                  <div className="campaign-card__info-item">{description}</div>
                </div>
              )}
              {(tiktok?.enabled ||
                instagram?.enabled ||
                youtube?.enabled ||
                podcast?.enabled) && (
                <div className="campaign-card__social-container">
                  <div className="campaign-card__info-label campaign-card__info-sub-label">
                    Marketing Channels:
                  </div>
                  <div className="campaign-card__socials">
                    <PromotionPostCard
                      name={"TikTok"}
                      posts={tiktok?.quantity}
                      enabled={tiktok?.enabled}
                    />
                    <PromotionPostCard
                      name={"Instagram"}
                      posts={instagram?.quantity}
                      enabled={instagram?.enabled}
                    />
                    <PromotionPostCard
                      name={"Youtube"}
                      posts={youtube?.quantity}
                      enabled={youtube?.enabled}
                      tag={"Spot"}
                    />
                    <PromotionPostCard
                      name={"Podcast"}
                      posts={podcast?.quantity}
                      enabled={podcast?.enabled}
                      tag={"Spot"}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="campaign-card__details-container-right">
              <div
                className="campaign-card__image-container"
                onClick={() => {
                  setOptionsModal(true);
                }}
              >
                <img
                  src={campaignPreview || campaign?.image || CampaignPoster}
                  alt="Campaign"
                  className="campaign-card__image"
                />
              </div>
            </div>
          </div>
          {/* <div className="campaign-card__info-label campaign-card__sub-header">Campaign Visuals</div> */}
          <div className="campaign-card__actions">
            <div className="campaign-card__actions__left">
              <p className="campaign-card__info-label campaign-card__info-label--mb">
                Brand Campaign Concept:
              </p>
              <CampaignPortfolioContainer
                key={"2"}
                type="image/png, image/jpeg, video/mov, video/mp4"
                portfolioItems={brandConcept}
                handleAddItem={handleBrandCampaign}
                uploadImage={uploadBrandImage}
                setUploadImage={setUploadBrandImage}
                videoData={videoData}
                uploadBrandPreviewImage={uploadBrandPreviewImage}
                setUploadBrandPreviewImage={setUploadBrandPreviewImage}
                tag={"brand-upload"}
                handleItemClick={handleItemClick}
                brand={brand}
                username={username}
                campaignPostsCount={campaign?.brandConcept.length}
                status={status}
              />
            </div>
            <div className="campaign-card__actions__right">
              <p className="campaign-card__info-label campaign-card__info-label--mb">
                Creator Campaign Ad:
              </p>
              {status !== "Pending" ? (
                <CampaignPortfolioContainer
                  key={"1"}
                  type="image/png, image/jpeg, video/mov, video/mp4"
                  portfolioItems={campaignPosts}
                  handleAddItem={handleCreatorCampaign}
                  videoData={videoData}
                  uploadImage={uploadCreatorImage}
                  setUploadImage={setUploadCreatorImage}
                  uploadCreatorPreviewImage={uploadCreatorPreviewImage}
                  setUploadCreatorPreviewImage={setUploadCreatorPreviewImage}
                  tag={"creator-upload"}
                  handleItemClick={handleItemClick}
                  creator={creator}
                  username={username}
                  campaignPostsCount={campaign?.campaignPosts.length}
                  status={status}
                />
              ) : (
                <p
                  className="campaign-card__info-label campaign-card__info-sub-label"
                  style={{
                    textAlign: "center",
                    fontSize: "0.75rem",
                    marginTop: "2rem",
                  }}
                >
                  Request Pending
                </p>
              )}
            </div>
          </div>
          <div className="campaign-card__progress">
            <p className="campaign-card__info-label campaign-card__info-sub-label">
              Campaign Progress
            </p>
            <CampaignStatus
              status={status}
              isBrand={username === brand?.username}
              handleCampaignCompletion={handleCampaignCompletion}
              handleRevisionReq={handleRevisionReq}
            />
          </div>

          <div className="campaign-card__comments">
            {campaign?.comments.length < 1 && (
              <div className="campaign-card__info-label campaign-card__sub-header">
                Comments
              </div>
            )}
            {campaign?.comments && (
              <div
                className={`home-post__comment-sec home-post__comment-sec--${""} home-post__comment-sec--${themeClass} campaign-card__comment-s`}
              >
                {campaign?.comments.map((comment, index) => (
                  <Comment key={index} comment={comment} />
                ))}
              </div>
            )}
            <CommentInputField
              comment={comment}
              setComment={setComment}
              handlePostComment={() => {
                handlePostComment(campaign?._id);
              }}
              imageBorder={true}
            />
          </div>
        </div>
      </Card>
    </>
  );
}
