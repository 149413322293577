import React, { useState, useEffect } from 'react';
import './LandingPgCarousel.scss';
import promoteTiTok from '../../assets/images/promoteTikTok.png';
import promoteSpotify from '../../assets/images/promoteSpotify.jpeg';
import promoteInstagram from '../../assets/images/highlightPortfolio.png';
import campaignsPg from '../../assets/images/orderPg2.png';
import linkFeature from '../../assets/images/linkFeatures.jpeg';

export default function Carousel({ fullImage }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    { src: campaignsPg, layout: 'content', caption: 'Manage Deals', description: 'Track campaigns, get instant payouts' },
    { src: linkFeature, layout: 'content', caption: 'Link Sites', description: 'Manage socials, portfolio, deals' },
    { src: promoteTiTok, layout: 'content', caption: 'TikTok Campaigns', description: 'Handle influencer promotions.' },
    { src: promoteInstagram, layout: 'content', caption: 'Instagram Campaigns', description: 'Showcase portfolios, run campaigns' },
    { src: promoteSpotify, layout: 'content', caption: 'Promote Music & Podcasts', description: 'Easily connect your Spotify' },
  ];
  

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds
    return () => clearInterval(interval);
  }, [slides.length]);

  const renderSlideContent = () => {
    const { src, layout, caption, description } = slides[currentSlide];
    
    // Conditionally break the caption into two parts for specific slides
    let captionFirstLine = caption;
    let captionSecondLine = '';

    if (src === promoteTiTok || src === promoteInstagram || src === promoteSpotify) {
      const captionParts = caption.split(' ');
      captionFirstLine = captionParts.slice(0, 2).join(' ');
      captionSecondLine = captionParts.slice(2).join(' ');
    }

    switch (layout) {
      case 'full':
        return (
          <div className='carousel__full'>
            <div className='carousel__image-container'>
              <img src={src} alt={`Slide ${currentSlide}`} className='carousel__image--full' />
            </div>
            <div className='carousel__overlay'>
              <h1>
                {captionFirstLine}
                {captionSecondLine && <><br />{captionSecondLine}</>}
              </h1>
              <p>{description}</p>
            </div>
          </div>
        );
      case 'content':
        return (
          <div className='carousel__content'>
            <div className='carousel__text'>
              <h1>
                {captionFirstLine}
                {captionSecondLine && <><br />{captionSecondLine}</>}
              </h1>
              <p className='carousel__text--description'>{description}</p>
            </div>
            <div className='carousel__image-container'>
              <img src={src} alt={`Slide ${currentSlide}`} className='carousel__image--content' />
            </div>
          </div>
        );
      case 'content-opposite':
        return (
          <div className='carousel__content carousel__content--reverse'>
            <div className='carousel__image-container'>
              <img src={src} alt={`Slide ${currentSlide}`} className='carousel__image--content' />
            </div>
            <div className='carousel__text'>
              <h1>
                {captionFirstLine}
                {captionSecondLine && <><br />{captionSecondLine}</>}
              </h1>
              <p>{description}</p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return <div className='carousel'>{renderSlideContent()}</div>;
}
