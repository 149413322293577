import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Context } from "../../context/index";
import UserRoute from '../../Routes/Auth';
import "./ManagePromotionsPage.scss";
import PromoteCard from '../../Components/PromoteCard/PromoteCard';
import Navigation from '../../Components/Navigation/Navigation';
import OptionsModalBox from '../../Components/OptionsModalBox/OptionsModalBox';
import { useNavigate } from 'react-router-dom';

export default function ManagePromotionsPage() {
  const { state } = useContext(Context);
  const { user } = state;
  const [promotions, setPromotions] = useState([]);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const navigate = useNavigate();

  const fetchPromotions = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/user/edit-profile/promotion`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setPromotions(data.promotions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPromotions();
  }, [user]);

  const handleEditPromotion = (id) => {
    navigate("/settings/promotions/edit/" + id);
  };

  const handleDeletePromotion = async (promotionId) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API}/settings/promotions/${promotionId}`);
      console.log(response);
      setPromotions(promotions.filter(promotion => promotion._id !== promotionId));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <UserRoute>
      <div className='manage-promo-pg'>
        <Navigation heading={"Manage Promotions"} add={true} username={user.username} back={true} payments={true} navRight={true} />
        {promotions.length > 0 ?
          promotions.map(promotion => (
            <PromoteCard
              name={promotion.name}
              description={promotion.description}
              price={promotion.price}
              images={promotion.portfolio}
              handleCheckout={() => { }}
              tikTok={promotion && promotion.tiktok}
              instagram={promotion && promotion.instagram}
              podcast={promotion && promotion.podcast}
              youtube={promotion && promotion.youtube}
              edit={true}
              key={promotion._id}
              id={promotion._id}
              setMenu={setIsOptionsOpen}
            />
          )) :
          <div className="no-promotions">
            <h3 className='no-promotions__title'>No Promotions</h3>
            <p className='no-promotions__caption'>Tap + to create a new promotion</p>
          </div>
        }

        {isOptionsOpen !== false && (
          <OptionsModalBox
            isOpen={isOptionsOpen}
            setIsOpen={setIsOptionsOpen}
            handleEditPromotion={() => handleEditPromotion(isOptionsOpen)}
            handleDeletePromotion={() => handleDeletePromotion(isOptionsOpen)}
          />
        )}
      </div>
    </UserRoute>
  );
}
