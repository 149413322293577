import React, { useContext, useEffect } from 'react';
import './LandingPage.scss';
import FooterComponent from '../../Components/FooterComponent/FooterComponent';
import ChequeaiLogo from '../../assets/icons/chequai-logo.png';
import HomeImg from '../../assets/screenshoots/HomePage3.png';
import EarningsImg from '../../assets/screenshoots/EarningsFeature.png';
import SearchImg from '../../assets/screenshoots/SearchFeature.png';
import CampaignImg from '../../assets/screenshoots/CampaignFeature.png';
import SubscriptionPlanCard from '../../Components/SubscriptionPlanCard/SubscriptionPlanCard';
import { Link, useNavigate } from 'react-router-dom';
import { Context } from "../../context/index";
import ThemeContext from '../../context/ThemeContext';
import EmailListComponent from '../../Components/EmailListComponent/EmailListComponent';
// import WaitListComponent from '../../Components/WaitListComponent/WaitListComponent';
import LandingPgCarousel from '../../Components/LandingPgCarousel/LandingPgCarousel';

const standardPlan = {
    priceId: "price_1PV0EPHJJv2x3AI2fpO44juW",
    name: "Standard",
    price: "$29.99",
    duration: "M",
    buttonLabel: "Subscribe",
    features: [
        "Manage Brand Campaigns",
        "Built-in Product Listings",
        "Quick Links to All Your Main Socials",
        "Links to Third-Party Sites",
        "8 Campaigns Per Month",
        "3 Promotions",
        "7.0% Campaign Fee"
    ]
};

const premiumPlan = {
    priceId: "price_1PT7eiHJJv2x3AI2Vw6HYmCM",
    name: "Premium",
    price: "$59.99",
    duration: "M",
    buttonLabel: "Subscribe",
    features: [
        "Manage Brand Campaigns",
        "Built-in Product Listings",
        "Quick Links to All Your Main Socials",
        "Links to Third-Party Sites",
        "Unlimited Campaigns Per Month",
        "Unlimited Promotions",
        "6.0% Campaign Fee"
    ]
};

export default function LandingPage() {
    const { state } = useContext(Context);
    const navigate = useNavigate();
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    const { user } = state;

    const handleCheckout = (id) => {
        try {
                navigate('/signup');
           
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // if (user !== null) navigate("/home");
    }, [user, navigate]);

    return (
        <div className={`landing-pg landing-pg--${themeClass}`}>
            <header className='landing-pg__header'>
                <div className='landing-pg__header-left'>
                    <img src={ChequeaiLogo} alt='Search for Influencers' className='landing-pg__header__logo-img' />
                    <Link to="/login" className='landing-pg__logo'>
                        <h1 className='landing-pg__header__title'>Chequeai</h1>
                    </Link>
                </div>
                <div className='landing-pg__header-right'>
                    <a href="#features" className='landing-pg__nav-link'>Features</a>
                    <a href="#subscriptions" className='landing-pg__nav-link'>Subscriptions</a>
                    <a href="#subscriptions" className='landing-pg__nav-link'>Start now</a>
                    <Link to="/login">
                        <button className={`landing-pg__btn landing-pg__btn--login landing-pg__btn--translucent landing-pg__btn--translucent--${themeClass}`}>
                            {user ? <span className="landing-pg__login-text">Home</span> : <span className="landing-pg__login-text">Login</span>}
                        </button>
                    </Link>
                </div>
            </header>
            <section className='landing-pg__hero'>
                <h4 className='landing-pg__hero__made-for'>Made for Creators and Brands</h4>
                <h1 className='landing-pg__hero__title'>Manage Brand Deals</h1>
                <p className='landing-pg__hero__text'>Find the perfect creator for your brand, or become a creator and start earning money today!</p>
                <img src={HomeImg} alt='Search for Influencers' className='landing-pg__hero__img' />
                <div className='landing-pg__hero__cta'>
                    <Link to="/signup">
                        <button className='landing-pg__btn landing-pg__btn--primary'>
                            <span className={`landing-pg__start-now landing-pg__start-now--${themeClass}`}>Start now</span>
                        </button>
                    </Link>
                    <Link to="/login">
                        <button className='landing-pg__btn landing-pg__btn--translucent'>
                            {user ? <span className="landing-pg__learn-more">Home</span> : <span className="landing-pg__learn-more">Sign In</span>}
                        </button>
                    </Link>
                </div>
            </section>
            <LandingPgCarousel fullImage={HomeImg} />
            <section className='landing-pg__features' id="features">
                <h1 className='landing-pg__features__title'>Features</h1>
                <div className='landing-pg__features__list'>
                    <div className='landing-pg__features__item'>
                        <div className='landing-pg__features__img'>
                            <img src={SearchImg} alt='Search for Influencers' className='landing-pg__features__img' />
                        </div>
                        <div className='landing-pg__features__text'>
                            <i className="fas fa-search"></i>
                            <h3>Search for Creators</h3>
                            <p>Brands can search for creators based on their target audience, engagement rate, and more.</p>
                        </div>
                    </div>
                    <div className='landing-pg__features__item'>
                        <div className='landing-pg__features__img'>
                            <img src={EarningsImg} alt='Search for Influencers' className='landing-pg__features__img' />
                        </div>
                        <div className='landing-pg__features__text'>
                            <i className="fas fa-user-plus"></i>
                            <h3>Start as a Creator today</h3>
                            <p>Creators can create a profile, set their rates, and start earning money by promoting brands on their social media channels.</p>
                        </div>
                    </div>
                    <div className='landing-pg__features__item'>
                        <div className='landing-pg__features__img'>
                            <img src={CampaignImg} alt='Search for Influencers' className='landing-pg__features__img' />
                        </div>
                        <div className='landing-pg__features__text'>
                            <i className="fas fa-chart-line"></i>
                            <h3>Track Performance</h3>
                            <p>Brands can track the performance of their creators' campaigns in real-time, including reach, engagement, and conversions.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <WaitListComponent /> */}

            <section className='landing-pg__subscription' id="subscriptions">
                <h1 className='landing-pg__subscription__title'>Subscriptions</h1>
                <div className='landing-pg__subscription-div'>
                    <SubscriptionPlanCard
                        priceId={standardPlan.priceId}
                        name={standardPlan.name}
                        price={standardPlan.price}
                        duration={standardPlan.duration}
                        buttonLabel={standardPlan.buttonLabel}
                        handleCheckout={handleCheckout}
                        features={standardPlan.features}
                    />
                    <SubscriptionPlanCard
                        priceId={premiumPlan.priceId}
                        name={premiumPlan.name}
                        price={premiumPlan.price}
                        duration={premiumPlan.duration}
                        buttonLabel={premiumPlan.buttonLabel}
                        handleCheckout={handleCheckout}
                        features={premiumPlan.features}
                    />
                </div>
            </section>
            <section className='landing-pg__cta'>
                <div className='landing-pg__cta-content'>
                    <h1 className='landing-pg__cta__title'>Ready to get started?</h1>
                    <p className='landing-pg__cta__text'>Join us today and take your brand to the next level with the perfect creator partnerships.</p>
                    <div className='landing-pg__cta__btn-div'>
                        <Link to="/signup">
                            <button className={`landing-pg__btn landing-pg__btn--cta landing-pg__btn--cta--${themeClass}`}>Start now</button>
                        </Link>
                        <a href="#subscriptions">
                            <button className={`landing-pg__btn landing-pg__btn--cta landing-pg__btn--cta--${themeClass}`}>Pricing</button>
                        </a>
                    </div>
                </div>
            </section>

            <EmailListComponent />

            <FooterComponent />
        </div>
    );
}
