import React, { useContext, useEffect, useState, useRef } from 'react';
import { Context } from "../../context/index";
import "./Messages.scss";
import Card from '../../Components/Card/Card';
import UserRoute from '../../Routes/Auth';
import { Link, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import displayPicture from '../../assets/defaults/displayPicture.png';
import ThemeContext from '../../context/ThemeContext';
import { formatTimestamp } from '../../utils/dateUtils';
import MenuIcon from '../../Pages/UserPage/icons/MenuIcon';
import OptionsModalBox from '../../Components/OptionsModalBox/OptionsModalBox';
import axios from 'axios';
import Loading from '../../Components/Loading/Loading';

export default function Messages({ serverUrl, currentRoom, setCurrentRoom }) {
  const [rooms, setRooms] = useState([]);
  const [username, setUsername] = useState("");
  const { state: { user } } = useContext(Context);
  const socketRef = useRef();
  const { themeMode } = useContext(ThemeContext);
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = 6;

  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  const updateAndSortRooms = (rooms, messageData) => {
    const updatedRooms = rooms.map(room => {
      if (room.roomId === messageData.roomId) {
        return {
          ...room,
          message: messageData.message,
          timeStamp: messageData.timeStamp,
          lastMessageSender: messageData.sender,
        };
      }
      return room;
    });
    return updatedRooms.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
   else if (user !== null) {
      setUsername(user?.username);
      setCurrentRoom(user._id)
    } 
  }, [user, navigate,setCurrentRoom]);

  useEffect(() => {
    socketRef.current = io(serverUrl, {
      query: {
        userId: user?._id,
      },
    });

    socketRef.current.on('connect', () => {
      console.log('Connected to live messages user', user.username);
      socketRef.current.emit('join room', { username, roomName: user._id });
      socketRef.current.emit('get rooms', { username });
    });

    socketRef.current.on('rooms by user', (rooms) => {
      setRooms(rooms);
    });

    socketRef.current.on('notify', (messageData) => {
      console.log("notify event received:", messageData);
      setRooms(prevRooms => {
        const updatedRooms = updateAndSortRooms(prevRooms, messageData);
        console.log("Updated rooms:", updatedRooms);
        return updatedRooms;
      });
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [username, serverUrl, user?._id, user?.username]);

  useEffect(() => {
    socketRef.current.on('update preview', (roomName) => {
      console.log("update preview event received");
      if (roomName === "FORCE_UPDATE") {
        // Handle force update logic here
      } else {
        setRooms(prevRooms => {
          return prevRooms.map(room => {
            if (room.name === roomName) {
              // Modify the specific room object here
            }
            return room;
          });
        });
      }
    });
  }, []);

  const handleRemoveChat = async (roomId) => {
    try {
      console.log('Sending request to delete room:', roomId);
      const response = await axios.delete(`${process.env.REACT_APP_API}/user/messages/${roomId}`);
      if (response && response.data) {
        // Remove the room from the state without reloading
        setRooms(prevRooms => prevRooms.filter(room => room.roomId !== roomId));
        // Adjust current page if necessary
        const totalPages = Math.ceil((rooms.length - 1) / messagesPerPage);
        if (currentPage > totalPages) {
          setCurrentPage(totalPages);
        }
      } else {
        console.error('Unexpected response structure', response);
      }
    } catch (err) {
      console.error('Failed to remove. Try again later.', err);
    }
  };

  const truncateMessage = (message, maxLength = 72) => {
    if (!message) return "";
    if (message.length > maxLength) {
      return message.slice(0, maxLength) + "...";
    }
    return message;
  };

  const NewChatIcon = () => {
    const color = themeMode === 'light' ? 'black' : 'white';

    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-circle-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path stroke={color} d="M12.007 19.98a9.869 9.869 0 0 1 -4.307 -.98l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c1.992 1.7 2.93 4.04 2.747 6.34" />
        <path stroke={color} d="M16 19h6" />
        <path stroke={color} d="M19 16v6" />
      </svg>
    );
  };

  // Filter rooms
  const filteredRooms = rooms.filter(room => {
    const roomNameSplit = room.name.split('_');
    return !(user._id === roomNameSplit[1] && room.message === null);
  });

  // Pagination calculations
  const totalPages = Math.ceil(filteredRooms.length / messagesPerPage);
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentRooms = filteredRooms.slice(indexOfFirstMessage, indexOfLastMessage);

  if (!user) {
    return <Loading />;
  }

  return (
    <UserRoute>
      <div className="messages-pg">
        <div className="messages-pg__header">
          <h3 className='messages-pg__title'>Messages</h3>
          <Link to={"/messages/new-message"}>
            <NewChatIcon />
          </Link>
        </div>
        <Card className={"card--padding card--srollable"}>
          {currentRooms.map((room) => (
            <div key={room.roomId} className={`messages-pg__message messages-pg__message--${themeClass}`}>
              <Link className="messages-pg__message-info" to={`/messages/${room.name}`}>
                <div className="messages-pg__message-left">
                  <img className="messages-pg__message-dp" src={room.picture === "/avatar.png" ? displayPicture : room.picture} alt='user display' />
                </div>
                <div className="messages-pg__message-right">
                  <div>
                    <p className="messages-pg__message-sender">{room.sender}</p>
                    <p className={`messages-pg__message-text`}>{truncateMessage(room.message)}</p>
                  </div>
                </div>
              </Link>
              <div className="messages-pg__message-timestamp">
                {(!room.read && room.lastMessageSender && room.lastMessageSender !== username) && (
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill={"#276ab3"} className="icon icon-tabler icons-tabler-filled icon-tabler-point">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" />
                  </svg>
                )}
                {formatTimestamp(room.timeStamp)}
                <div className="messages-pg__message-options" style={{ display: 'flex', alignItems: 'center' }} onClick={() => setShowOptions(room.roomId)}>
                  <MenuIcon themeMode={themeMode} />
                </div>
              </div>
            </div>
          ))}
          {filteredRooms.length > messagesPerPage && (
            <div className="pagination">
              <button
                className={`pagination__button pagination__button--${themeClass}`}
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <button
                className={`pagination__button pagination__button--${themeClass}`}
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </Card>
        {showOptions && (
          <OptionsModalBox
            isOpen={showOptions}
            setIsOpen={setShowOptions}
            handleRemoveChat={() => handleRemoveChat(showOptions)}
          />
        )}
      </div>
    </UserRoute>
  );
};
