import React, { useContext } from 'react';
import './Dropdown.scss';
import ThemeContext from '../../context/ThemeContext';


const Dropdown = ({ label, links, selected, setSelected, singleSelection }) => {

    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const handleSelection = (value) => {
        if (singleSelection) {
            setSelected((prev) => (prev === value ? null : value));
        } else {
            setSelected((prev) => {
                if (prev.includes(value)) {
                    return prev.filter((item) => item !== value);
                } else {
                    return [...prev, value];
                }
            });
        }
    };

    return (
        <div className={`dropdown  dropdown--${themeClass}`}>
            <button className={`dropbtn  dropbtn--${themeClass}`}>
                <p className={`dropdown-label  dropdown-label--${themeClass}`}>{label}</p>
            </button>
            <div className={`dropdown-content  dropdown-content--${themeClass}`}>
                {links.map((link, index) => (
                    <div key={index} className={`dropdown-item  dropdown-item--${themeClass}`}>
                        <input
                            type="checkbox"
                            id={`${label}-${index}`}
                            value={link.path}
                            checked={singleSelection ? selected === link.path : selected.includes(link.path)}
                            onChange={() => handleSelection(link.path)}
                        />
                        <label htmlFor={`${label}-${index}`}>{link.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Dropdown;
