import React from "react";
import "./Carousel.scss";
import PlatformPlaceholderCard from "../../Components/PlatformPlaceholderCard/PlatformPlaceholderCard"; // Ensure this path is correct

export default function Carousel({ campaign, currentIndex, setAnalyticsModal, themeClass }) {
  const postIds = campaign?.postIds || {};
  
  // Convert postIds object to an array of platforms with their IDs
  const platforms = [
    { platform: "instagram", id: postIds.instagram },
    { platform: "spotifyPodcast", id: postIds.spotifyPodcast },
    { platform: "youtube", id: postIds.youtube },
    { platform: "tiktok", id: postIds.tiktok },
    { platform: "spotifyMusic", id: postIds.spotifyMusic }
  ];

  return (
    <>
      <div className="carousel">
        <div className="carousel-wrapper">
          {/* Check if the platforms array is empty */}
          {platforms.length === 0 ? (
            <>
              {currentIndex === 0 && (
                <div className="carousel-item active">
                  <PlatformPlaceholderCard themeClass={themeClass} platform="instagram" setAnalyticsModal={setAnalyticsModal} />
                </div> 
              )}
              {currentIndex === 1 && (
                <div className="carousel-item active">
                  <PlatformPlaceholderCard themeClass={themeClass} platform="tiktok" setAnalyticsModal={setAnalyticsModal} />
                </div>
              )}
              {currentIndex === 3 && (
                <div className="carousel-item active">
                  <PlatformPlaceholderCard themeClass={themeClass} platform="youtube" setAnalyticsModal={setAnalyticsModal} />
                </div>
              )}
              {currentIndex === 2 && (
                <div className="carousel-item active">
                  <PlatformPlaceholderCard themeClass={themeClass} platform="spotify" setAnalyticsModal={setAnalyticsModal} />
                </div>
              )}
              {currentIndex === 4 && (
                <div className="carousel-item active">
                  <PlatformPlaceholderCard themeClass={themeClass} platform="spotifyMusic" setAnalyticsModal={setAnalyticsModal} />
                </div>
              )}
            </>
          ) : (
            platforms.map((post, index) => (
              <div
                key={index}
                className={`carousel-item ${
                  index === currentIndex ? "active" : ""
                }`}
                aria-hidden={index !== currentIndex}
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              >
                {/* Placeholder card rendering when post.id is falsy */}
                {!post.id && (
                  <PlatformPlaceholderCard themeClass={themeClass} platform={post.platform || "default"} setAnalyticsModal={setAnalyticsModal} />
                )}

                {post.id && post.platform === "instagram" && (
                  <iframe
                    title="Instagram Post"
                    src={`https://www.instagram.com/p/${post.id}/embed`}
                    width="400"
                    height="516"
                    frameBorder="0"
                    scrolling="yes"
                    allowTransparency="true"
                    className={`iframe-container ${
                      index === currentIndex ? "iframe-container--active" : ""
                    }`}
                  ></iframe>
                )}

                {post.id && post.platform === "youtube" && (
                  <iframe
                    title="YouTube Video"
                    width="430"
                    height="300"
                    src={`https://www.youtube.com/embed/${post.id}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className={`iframe-container ${
                      index === currentIndex ? "iframe-container--active" : ""
                    }`}
                  ></iframe>
                )}

                {post.id && post.platform === "spotifyPodcast" && (
                  <iframe
                    title="Spotify Podcast"
                    src={`https://open.spotify.com/embed-podcast/episode/${post.id}`}
                    width="400"
                    height="232"
                    frameBorder="0"
                    allowTransparency="true"
                    allow="encrypted-media"
                    className={`iframe-container ${
                      index === currentIndex ? "iframe-container--active" : ""
                    }`}
                  ></iframe>
                )}

                {post.id && post.platform === "tiktok" && (
                  <iframe
                    title="TikTok Video"
                    src={`https://www.tiktok.com/embed/${post.id}`}
                    width="325"
                    height="576"
                    frameBorder="0"
                    scrolling="yes"
                    allowTransparency="true"
                    className={`iframe-container ${
                      index === currentIndex ? "iframe-container--active" : ""
                    }`}
                  ></iframe>
                )}

                {post.id && post.platform === "spotifyMusic" && (
                  <iframe
                    title="Spotify Music"
                    src={`https://open.spotify.com/embed/track/${post.id}`}
                    width="400"
                    height="232"
                    frameBorder="0"
                    allowTransparency="true"
                    allow="encrypted-media"
                    className={`iframe-container ${
                      index === currentIndex ? "iframe-container--active" : ""
                    }`}
                  ></iframe>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
}
